/* eslint-disable */
import React, { useState, useRef,useEffect } from 'react'
import {dataChangeMap, taskWordToNum} from "../../config/dataChange";
import moment from "moment";
import './index.scss'
import {
    fetchCandidate, fetchSubStaff, applyTransferTask, approveEditTask,
    applyOtherResourceTask, approveNewTask, cancelTask, createTask, fetchUpperInfo
} from "../../api/getExport"
import {
    InputNumber, Form, Modal, Collapse, Radio, Button, Select, DatePicker,
    Input, Message, Icon, Space, List, Avatar, Tooltip
} from '@arco-design/web-react'
import {
    IconArrowRight, IconExclamationCircle
} from '@arco-design/web-react/icon'
const Option = Select.Option;
const RadioGroup = Radio.Group;
const CollapseItem = Collapse.Item;
const TextArea = Input.TextArea;
const IconFont = Icon.addFromIconFontCn({
    src: '//at.alicdn.com/t/font_180975_26f1p759rvn.js',
});

const TaskInfoModule = ({ token, userID, record, taskInfoJson, initData, taskReviseLog, form_info }) => {
    //const [form] = Form.useForm();
    const [form_revise] = Form.useForm();
    const [form_create_cancel] = Form.useForm();
    //任务信息板块-变量
    const [reviseModalVisible, setReviseModalVisible] = useState(false)
    const [cancelTaskModalVisible, setCancelTaskModalVisible] = useState(false)
    const [restartTaskModalVisible, setRestartTaskModalVisible] = useState(false)
    const [cancelCreateModalVisible, setCancelCreateModalVisible] = useState(false)
    const [createCancelReason, setCreateCancelReason] = useState('')
    const [taskRestartReason, setTaskRestartReason] = useState('')
    const [taskCancelReason, setTaskCancelReason] = useState('')
    const [taskDescribe, setTaskDescribe] = useState(record.taskDes)
    const [taskChecker, setTaskChecker] = useState(record.taskChecker)
    const [taskCheckerId, setTaskCheckerId] = useState(record.taskCheckerId)
    const [taskCheckStandard, setTaskCheckStandard] = useState(record.taskCheckStandard)
    const [queryCheckerOptions, setQueryCheckerOptions] = useState([])
    const [queryAllStaffOptions, setQueryAllStaffOptions] = useState([])
    const [unionId, setUnionId] = useState(null);
    const taskName_list = record.taskKey.split('#')
    const [taskClassification, setTaskClassification] =
        useState((taskName_list.length > 2) ? taskName_list[0] + '/' + taskName_list[1] : '')

    const [supplementMsg, setSupplementMsg] = useState('')
    const [taskValueType, setTaskValueType] = useState(record.taskValueType)
    const [taskWorkType, setTaskWorkType] = useState(record.taskWorkType)
    const [taskSkillAttribute, setTaskSkillAttribute] = useState(record.taskSkillAttribute)
    const [taskResAttribute, setTaskResAttribute] = useState(record.taskResAttribute)
    const [taskExpectedTime, setTaskExpectedTime] = useState(record.taskExpectedTime)
    const [taskDeliverLasttime, setTaskDeliverLasttime] = useState(record.taskDeliverLasttime)
    const [createReviseReason, setCreateReviseReason] = useState('')

    //申请修改
    const [taskResAttributeRevise, setTaskResAttributeRevise] = useState('')
    const [taskExpectedTimeRevise, setTaskExpectedTimeRevise] = useState('')
    const [taskDeliverLasttimeRevise, setTaskDeliverLasttimeRevise] = useState('')
    const [judgeExecute, setJudgeExecute] = useState('')

    const [taskExecuteTimeStandard, setTaskExecuteTimeStandard] =
        useState(record.taskExecuteTimeStandard)
    const [timeRequirement, setTimeRequirement] = useState(
        record.taskExecuteTimeStandard ? record.taskExecuteTimeStandard.split('#')[0] : null)
    const [selectedDates, setSelectedDates] = useState(
        record.taskExecuteTimeStandard ? ((record.taskExecuteTimeStandard.split('#').length>2)
        ? [moment(parseInt(record.taskExecuteTimeStandard.split('#')[1])).format('YYYY-MM-DD HH:mm:ss'),
        moment(parseInt(record.taskExecuteTimeStandard.split('#')[2])).format('YYYY-MM-DD HH:mm:ss')]
        :['','']) : null)

    const [taskExecuteAddressStandard, setTaskExecuteAddressStandard] =
        useState(record.taskExecuteAddressStandard)
    const [taskPlaceRequirement, setTaskPlaceRequirement] =
        useState(record.taskExecuteAddressStandard?record.taskExecuteAddressStandard.split('#')[0]:null)
    const [selectedPlace, setSelectedPlace] =
        useState(record.taskExecuteAddressStandard ? ((record.taskExecuteAddressStandard.split('#').length>1)?
        record.taskExecuteAddressStandard.split('#')[1]:''):null)

    const [isEditing, setIsEditing] = useState(false)
    
    const init_task_info = () => {
        setQueryCheckerOptions([])
        setQueryAllStaffOptions([])
        setUnionId(null)
        setSupplementMsg('')

        //申请修改
        setTaskResAttributeRevise("")
        setTaskDeliverLasttimeRevise("")
        setJudgeExecute('')

        setCreateReviseReason('')
        setCreateCancelReason('')
        setIsEditing(false)

        initData()
    }
    
    const reset_form_value=()=>{
        setTaskDescribe(record.taskDes);
        setTaskChecker(record.taskChecker);
        setTaskCheckerId(record.taskCheckerId);
        setTaskCheckStandard(record.taskCheckStandard);
        setTaskClassification((taskName_list.length > 2) ? taskName_list[0] + '/' + taskName_list[1] : '');
        setTaskValueType(record.taskValueType);
        setTaskWorkType(record.taskWorkType);
        setTaskSkillAttribute(record.taskSkillAttribute);
        setTaskResAttribute(record.taskResAttribute)
        setTaskExpectedTime(record.taskExpectedTime);
        setTaskDeliverLasttime(record.taskDeliverLasttime);

        setTaskExecuteTimeStandard(record.taskExecuteTimeStandard);
        setTimeRequirement(record.taskExecuteTimeStandard.split('#')[0])
        setSelectedDates((record.taskExecuteTimeStandard.split('#').length > 2) ?
            [moment(parseInt(record.taskExecuteTimeStandard.split('#')[1])).format('YYYY-MM-DD HH:mm:ss'),
            moment(parseInt(record.taskExecuteTimeStandard.split('#')[2])).format('YYYY-MM-DD HH:mm:ss')] : ['', ''])

        setTaskExecuteAddressStandard(record.taskExecuteAddressStandard);
        setTaskPlaceRequirement(record.taskExecuteAddressStandard.split('#')[0])
        setSelectedPlace((record.taskExecuteAddressStandard.split('#').length > 1) ?
            record.taskExecuteAddressStandard.split('#')[1] : '')
    }


    const handleCheckerClick = () => {
        fetchUpperInfo(token, record.taskExecuterId, record.taskProjectId)
            .then(data => {
                const options = data.data.map(item => ({
                name: item.name, // 验收人名称
                union_Id: item.union_Id, // 对应的union_Id
                avatar_url: item.avatar_url.avatar_240,
                }));
                setQueryCheckerOptions(options); // 设置状态以在Select中展示所有验收人
            })
            .catch(error => {
                console.log("请求失败:", error);
            });
    }
    const handleCheckerChange = (value, option) => {
        // 更新选中的审批人的union_id
        setTaskCheckerId(option._key);
        setTaskChecker(value)
      };

    const handleDatePickerChange = (dates) => {
        // console.log('handleDatePickerChange', dates)
        if (typeof dates === 'undefined') {
            dates = ['', ''];
        }
        if (dates[0] === '') {
            dates[0] = 0
        } else {
            const StartDate = new Date(dates[0])
            dates[0] = StartDate.getTime()
        }
        if (dates[1] === '') {
            dates[1] = 0
        } else {
            const endDate = new Date(dates[1])
            endDate.setHours(23, 59, 59, 999)
            dates[1] = endDate.getTime()
        }
        let formattedDates = `1#${dates[0]}#${dates[1]}`
        setTaskExecuteTimeStandard(formattedDates)
        setSelectedDates(dates)
    }
    const handleTextAreaChange = (event) => {
        let formatPlace = `1#${event}`
        setSelectedPlace(event)
        setTaskExecuteAddressStandard(formatPlace)
    }
    const submitEditTask = (form) => {
        let params = {}
        params.token = token
        params.taskKey = record.taskKey
        params.taskDes = taskDescribe
        params.taskValueType = taskValueType
        params.taskWorkType = taskWorkType
        params.taskExecuteTimeStandard = taskExecuteTimeStandard
        params.taskExecuteAddressStandard = taskExecuteAddressStandard
        params.taskCheckStandard = taskCheckStandard
        params.taskExpectedTime = taskExpectedTime
        params.taskDeliverLasttime = taskDeliverLasttime
        params.taskSkillAttribute = taskSkillAttribute
        params.taskResAttribute = taskResAttribute
        params.taskChecker = taskChecker
        params.taskCheckerId = taskCheckerId
        params.taskExecuteOtherStandard = record.taskExecuteOtherStandard
        params.taskWorkhoursType = record.taskWorkhoursType
        params.taskName = record.taskName
        params.taskDeliverWay = record.taskDeliverWay
        params.taskCheckWay = record.taskCheckWay
        params.taskFlowWork = record.taskFlowWork
        params.taskNodeWork = record.taskNodeWork
        params.taskExecuter = record.taskExecuter
        params.taskExecuterId = record.taskExecuterId
        params.taskApproverName = record.taskApproverName
        params.taskApproverId = record.taskApproverId
        params.taskWork = record.taskWork
        params.taskStandardPay = record.taskStandardPay
        params.taskState = record.taskState
        params.taskAttach = record.taskAttach

        form.validate().then((values) => {
            if (params.taskDes == record.taskDes && params.taskValueType == record.taskValueType &&
                params.taskWorkType == record.taskWorkType && params.taskChecker == record.taskChecker &&
                params.taskExecuteTimeStandard == record.taskExecuteTimeStandard &&
                params.taskExecuteAddressStandard == record.taskExecuteAddressStandard &&
                params.taskCheckStandard == record.taskCheckStandard &&
                params.taskExpectedTime == record.taskExpectedTime &&
                params.taskDeliverLasttime == record.taskDeliverLasttime &&
                params.taskSkillAttribute == record.taskSkillAttribute &&
                params.taskResAttribute == record.taskResAttribute) {
                Message.error({
                    id: "submitEditTask",
                    content: "请至少修改一项！",
                })
            } else {
                approveEditTask(params)
                    .then(data => {
                        // console.log(data, "submit_edit_task")
                        Message.success({
                            id: "submitEditTask",
                            content: "修改成功！",
                        })
                        setIsEditing(false)
                        init_task_info()
                    })
                    .catch(error => {
                        Message.error({
                            id: "submitEditTask",
                            content: error,
                        })
                        init_task_info()
                        console.log("请求失败:", error)
                    })
            }
        }).catch((error) => {
            console.log(error.name)
            console.log(error.message)
            console.log(error.errors)
        })
    }
    const onEditClick = () => {
        setIsEditing(true)
    }
    const onCancelEdit = () => {
        setIsEditing(false)
        form_info.resetFields()
        reset_form_value()
    }
    //审批新建任务
    const approveCreateTask = (params, msg) => {
        approveNewTask(params)
            .then(d => {
                init_task_info()
                // console.log('approveNewTask success', params);
                //添加成功提示
                Message.success({
                    id: "approveTaskInit",
                    icon: <IconFont type='icon-success' />,
                    content: msg,
                })
            })
            .catch(error => {
                console.log("请求失败:", error)
                Message.error({
                    id: "approveTaskInit",
                    content: "请求失败:" + error,
                })
            })
    }
    const approveTaskInit = (data, form) => {
        let params = {}
        params.token = token
        params.taskKey = record.taskKey;
        params.taskApproveName = record.taskApproverName;
        params.taskApproveId = record.taskApproverId;
        params.taskApproveType = taskWordToNum.taskApproveType["task_create"]
        let taskApproveContent = {}
        let msg = record.taskName + '任务创建审批成功!'
        if (data === 1) {
            params.taskApproveResult = '1'
            params.taskApproveAdvice = '通过'
            setIsEditing(false)
            params.taskApproveContent = JSON.stringify(taskApproveContent)
            approveCreateTask(params, msg)
        } else {
            form.validate().then((values) => {
                // console.log(values);
                if (data === 0) {
                    params.taskApproveResult = '0'
                    params.taskApproveAdvice = createCancelReason
                    msg = record.taskName + '任务创建审批驳回!'
                    setCancelCreateModalVisible(false)
                    setCreateCancelReason('')
                    setIsEditing(false)
                } else if (data === 1) {
                    params.taskApproveResult = '1'
                    params.taskApproveAdvice = '通过'
                    setIsEditing(false)
                } else if (data === 2) {
                    if (taskDescribe == record.taskDes && taskValueType == record.taskValueType &&
                        taskWorkType == record.taskWorkType && taskExpectedTime == record.taskExpectedTime &&
                        taskExecuteTimeStandard == record.taskExecuteTimeStandard &&
                        taskExecuteAddressStandard == record.taskExecuteAddressStandard &&
                        taskCheckStandard == record.taskCheckStandard &&
                        taskDeliverLasttime == record.taskDeliverLasttime &&
                        taskSkillAttribute == record.taskSkillAttribute && taskCheckerId == record.taskCheckerId &&
                        taskResAttribute == record.taskResAttribute && taskChecker == record.taskChecker) {
                        Message.error({
                            id: "approveTaskInit",
                            content: "至少修改其中一项。无修改可取消修改并【确认创建】。"
                        })
                        return
                    } else {
                        taskApproveContent = {
                            "taskDes": taskDescribe, "taskValueType": taskValueType, "taskWorkType": taskWorkType,
                            "taskExecuteTimeStandard": taskExecuteTimeStandard,
                            "taskExecuteAddressStandard": taskExecuteAddressStandard, "taskCheckStandard": taskCheckStandard,
                            "taskExpectedTime": taskExpectedTime, "taskDeliverLasttime": taskDeliverLasttime,
                            "taskSkillAttribute": taskSkillAttribute, "taskResAttribute": taskResAttribute,
                            "taskChecker": taskChecker, "taskCheckerId": taskCheckerId
                        }
                        params.taskApproveResult = '2'
                        params.taskApproveAdvice = createReviseReason
                        setIsEditing(false)
                    }
                }
                params.taskApproveContent = JSON.stringify(taskApproveContent)
                approveCreateTask(params, msg)
            }).catch((error) => {
                console.log(error.name);
                console.log(error.message);
                console.log(error.errors);
            });
        }
    }

    const handleTimeRequirementChange = (value) => {
        setTimeRequirement(value)
        setTaskExecuteTimeStandard(value === '0' ? '0' : `1#${selectedDates[0]}#${selectedDates[1]}`)
    }
    const handlePlaceRequirementChange = (value) => {
        setTaskPlaceRequirement(value)
        setTaskExecuteAddressStandard(value === '0' ? '0' : `1#${selectedPlace}`)
    }

    //任务信息板块-函数
    const handleExecuteClick = () => {
        fetchCandidate(token)
            .then(data => {
                const filteredOptions = data.data.map(item => ({
                    label: item.name,
                    value: item.name,
                    id: item.union_Id
                }));
                setQueryAllStaffOptions(filteredOptions)
            })
            .catch(error => {
                console.log("请求失败:", error);
                setQueryAllStaffOptions([])
            });
    }
    const handleExecuteChange = (value) => {
        setJudgeExecute(value)
        const selectedOption = queryAllStaffOptions.find(option => option.value === value);
        if (selectedOption) {
            const UnionId = selectedOption.id;
            setUnionId(UnionId)
        }
    }
    const supplementMsgClick = (event) => {
        const value = event
        setSupplementMsg(value)
    }

    const deliverLasttimeClick = (date) => {
        const timestamp = new Date(date).getTime()
        setTaskDeliverLasttimeRevise(timestamp)
    }

    const deliverLasttimeInfo = (date) => {
        const timestamp = new Date(date).getTime()
        setTaskDeliverLasttime(timestamp)
    }

    const taskApplyAttach = supplementMsg
    const ApplyTask = (params, other_resource_change, transfer_change) => {
        const apply_transfer_task=()=>{
            if (transfer_change){
                applyTransferTask(params)
                    .then(data => {
                        //console.log(data, 'applyTransferTask');
                        if (data != null){
                            Message.success({
                                id: 'ApplyTask',
                                content: "提交成功",
                            })
                        }
                        init_task_info()
                    })
                    .catch(error => {
                        console.log("请求失败:", error);
                        if (other_resource_change){
                            Message.success({
                                id: 'ApplyTask',
                                content: "其他资源修改提交成功",
                            })
                            init_task_info()
                        }
                    });
            }
        }
        if (transfer_change){
            params.opKey = generateUUID();
        }
        if (other_resource_change){
            applyOtherResourceTask(params)
                .then(data => {
                    if (data != null){
                        if (transfer_change){
                            apply_transfer_task()
                        }else{
                            init_task_info()
                            Message.success({
                                id: 'ApplyTask',
                                content: "提交成功",
                            })
                        }
                    }
                })
                .catch(error => {
                    console.log("请求失败:", error);
                });
        }
        if (!other_resource_change && transfer_change){
            apply_transfer_task()
        }
    }


    function generateUUID() {
        const hexDigits = '0123456789abcdef';
        let uuid = '';

        for (let i = 0; i < 36; i++) {
            if (i === 8 || i === 13 || i === 18 || i === 23) {
                uuid += '-';
            } else if (i === 14) {
                uuid += '4';
            } else if (i === 19) {
                uuid += hexDigits[(Math.random() * 4 | 8)];
            } else {
                uuid += hexDigits[(Math.random() * 16 | 0)];
            }
        }

        return uuid;
    }

    const modificationMsgSubmit = () => {
        // let other_resource_change = false
        let transfer_change = false
        if ((judgeExecute == '' || judgeExecute == record.taskExecuter) &&
            (taskDeliverLasttimeRevise == '' || taskDeliverLasttimeRevise == record.taskDeliverLasttime) &&
            (taskResAttributeRevise == '' || taskResAttributeRevise == record.taskResAttribute) &&
            (taskExpectedTimeRevise == '' || taskExpectedTimeRevise == record.taskExpectedTime)) {
            Message.error({ id: 'modify_apply', content: "至少修改一项！" })
            return
        }
        // if ((taskDeliverLasttimeRevise != '' && taskDeliverLasttimeRevise != record.taskDeliverLasttime) ||
        //     (taskResAttributeRevise != '' && taskResAttributeRevise != record.taskResAttribute) ||
        //     (taskExpectedTimeRevise != '' && taskExpectedTimeRevise != record.taskExpectedTime)) {
        //         other_resource_change = true
        // }
        let params = {}
        if (judgeExecute != '' && judgeExecute != record.taskExecuter){
            transfer_change = true
            params.opKey = generateUUID();
        }
        params.taskKey = record.taskKey
        params.unionID = unionId
        if (unionId == null){
            params.taskExecuterId= record.taskExecuterId
        }else{
            params.taskExecuterId = unionId
        }
        if (taskExpectedTimeRevise == ''){
            params.taskExpectedTime = record.taskExpectedTime
        }else{
            params.taskExpectedTime = taskExpectedTimeRevise
        }
        if (taskDeliverLasttimeRevise == ''){
            params.taskDeliverLasttime = record.taskDeliverLasttime
        }else{
            params.taskDeliverLasttime = taskDeliverLasttimeRevise
        }
        if (taskResAttributeRevise == ''){
            params.taskResAttribute = record.taskResAttribute
        }else{
            params.taskResAttribute = taskResAttributeRevise
        }
        params.taskApplyAttach = taskApplyAttach
        //ApplyTask(params, other_resource_change, transfer_change)
        applyOtherResourceTask(params)
            .then(data => {
                if (data != null){
                    init_task_info()
                    Message.success({
                        id: 'ApplyTask',
                        content: "提交成功",
                    })
                }
            })
            .catch(error => {
                console.log("请求失败:", error);
            });
        closeReviseModal()
    }
    const closeReviseModal = () => {
        setReviseModalVisible(false)
        form_revise.resetFields()
        setTaskExpectedTimeRevise('')
        setTaskResAttributeRevise('')
        setTaskDeliverLasttimeRevise('')
    }
    const onCancelTaskClick = () => {
        cancelTask(record.taskKey, taskCancelReason)
            .then(data => {
                // console.log('onCancelTaskClick',data);
                setCancelTaskModalVisible(false)
                initData()
            })
            .catch(error => {
                console.log("取消失败:", error);
            });
    }
    const onRestartTaskClick = () => {
        createTask(token, record.taskName, record.taskDes, record.taskValueType, record.taskWorkType,
            record.taskExecuteTimeStandard, record.taskExecuteAddressStandard,
            record.taskExecuteOtherStandard, record.taskCheckStandard, record.taskExpectedTime,
            record.taskDeliverLasttime, record.taskSkillAttribute, record.taskResAttribute,
            record.taskChecker, record.taskCheckerId, record.taskFlowWork, record.taskNodeWork,
            record.taskExecuter, record.taskExecuterId, record.taskApproverId, record.taskApproverName,
            record.taskWork, record.taskStandardPay, record.taskWorkhoursType, taskRestartReason)
            .then(data => {
                // console.log('onRestartTaskClick',data);
                setRestartTaskModalVisible(false)
                initData()
            })
            .catch(error => {
                console.log("重启失败:", error);
            });
    }



    return (
        <CollapseItem className={"custom_style"} header='任务信息' name='2'>
            <div className="column-block" style={{ paddingLeft: '5%', display: taskReviseLog.length > 0 ? "" : "none" }}>
                <span style={{ marginBottom: '1%' }}>任务修改记录</span>
                <List
                    style={{ width: 700, maxHeight: 150 }}
                    className={"customListStyle"}
                    dataSource={taskReviseLog}
                    render={(item, index) =>
                        <List.Item key={index}>{item}</List.Item>}
                />
            </div>
            <Form className={'taskinfo-form'} form={form_info}
                autoComplete='off' labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}>
                <div className="form-item-block">
                    <Form.Item disabled label='任务分类' rules={[{ required: true }]}>
                        <Input value={taskClassification} />
                    </Form.Item>
                    <Form.Item disabled label='任务名称' rules={[{ required: true }]}>
                        <Input value={record.taskName} />
                    </Form.Item>
                </div>
                <div className="form-item-block">
                    <Form.Item label='详细描述' field='详细描述'
                        wrapperCol={{ span: 19 }} labelCol={{ span: 4 }}
                        initialValue={record.taskDes} rules={[{ required: true }]}
                        disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])}>
                        <TextArea autoSize={{ minRows: 4 }}
                            onChange={(v) => setTaskDescribe(v)}>
                        </TextArea>
                    </Form.Item>
                </div>
                <div className="form-item-block">
                    <Form.Item disabled label='执行人' rules={[{ required: true }]}>
                        <Select
                            disabled
                            defaultValue={record.taskExecuter}
                            showSearch={{
                                retainInputValue: true,
                            }}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item disabled label='审批人' rules={[{ required: true }]}>
                        <Select className="taskApproverDetail"
                            defaultValue={record.taskApproverName} disabled></Select>
                    </Form.Item>
                </div>
                <div className="form-item-block">
                    <Form.Item label='验收人' field='验收人' disabled={!isEditing} 
                            rules={[{ required: true }]} initialValue={record.taskChecker}>
                        <Select
                        placeholder='请输入'
                        onClick={handleCheckerClick}
                        onChange={handleCheckerChange}
                        allowClear
                        >
                        {queryCheckerOptions.map((option) => (
                            <Option key={option.union_Id} value={option.name}>
                            <Avatar size={24}>
                                <img alt='avatar' src={option.avatar_url}/>
                            </Avatar>
                            {option.name}
                            </Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])}
                        label='验收标准' field='验收标准'
                        initialValue={record.taskCheckStandard}>
                        <Input onChange={(value) => setTaskCheckStandard(value)}></Input>
                    </Form.Item>
                </div>
                <div className="form-item-block">
                    <Form.Item label='价值类型' field='价值类型'
                        initialValue={dataChangeMap.taskValueType[record.taskValueType]}
                        disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])}
                        rules={[{ required: true }]}>
                        <Select
                            className="taskValueTypeDetail"
                            options={[
                                {
                                    label: '基础型',
                                    value: 0,
                                },
                                {
                                    label: '灵活型',
                                    value: 1,
                                },
                                {
                                    label: '创新型',
                                    value: 2,
                                },
                                {
                                    label: '复合型',
                                    value: 3,
                                },
                                {
                                    label: '学习型',
                                    value: 4,
                                },
                                {
                                    label: '其他',
                                    value: 5,
                                },
                            ]}
                            allowClear
                            onChange={(value) => setTaskValueType(value)}
                        />
                    </Form.Item>
                    <Form.Item label='开展方式' field='开展方式'
                        initialValue={dataChangeMap.taskWorkType[record.taskWorkType]}
                        disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])} rules={[{ required: true }]}>
                        <Select
                            className="taskWorkTypeDetail"
                            placeholder='请输入'
                            options={[
                                {
                                    label: '独立执行',
                                    value: 0,
                                },
                                {
                                    label: '会议',
                                    value: 1,
                                },

                            ]}
                            allowClear
                            onChange={(value) => setTaskWorkType(value)}
                        />
                    </Form.Item>
                </div>
                <div className="form-item-block">
                    <Form.Item disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])}
                        label='所需技能' field='所需技能'
                        initialValue={record.taskSkillAttribute}>
                        <Input className='taskSkillAttributeDetail'
                            onChange={(v) => setTaskSkillAttribute(v)} >
                        </Input>
                    </Form.Item>
                    <Form.Item disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])}
                        label='所需资源' field='所需资源'
                        initialValue={record.taskResAttribute}>
                        <Input className='taskResAttributeDetail'
                            onChange={(v) => setTaskResAttribute(v)} >
                        </Input>
                    </Form.Item>
                </div>
                <div className="form-item-block">
                    <Form.Item label='预计耗时' field='预计耗时'
                        disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])} rules={[{ required: true }]}
                        initialValue={record.taskExpectedTime}>
                        <InputNumber
                            min={0}
                            suffix="小时"
                            onChange={v => setTaskExpectedTime(v)} />
                    </Form.Item>
                    <Form.Item disabled={!isEditing} label='截止时间'
                        filed='截止时间0' rules={[{ required: true }]}
                        initialValue={moment(record.taskDeliverLasttime).format('YYYY-MM-DD HH:mm:ss')}>
                        <DatePicker
                            showTime style={{ width: 230 }}
                            position="bottom"
                            value={moment(record.taskDeliverLasttime).format('YYYY-MM-DD HH:mm:ss')}
                            onChange={deliverLasttimeInfo} />
                    </Form.Item>
                </div>
                <Space className={"require_space"}>
                    <Form.Item disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])} label='时间要求'
                        field='timeType' initialValue={timeRequirement} >
                        <RadioGroup
                            style={{ width: 180 }}
                            onChange={(value) => handleTimeRequirementChange(value)}
                        >
                            <Radio value="0">不限</Radio>
                            <Radio value="1">固定时间</Radio>
                        </RadioGroup>
                    </Form.Item>
                    {timeRequirement === "1" &&
                        <Form.Item field='固定时间' rules={[{ required: true }]}
                            initialValue={selectedDates} disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])}>
                            <DatePicker.RangePicker status='warning' placeholder='warning status'
                                showTime={{
                                    defaultValue: '23:59:59',
                                }}
                                style={{ width: 400 }}
                                onChange={handleDatePickerChange}
                                format='YYYY-MM-DD HH:mm:ss'
                            />
                        </Form.Item>
                    }
                </Space>
                <Space className={"require_space"}>
                    <Form.Item disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])} label='地点要求'
                        field='placeType' initialValue={taskPlaceRequirement}>
                        <RadioGroup
                            style={{ width: 180 }}
                            onChange={(value) => handlePlaceRequirementChange(value)}
                        >
                            <Radio value="0">不限</Radio>
                            <Radio value="1">固定地点</Radio>
                        </RadioGroup>
                    </Form.Item>
                    {taskPlaceRequirement === "1" &&
                        <Form.Item field='固定地点' rules={[{ required: true }]}
                            initialValue={selectedPlace} disabled={!isEditing || (record.taskState == taskWordToNum.taskState["checking"])}>
                            <Input placeholder="请输入" style={{ width: 400 }}
                                onChange={handleTextAreaChange} />
                        </Form.Item>
                    }
                </Space>
                <div className="form-item-block">
                    <Form.Item label='修改原因' wrapperCol={{ span: 19 }} labelCol={{ span: 4 }}
                        rules={[{ required: isEditing &&
                            record.taskState == taskWordToNum.taskState["creating"] }]} field='创建修改原因'
                        style={{
                            display: isEditing &&
                                record.taskState == taskWordToNum.taskState["creating"] ? "" : "none"
                        }}>
                        <TextArea
                            placeholder="请输入"
                            onChange={(value) => setCreateReviseReason(value)} />
                    </Form.Item>
                </div>

                <Form.Item style={{ marginLeft: '5%' }}>
                    <div className="applyer" style={{
                        display: (
                            (record.taskState == taskWordToNum.taskState["submitting"]) &&
                            (record.taskExecuterId == userID && record.taskApproverId != userID)) ? "" : "none"
                    }}>
                        <Button type='primary' onClick={() => setReviseModalVisible(true)}>申请修改</Button>
                    </div>

                    <Button type='primary' onClick={() => setRestartTaskModalVisible(true)}
                        style={{
                            display: record.taskState == taskWordToNum.taskState["cancel"]
                                ? "" : "none"
                        }}>
                        重启任务
                    </Button>

                    <div className="approver" style={{ display: (record.taskApproverId == userID) ? "" : "none" }}>
                        <div className="create_task" style={{
                            display:
                                (!isEditing && record.taskState == taskWordToNum.taskState['creating']) ? "" : "none"
                        }}>
                            <div style={{display: taskInfoJson["isRestart"] ? "none":""}}>
                                <Button type='primary' onClick={() => setCancelCreateModalVisible(true)}>驳回创建</Button>
                                <Button type='primary' onClick={() => approveTaskInit(1, form_info)}>通过创建</Button>
                                <Button type='primary' onClick={onEditClick}>修改任务</Button>
                            </div>
                            <div style={{display: taskInfoJson["isRestart"] ? "":"none"}}>
                                <Button type='primary' onClick={() => setCancelCreateModalVisible(true)}>驳回重启</Button>
                                <Button type='primary' onClick={() => approveTaskInit(1, form_info)}>通过重启</Button>
                                <Button type='primary' onClick={onEditClick}>修改任务</Button>
                            </div>
                        </div>
                        <Button type='primary' onClick={() => setCancelTaskModalVisible(true)}
                            style={{
                                display: !isEditing && (record.taskState == taskWordToNum.taskState["submitting"])
                                    ? "" : "none"
                            }}>
                            取消任务
                        </Button>
                        <Button type='primary' onClick={onEditClick} style={{
                            display:
                                !isEditing && (record.taskState == taskWordToNum.taskState["submitting"] ||
                                    record.taskState == taskWordToNum.taskState["checking"]) ? "" : "none"
                        }}>
                            修改任务
                        </Button>
                        <div className="revise_state" style={{ display: isEditing ? "" : "none" }}>
                            <Button type='primary' onClick={onCancelEdit}>取消修改</Button>
                            <Button type='primary' onClick={() => approveTaskInit(2, form_info)}
                                style={{
                                    display: (record.taskState == taskWordToNum.taskState['creating']) ? "" : "none"
                                }}>提交并创建
                            </Button>
                            <Button type='primary' onClick={()=>submitEditTask(form_info)}
                                style={{
                                    display: (record.taskState != taskWordToNum.taskState['creating']) ? "" : "none"
                                }}>提交修改
                            </Button>
                        </div>
                    </div>

                </Form.Item>
            </Form>
            <Modal
                title='取消任务' visible={cancelTaskModalVisible} mountOnEnter={false}
                footer={null} autoFocus={false} focusLock={true}
                onCancel={() => setCancelTaskModalVisible(false)}
            >
                <Form onSubmit={onCancelTaskClick}>
                    <Form.Item label='取消原因' field="取消任务原因" rules={[{ required: true }]}>
                        <Input.TextArea onChange={v => setTaskCancelReason(v)}
                        />
                    </Form.Item>
                    <Button type='primary' htmlType='submit'>
                        确认取消
                    </Button>
                </Form>
            </Modal>
            <Modal
                title='重启任务' visible={restartTaskModalVisible} mountOnEnter={false}
                footer={null} autoFocus={false} focusLock={true}
                onCancel={() => setRestartTaskModalVisible(false)}
            >
                <Form onSubmit={onRestartTaskClick}>
                    <Form.Item label='重启原因' field="重启任务原因" rules={[{ required: true }]}>
                        <Input.TextArea onChange={v => setTaskRestartReason(v)}
                        />
                    </Form.Item>
                    <Button type='primary' htmlType='submit'>
                        确认重启
                    </Button>
                </Form>
            </Modal>
            <Modal
                title='驳回创建任务' visible={cancelCreateModalVisible} mountOnEnter={false}
                footer={null} autoFocus={false} focusLock={true}
                onCancel={() => setCancelCreateModalVisible(false)}
            >
                <Form onSubmit={(e) => approveTaskInit(0, form_create_cancel)} form={form_create_cancel}>
                    <Form.Item label='驳回原因' field="驳回创建原因" rules={[{ required: true }]}>
                        <Input.TextArea onChange={v => setCreateCancelReason(v)}
                        />
                    </Form.Item>
                    <Button type='primary' htmlType='submit'>
                        确认驳回
                    </Button>
                </Form>
            </Modal>
            <Modal title='任务修改申请' visible={reviseModalVisible} mountOnEnter={false}
                onCancel={closeReviseModal} className="modificationModal">
                <Form labelCol={{ span: 4 }} form={form_revise}>
                    <Form.Item label="执行人">
                        <Space>
                            <Form.Item>
                                <Input value={record.taskExecuter} disabled />
                            </Form.Item>
                            <IconArrowRight style={{ marginBottom: '10px' }} />
                            <Form.Item field='executer_revise'>
                                <Select
                                    placeholder='请输入'
                                    onClick={handleExecuteClick}
                                    onChange={handleExecuteChange}
                                    showSearch={{
                                        retainInputValue: true,
                                    }}
                                    allowClear
                                    style={{ width: '200px' }}
                                >
                                    {queryAllStaffOptions.map(option => (
                                        <Option key={option.id} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Tooltip content='若修改执行人，审批通过后将自动转派任务，原任务将自动取消。'>
                            <IconExclamationCircle style={{ margin: '0 0 15 0', color: 'rgb(var(--arcoblue-6))' }} />
                            </Tooltip>
                        </Space>
                    </Form.Item>
                    <Form.Item label='截止时间'>
                        <Space>
                            <Form.Item>
                                <Input disabled
                                    value={moment(record.taskDeliverLasttime).format('YYYY-MM-DD HH:mm:ss')} />
                            </Form.Item>
                            <IconArrowRight style={{ marginBottom: '10px' }} />
                            <Form.Item field='deadline_revise'>
                                <DatePicker
                                    showTime={{
                                        defaultValue: '23:59:59',
                                    }}
                                    format='YYYY-MM-DD HH:mm:ss'
                                    onChange={deliverLasttimeClick}
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label='所需资源'>
                        <Space>
                            <Form.Item >
                                <Input disabled value={record.taskResAttribute} />
                            </Form.Item>
                            <IconArrowRight style={{ marginBottom: '10px' }} />
                            <Form.Item field='resource_revise'>
                                <Input onChange={(v) => setTaskResAttributeRevise(v)}
                                    style={{ width: '200px' }}></Input>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label='预计耗时'>
                        <Space>
                            <Form.Item >
                                <InputNumber
                                    disabled
                                    suffix="小时"
                                    value={record.taskExpectedTime} />
                            </Form.Item>
                            <IconArrowRight style={{ marginBottom: '10px' }} />
                            <Form.Item field='expected_time_revise'>
                                <InputNumber
                                    min={0}
                                    suffix="小时"
                                    onChange={(v) => setTaskExpectedTimeRevise(v)} />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label='补充说明' field='other_revise'>
                        <Input onChange={supplementMsgClick}></Input>
                    </Form.Item>
                    <Button onClick={modificationMsgSubmit} type='primary'>
                        提交申请
                    </Button>
                </Form>
            </Modal>

        </CollapseItem>
    )
}

export default TaskInfoModule
/* eslint-enable */

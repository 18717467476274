/* eslint-disable */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import './index.scss'
import {
    Table,
    Button,
    Input,
    Select,
    Modal,
    Message,
    Icon
} from '@arco-design/web-react'
import { dataChangeMap } from '../../../../../config/dataChange'
import { queryTaskListByDraft, DraftsPara, deleteDrafts } from "../../../../../api/DraftsApi";
import Drafts_op_model from "./drafts_module";

const IconFont = Icon.addFromIconFontCn({
    src: '//at.alicdn.com/t/font_180975_26f1p759rvn.js',
});

const MyDrafts = ({ token, userID, change, onNotify }) => {
    const [pageThreeData, setPageThreeData] = useState('')
    const [taskTypePage3, setTaskTypePage3] = useState('')
    const [taskNamePage3, setTaskNamePage3] = useState('')
    const [taskExecuterPage3, setTaskExecuterPage3] = useState('')
    const [type, setType] = useState('checkbox');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visible, setVisible] = useState(false);
    // 使用 React.memo
    const TaskDetailModalMemo = React.memo(Drafts_op_model);


    const handleChange = (value, name) => {
        if (typeof (value) == "undefined") {
            value = ''
        }
        switch (name) {
            case 'taskTypePage3':
                setTaskTypePage3(value);
                break;
            case 'taskExecuterPage3':
                setTaskExecuterPage3(value);
                break;
            case 'taskNamePage3':
                setTaskNamePage3(value);
                break;
            default:
                break;
        }
    }
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10
        }
    })

    const fetchData = () => {
        let para = new DraftsPara()
        para.taskName = taskNamePage3
        para.taskValueType = taskTypePage3
        para.taskExecuter = taskExecuterPage3
        queryTaskListByDraft(para, function (data) {
            if (data && data['list']) {
                setPageThreeData(data['list'])
                setTableParams(tableParams => ({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: data['totalCount']
                    }
                }))
            } else {
                setPageThreeData([])
                setTableParams(tableParams => ({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: 0
                    }
                }))
            }
        })
    }
    useEffect(() => {
        fetchData()
    }, [taskNamePage3, taskTypePage3, taskExecuterPage3, change])

    const handleTableChange = (pagination) => {
        setTableParams({
            pagination
        })
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setPageThreeData([])
        }
    }

    const clearLocalStorage = () => {
        setTaskTypePage3('');
        setTaskExecuterPage3('');
        setTaskNamePage3('');
    }

    const closeCallback = (result) => {
        if (result === null) {
            return
        }
        if (result === 'drafts') {
            fetchData()
        }

    }

    //批量删除
    const deleteSelectDrafts = () => {
        deleteDrafts(selectedRowKeys, function (data) {
            setSelectedRowKeys([])
            fetchData()
            setVisible(false)
            Message.success({
                icon: <IconFont type='icon-success' />,
                content: `选中草稿删除成功!`,
            });
        })
    }


    const columns = [
        {
            title: '更新时间',
            dataIndex: 'taskUpdateddate',
            key: '0',
            width: 100,
            sorter: (a, b) => b.taskUpdateddate - a.taskUpdateddate,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '任务分类',
            width: 100,
            dataIndex: 'taskInfo',
            key: '011',
            render: (value, record) => {
                if (record.taskFlowWork === null) {
                    return '—';
                }

                return `${record.taskFlowWork}#${record.taskNodeWork}`;
            }
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: '1',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '价值类型',
            dataIndex: 'taskValueType',
            key: '2',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskValueType[value]
                }
            }
        },
        {
            title: '开展方式',
            dataIndex: 'taskWorkType',
            key: '3',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskWorkType[value]
                }
            }
        },
        {
            title: '时间要求',
            dataIndex: 'taskExecuteTimeStandard',
            key: '4',
            width: 165,
            render: (value) => {
                if (value === '0') {
                    return '不限';
                } else if (typeof value === 'string' && value.startsWith("1#")) {
                    const timeRange = value.split("#").slice(1); // 获取毫秒数时间范围
                    const startDate = new Date(parseInt(timeRange[0]));
                    const endDate = new Date(parseInt(timeRange[1]));
                    const startTime = startDate.toLocaleString(); // 格式化开始时间（包括时:分:秒）
                    const endTime = endDate.toLocaleString(); // 格式化结束时间（包括时:分:秒）
                    return (
                        <div>
                            {startTime}
                            <br />
                            至
                            <br />
                            {endTime}
                        </div>
                    );
                } else {
                    return '—'
                }
            },
        },
        {
            title: '地点要求',
            dataIndex: 'taskExecuteAddressStandard',
            key: '5',
            width: 100,
            render: (value) => {
                if (value === '0') {
                    return '不限';
                } else if (typeof value === 'string' && value.startsWith("1#")) {
                    const place = value.split("#").slice(1)
                    return `${place}`;
                } else {
                    return '—';
                }
            },
        },
        {
            title: '执行人',
            dataIndex: 'taskExecuter',
            key: '6',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '审批人',
            dataIndex: 'taskApproverName',
            key: '7',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '预计耗时',
            dataIndex: 'taskExpectedTime',
            key: '8',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return `${value}小时`;
                }
            }
        },
        {
            title: '截止时间',
            dataIndex: 'taskDeliverLasttime',
            key: '9',
            width: 100,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '操作',
            key: 'op',
            fixed: 'right',
            width: 150,
            render: (col, record, index) => <TaskDetailModalMemo token={token} onClose={closeCallback} record={record} onNotify={onNotify} />
        }
    ]


    return (
        <div className="page3">
            <div className='page3-header'>
                <div className="filterType">
                    <span className='selectType'>任务名称</span>
                    <Input
                        placeholder='请输入'
                        style={{ width: 200 }}
                        value={taskNamePage3}
                        onChange={(value) => handleChange(value, 'taskNamePage3')}
                    />
                </div>
                <div className="filterType">
                    <span className='selectType'>价值类型</span>
                    <Select
                        placeholder='请选择'
                        style={{
                            width: 120,
                        }}
                        value={taskTypePage3}
                        allowClear
                        onChange={(value) => handleChange(value, 'taskTypePage3')}
                        options={[
                            {
                                value: '0',
                                label: '基础型',
                            },
                            {
                                value: '1',
                                label: '灵活型',
                            },
                            {
                                value: '2',
                                label: '创新型',
                            },
                            {
                                value: '3',
                                label: '复合型',
                            },
                            {
                                value: '4',
                                label: '学习型',
                            },
                            {
                                value: '5',
                                label: '其他类型',
                            }
                        ]}
                    />
                </div>
                <div className="filterType">
                    <span className='selectType'>执行人 </span>
                    <Input
                        placeholder='请输入'
                        style={{ width: 120 }}
                        value={taskExecuterPage3}
                        onChange={(value) => handleChange(value, 'taskExecuterPage3')}
                    />
                </div>


                <div className="reset">
                    <Button className='btn'
                        onClick={clearLocalStorage}>
                        <span>重置</span>
                    </Button>
                </div>

                <div className="reset">
                    <Button className='deleteDraft' disabled={selectedRowKeys.length === 0}
                        onClick={() => setVisible(true)} type='primary'>
                        <span>批量删除</span>
                    </Button>
                    <Modal
                        title={
                            <div style={{ textAlign: 'left' }}>
                                注意
                            </div>
                        }
                        visible={visible}
                        onOk={() => deleteSelectDrafts()}
                        onCancel={() => setVisible(false)}
                        autoFocus={false}
                        focusLock={true}
                        cancelButtonProps={{
                            className: 'deleteDraft',
                            style: {
                                position: 'relative',
                                left: 70,
                                backgroundColor: 'white',
                                color: '#1496db',
                                border: '1px solid #1496db'
                            }
                        }}
                        okButtonProps={{
                            className: "deleteDraft",
                            style: {
                                position: 'relative',
                                left: -420,
                                backgroundColor: 'white',
                                color: '#1496db',
                                border: '1px solid #1496db'
                            }
                        }}
                    >
                        <p>
                            将删除选中的草稿,一旦删除将无法恢复!
                            <br></br><br></br>
                            请确认是否删除？
                        </p>
                    </Modal>
                </div>

            </div>
            <div className="page3-wrap">
                <Table
                    rowKey="tid"
                    borderCell
                    columns={columns}
                    data={pageThreeData}
                    pagination={tableParams.pagination}
                    scroll={{
                        x: 1500
                    }}
                    rowSelection={{
                        type,
                        selectedRowKeys,
                        onSelect: (selected, record, selectedRows) => {
                            if (selected) {
                                setSelectedRowKeys(selectedRowKeys.concat(record.tid));
                            } else {
                                setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.tid));
                            }
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            if (selected) {
                                const newSelectedRowKeys = selectedRows.map(row => row.tid);
                                setSelectedRowKeys(newSelectedRowKeys);
                            } else {
                                setSelectedRowKeys([]);
                            }
                        }
                    }}
                    onChange={handleTableChange}
                />
            </div>
        </div>
    )
}

export default MyDrafts
/* eslint-enable */

/* eslint-disable */
import {taskWordToNum, taskResultOptions} from "../../config/dataChange";
import React, { useState, useRef } from 'react'
import {
    uploadFile, applyAcceptTask, approveAcceptTask
} from "../../api/getExport"
import {
    Form, Modal, Collapse, Upload, Button, 
    Select, Input, Space, Message,InputNumber,List,Notification
} from '@arco-design/web-react'
import './index.scss'
const Option = Select.Option;
const CollapseItem = Collapse.Item;
const TextArea = Input.TextArea;

const DeliveryModule = ({token, record, deliveryInfo, userID, initData}) => {
    const [rejectForm] = Form.useForm()
    const [passForm] = Form.useForm()
    const [fileForm] = Form.useForm()
    //交付信息板块变量
    const [reworkModalVisible, setReworkModalVisible] = useState(false)
    const [reworkReason, setReworkReason] = useState("")
    const [checkresultModalVisible, setCheckresultModalVisible] = useState(false)
    const [taskCheckResult, setTaskCheckResult] = useState(0)
    const [checkExpectedTime, setCheckExpectedTime] = useState(record.taskExpectedTime)
    //上传图片
    const uploadRef = useRef();
    const taskAttachJson = JSON.parse(record.taskAttach)
    const [fileList, setFileList] = useState(
        taskAttachJson != null ? taskAttachJson.taskAttachFileUrl : []
    );
    const [inputValue, setInputValue] = useState(taskAttachJson != null ? 
        taskAttachJson.taskAttachDes : ""
    );

    const init_delivery_info=()=>{
        setReworkReason("")
        setTaskCheckResult(0)
        rejectForm.clearFields()
        passForm.resetFields()
        initData()
    }
    const clearInfo=()=>{
        fileForm.clearFields()
        setFileList([])
        setInputValue("")
    }

    const [totalSize, setTotalSize] = useState(0);

    const onApplySubmit = (e) => {
        const maxSizeOfSingleFile = 5 * 1024 * 1024; // 单个文件最大5MB
        const maxTotalSize = 25 * 1024 * 1024; // 文件总大小最大25MB
        let totalSize = 0;

        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].originFile.size > maxSizeOfSingleFile) {
                Message.error('单个文件大小不能超过5MB!');
                return false;
            }

            totalSize += fileList[i].originFile.size;

            if (totalSize > maxTotalSize) {
                Message.error('文件总大小不能超过25MB!');
                return false;
            }
        }

        Message.loading({
            id: 'onApplySubmit',
            content: '提交中',
            duration: 0
        });
        const taskKey = record.taskKey
        if (fileList.length == 0 && inputValue==""){
            Message.error({
                id: 'onApplySubmit',
                content: '附件和任务说明必填其一!'
            })
            return
        }
        e.stopPropagation();


        if (fileList.length !== 0) {
            console.log("fileList",fileList[0].originFile.size,'1111')
            uploadRef.current && uploadFile(token, record.taskName, inputValue, fileList)
                .then((response) => {
                    //let jsonObj = { 'taskAttachFileUrl': response.data.taskAttachFileUrl, 'taskAttachDes': inputValue }
                    let file_list = []
                    let file_url_list = response.data.taskAttachFileUrl
                    for (let i=0;i<fileList.length;i++){
                        let url_json = {}
                        url_json["uid"] = fileList[i]["uid"]
                        url_json["name"] = fileList[i]["name"]
                        url_json["url"] = file_url_list[i]
                        file_list.push(url_json)
                    }
                    setFileList(file_list)
                    let jsonObj = { 'taskAttachFileUrl': file_list, 'taskAttachDes': inputValue }
                    let taskAttach = JSON.stringify(jsonObj)
                    applyAcceptTask(token, taskAttach, taskKey)
                        .then((response2) => {
                            //console.log("response2", response2,taskAttach, taskKey);
                            Message.success({
                                id: 'onApplySubmit',
                                content: "提交成功",
                            })
                            init_delivery_info()
                        })
                        .catch((error2) => {
                            // Handle errors
                            Message.error({
                                id: 'onApplySubmit',
                                content: error2
                            })
                            console.error(error2);
                        });
                })
                .catch((error) => {
                    // Handle errors
                    console.error(error);
                    Message.error({
                        id: 'onApplySubmit',
                        content: error
                    })
                });
        }else{
            let jsonObj = { 'taskAttachFileUrl': [], 'taskAttachDes': inputValue }
            let taskAttach = JSON.stringify(jsonObj)
            applyAcceptTask(token, taskAttach, taskKey)
                .then((response) => {
                    //console.log("response2", response2,taskAttach, taskKey);
                    Message.success({
                        id: 'onApplySubmit',
                        content: "提交成功",
                    })
                    init_delivery_info()
                })
                .catch((error2) => {
                    // Handle errors
                    Message.error({
                        id: 'onApplySubmit',
                        content: error2
                    })
                    console.error(error2);
                });
        }
        
    }
    const onSelectFileChange = (files) => {
        setFileList(files);
    };
    const onProgress = (file) => {
        // 更新文件进度，保持不变
        setFileList((currentFiles) => {
            return currentFiles.map((x) => (x.uid === file.uid ? { ...x, progress: file.progress } : x));
        });
    };

    //提交验收审批
    const onCheck = (approve_result, check_result="", check_feedback="", check_expected_time=0)=>{
        let params = {}
        params.token = token
        params.taskKey = record.taskKey
        params.taskApproveName = record.taskChecker
        params.taskApproveId = record.taskCheckerId
        params.taskApproveType = taskWordToNum.taskApproveType["task_check"]
        params.taskApproveResult = approve_result
        params.taskCheckResult = check_result
        params.taskApproveAdvice = check_feedback
        let approve_content = {}
        if (check_expected_time>0){
            approve_content["taskRealTime"] = check_expected_time
        }
        params.taskApproveContent = JSON.stringify(approve_content)
        approveAcceptTask(params)
            .then(data=>{
                console.log('验收操作成功', params)
                setReworkModalVisible(false)
                setCheckresultModalVisible(false)
                init_delivery_info()
                if (approve_result==taskWordToNum.taskApproveResult["partly_adjust"]){
                    clearInfo()
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
        });
    }

    return (<CollapseItem className={'custom_style'} header='交付信息' name='3'>
        <div className={"column-block"}
            style={{display:deliveryInfo.length > 0
                    ? "" : "none", marginBottom: '5%',marginLeft:'5%' }}>
            <span style={{ marginBottom: '1%'}}>验收反馈记录</span>
            <List
                style={{ width: 700, maxHeight: 150}}
                className={"customListStyle"}
                dataSource={deliveryInfo}
                render={(item, index) => 
                    <List.Item key={index}>{item}</List.Item>}
            />
        </div>
        
        <Form className={'fileUpload-form'} form={fileForm}>
            <Form.Item label="任务附件" field='任务附件'>
                <Upload
                    ref={uploadRef}
                    multiple
                    autoUpload={false}
                    action='/'
                    onChange={onSelectFileChange}
                    onProgress={onProgress}
                    fileList={fileList}
                    className={'fileUpload'}
                    showUploadList={{
                        removeIcon: (record.taskState==taskWordToNum.taskState["submitting"] ? "": null)
                      }}
            
                >
                    <Button type='primary' 
                        style={{display:record.taskState==taskWordToNum.taskState["submitting"]?"":"none"}}>
                        选择文件
                    </Button>
                </Upload>
            </Form.Item>
            <Form.Item label='任务说明' field='任务说明'>
                <TextArea
                    disabled={record.taskState != taskWordToNum.taskState["submitting"]}
                    value={inputValue}
                    onChange={(v)=>setInputValue(v)}
                />
            </Form.Item>
            <Form.Item>
                <Button style={{display:record.taskState==taskWordToNum.taskState["submitting"]
                        ?"":"none", marginLeft: '8%' }} type='primary' onClick={onApplySubmit}>
                    确认提交
                </Button>
            </Form.Item>
        </Form>
        <Space style={{display:(record.taskState==taskWordToNum.taskState["checking"]) &&
                    (userID==record.taskCheckerId)
                    ?"":"none", marginLeft:'2%'}}  >
            <Button type='primary'
                    onClick={()=>setReworkModalVisible(true)}>
                驳回返工
            </Button>
            <Button type='primary' 
                    onClick={()=>setCheckresultModalVisible(true)}>
                结果评定
            </Button>
        </Space>

        <Modal
            title='驳回返工'
            visible={reworkModalVisible}
            footer={null}
            autoFocus={false}
            focusLock={true}
            onCancel={() => setReworkModalVisible(false)}
        >
            <Form form={rejectForm} onSubmit={()=>onCheck(taskWordToNum.taskApproveResult["partly_adjust"], "", reworkReason)}>
                <Form.Item label='返工原因' field="返工原因" rules={[{ required: true }]}>
                    <Input.TextArea onChange={v=>setReworkReason(v)}
                    />
                </Form.Item>
                <Button type='primary' htmlType='submit'>确认</Button>
            </Form>
        </Modal>
        <Modal
            title='结果评定'
            visible={checkresultModalVisible}
            footer={null}
            autoFocus={false}
            focusLock={true}
            onCancel={() => setCheckresultModalVisible(false)}
        >
            <Form form={passForm} onSubmit={()=>
                onCheck((taskCheckResult=="达标")
                        ? taskWordToNum.taskApproveResult["pass"]
                        : taskWordToNum.taskApproveResult["rejected"],
                        taskWordToNum.checkResult[taskCheckResult], "", checkExpectedTime)}
                style={{width:'70%', marginLeft:'14%'}}
                labelCol={{span: 7}} wrapperCol={{span: 12}}>
                <Form.Item label='任务效果' field="任务效果" rules={[{ required: true }]}>
                    <Select
                        placeholder='请选择'
                        onChange={(value) =>setTaskCheckResult(value)}
                    >
                        {taskResultOptions.map((taskResultOptions, index) => (
                        <Option key={taskResultOptions} disabled={index === 3} value={taskResultOptions}>
                            {taskResultOptions}
                        </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='认定工时' field="认定工时" 
                        rules={[{ required: true }]} initialValue={record.taskExpectedTime}>
                    <InputNumber
                        min={0}
                        suffix="小时"
                        onChange={v => setCheckExpectedTime(v)} />
                </Form.Item>
                <Button type='primary' htmlType='submit'>确认提交</Button>
            </Form>
        </Modal>
    </CollapseItem>
    )
}

export default DeliveryModule
 /* eslint-enable */
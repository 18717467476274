import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { lazy } from 'react'
import NotFound from './pages/notfound/index.js';
import { Home } from './pages/home';
import React from 'react';
const TaskList = lazy(() => import('./components/layout/myTask/taskList'))
const ProgressDashboard = lazy(() => import('./components/layout/myTask/progressDashboard/index.jsx'))
const TeamTaskList = lazy(() => import('./components/layout/teamManage/teamTaskList/index.jsx'))
const MemberProgress = lazy(() => import('./components/layout/teamManage/teamTaskList/index.jsx'))
const Liuliang = lazy(() => import('./components/layout/myManage/liuliang/index.jsx'))
const Rencaiku = lazy(() => import('./components/layout/myManage/rencaiku/index.jsx'))

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="taskList" element={<TaskList />} />
          <Route path="progressDashboard" element={<ProgressDashboard />} />
          <Route path="/teamTaskList" element={<TeamTaskList />} />
          <Route path="/memberProgress" element={<MemberProgress />} />
          <Route path="/liuliang" element={<Liuliang />} />
          <Route path="/rencaiku" element={<Rencaiku />} />
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;

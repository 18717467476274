/* eslint-disable */
import React, { useState, useEffect } from 'react'
import read from '../../../.././../assets/image/read.svg'
import unread from '../../../.././../assets/image/unread.svg'
import './index.scss'
import { DatePicker, Button, Select, Table, Cascader,Input } from '@arco-design/web-react'
import { fetchMyChecked, fetchSecondTask, fetchThirdTask, } from '../../../../../api/getExport'
import { dataChangeMap } from "../../../../../config/dataChange";
import TaskDetailModal from '../../../../task_detail_model'
import moment from "moment";
import './index.scss'

const optionsList = [
    {
        value: 0,
        label: '项目',
        isLeaf: false,
    },
    {
        value: 1,
        label: '事务',
        isLeaf: false,
    },
    {
        value: 2,
        label: '业务',
        isLeaf: false,
    },
    {
        value: 3,
        label: '事项',
        isLeaf: false,
    },
    {
        value: 4,
        label: '其他',
        isLeaf: false,
    }
]

const MyCheck = ({ token, userID, change, notifyRefresh }) => {
    const [pageFiveData, setPageFiveData] = React.useState([])
    const [taskStateValue, setTaskStateValue] = useState('2');
    const [approveResultValue, setApproveResultValue] = useState('');
    const [taskCheckTimesValue, setTaskCheckTimesValue] = useState('');
    const [dateRange, setDateRang] = useState(null)
    const [taskClassify, setTaskClassify] = useState('')
    const [taskName, setTaskName] = useState('')
    const [options, setOptions] = useState(optionsList)
    const [projectKey, setProjectKey] = useState(null)

    const handleTaskNameChange = (event) => {
        setTaskName(event)
        localStorage.setItem('taskName', event)
    }
    const handleChange = (value, name) => {
        if (typeof (value) == "undefined") {
            if (name === 'taskCreatedTimePage5') {
                value = ['', '']
            } else {
                value = ''
            }
        }
        switch (name) {
            case 'taskStateValue':
                setTaskStateValue(value);
                break;
            case 'approveResultValue':
                setApproveResultValue(value);
                break;
            case 'taskCheckTimesValue':
                setTaskCheckTimesValue(value);
                break;
            case 'taskCreatedTimePage5':
                setDateRang([value[0], value[1]]);
                break;
            default:
                break;
        }
    }
    const loadMore = async (pathValue, level) => {
        try {
            if (level === 1) {
                const data = await fetchSecondTask(token, pathValue);
                pathValue = data.data;
            } else if (level === 2) {
                const values = pathValue.toString();
                const value = values.split(',')[1];
                const data = await fetchThirdTask(token, value);
                const ProjectKey = data.data[2].taskWorkProject;
                setProjectKey(ProjectKey);
                pathValue = data.data;
            }

            const nodes = pathValue.map((x, i) => ({
                label: level === 1 ? `${pathValue[i].projectName}` : `${pathValue[i].taskWorkName}`,
                value: `${pathValue[i].id}`,
                isLeaf: level >= 2,
            }));

            return nodes;
        } catch (error) {
            console.log("请求失败:", error);
            return [];
        }
    };
    const handleTaskWorkOptionChange = (value, selectedOptions) => {
        if (typeof selectedOptions === 'undefined' || typeof value === 'null') {
            setSelectedTaskWorkOption(null)
            return
        }
        const labels = selectedOptions.map(option => option.label);
        const encodedTaskClassify = encodeURIComponent(labels[1] + '#' + labels[2])
        setTaskClassify(encodedTaskClassify)
    }
    const handleDateRangeChange = (dates) => {

        if (typeof (dates) == "undefined") {
            setDateRang(null)
        } else {
            const StartDate = new Date(dates[0]);
            dates[0] = StartDate.getTime();
            const endDate = new Date(dates[1]);
            endDate.setHours(23, 59, 59, 999); // 设置小时、分钟、秒和毫秒
            dates[1] = endDate.getTime();
            setDateRang(dates)
        }
    }
    const [tableFiveParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10
        }
    })
    const pageValuePage5 = tableFiveParams.pagination.current

    const taskState5 = taskStateValue
    const approveResult5 = approveResultValue
    const taskCheckTimes5 = taskCheckTimesValue
    const taskBeginTime = dateRange ? dateRange[0] : '';
    const taskEndTime = dateRange ? dateRange[1] : '';

    const fetchData5 = () => {
        fetchMyChecked(token, 10, pageValuePage5, taskBeginTime, taskCheckTimes5, taskEndTime, taskState5, approveResult5, taskClassify,taskName)
            .then(data => {
                if (data && data['data'] && data['data'].list) {
                    setPageFiveData(data['data'].list)
                    setTableParams(tableParams => ({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: data['data'].totalCount
                        }
                    }))
                } else {
                    setPageFiveData([])
                    setTableParams(tableParams => ({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: 0
                        }
                    }))
                }
            })
            .catch(error => {
                console.log("请求失败:", error)
            })
    }
    useEffect(() => {
        fetchData5()
    }, [taskState5, approveResult5, taskCheckTimes5,
        taskBeginTime, taskEndTime, pageValuePage5, taskClassify,taskName, change])

    const handleTableFiveChange = (pagination) => {
        if (pagination.pageSize !== tableFiveParams.pagination?.pageSize) {
            setTableParams({
                pagination: {
                    current: 1,
                    pageSize: pagination.pageSize,
                    total: tableFiveParams.pagination.total
                }
            })
        } else {
            setTableParams({
                pagination
            })
        }
    }

    const reset = () => {
        setDateRang(['', ''])
        setTaskStateValue('2')
        setApproveResultValue('')
        setTaskCheckTimesValue('')
        setTaskClassify('')
        setTaskName('')
    }



    const columns5 = [
        {
            title: '读取状态',
            width: 100,
            dataIndex: 'isTaskOpenIdView',
            key: 'name',
            fixed: 'left',
            render: (value) => (Number(value) === 0 ? <img src={unread} className='unread' alt="" style={{ width: 25 }} /> : <img src={read} className='read' alt="" style={{ width: 25 }} />)
        },
        {
            title: '更新时间',
            width: 110,
            dataIndex: 'taskApproveTime',
            key: '0',
            sorter: (a, b) => a.taskApproveTime - b.taskApproveTime,
            render: (value) => {
                if (value === null) {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '任务分类',
            width: 100,
            dataIndex: 'taskKey',
            key: '01',
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'
                }
                const parts = value.split('#');
                if (parts.length >= 2) {
                    return `${parts[0]}#${parts[1]}`;
                } else {
                    return value;
                }
            }
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: '1',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return value
                }
            }
        },
        {
            title: '价值类型',
            dataIndex: 'taskValueType',
            key: '2',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'
                } else {
                    return dataChangeMap.taskValueType[value]
                }
            }
        },
        {
            title: '开展方式',
            dataIndex: 'taskWorkType',
            key: '3',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'
                } else {
                    return dataChangeMap.taskWorkType[value]
                }
            }
        },
        {
            title: '预计耗时',
            dataIndex: 'taskExpectedTime',
            key: '4',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return `${value}小时`;
                }
            }
        },
        {
            title: '截止时间',
            dataIndex: 'taskDeliverLasttime',
            key: '5',
            width: 110,
            sorter: (a, b) => a.taskDeliverLasttime - b.taskDeliverLasttime,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '执行人',
            dataIndex: 'taskExecuter',
            key: '6',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '任务状态',
            dataIndex: 'taskState',
            key: '7',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—';
                } else {
                    return dataChangeMap.taskState[value];
                }
            }
        },
        {
            title: '认定工时',
            dataIndex: 'taskRealTime',
            key: '8',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—';
                } else {
                    return `${value}小时`;
                }
            },
        },
        {
            title: '验收结果',
            dataIndex: 'taskApproveResult',
            key: '9',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return dataChangeMap.approveResult[value]
                }
            }
        },
        {
            title: '验收轮次',
            dataIndex: 'taskCheckTimes',
            key: '10',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return `${Number(value)}次`
                }
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (col, record, index) => <TaskDetailModal taskKey={record.taskKey} 
                    token={token} userID={userID} showCopy={true} notifyRefresh={notifyRefresh} 
                    copyRecord={record}/>
        }
    ]
    return (
        <div className='page5'>
            <div className='page5-header'>
                <div className="applyTime">
                    <span className='applyTimeTip'>截止时间</span>
                    <DatePicker.RangePicker
                        status='warning'
                        placeholder='warning status'
                        style={{ width: 400, backgroundColor: 'rgba(242,243,245,1)' }}
                        value={dateRange}
                        showTime={{
                            defaultValue: ['00:00:00', '23:59:59'],
                            format: 'HH:mm:ss',
                        }}
                        format='YYYY/MM/DD HH:mm:ss'
                        onChange={handleDateRangeChange}
                    />
                </div>
                <div className="taskName">
                    <span className='name'>任务名称</span>
                    <Input
                        placeholder='请输入'
                        style={{ width: 200 }}
                        value={taskName}
                        onChange={(value) => handleTaskNameChange(value, 'taskName')}
                    />
                </div>
                <div className="taskState">
                    <span className='state'>任务状态</span>
                    <Select
                        value={taskState5}
                        defaultValue="1"
                        allowClear
                        style={{
                            width: 120,
                        }}
                        onChange={(value) => handleChange(value, 'taskStateValue')}
                        options={[
                            {
                                value: '0',
                                label: '待创建'
                            },
                            {
                                value: '1',
                                label: '待交付',
                            },
                            {
                                value: '2',
                                label: '待验收',
                            },
                            {
                                value: '3',
                                label: '已完成',
                            },
                            {
                                value: '4',
                                label: '未完成',
                            },
                            {
                                value: '5',
                                label: '已取消',
                            }
                        ]}
                    />
                </div>
                <div className="taskClassify">
                    <span className='classify'>任务分类</span>
                    <Cascader
                        style={{
                            width: 200,
                        }}
                        showSearch
                        placeholder='请输入'
                        allowClear
                        onChange={handleTaskWorkOptionChange}
                        options={options}
                        loadMore={loadMore}
                    />
                </div>
                <div className="acceptResult">
                    <span className="acceptResultTip">验收结果</span>
                    <Select
                        value={approveResult5}
                        defaultValue="0"
                        allowClear
                        style={{
                            width: 120,
                        }}
                        onChange={(value) => handleChange(value, 'approveResultValue')}
                        options={[
                            {
                                value: '0',
                                label: '暂无',
                            },
                            {
                                value: '1',
                                label: '不达标',
                            },
                            {
                                value: '2',
                                label: '达标',
                            },
                            {
                                value: '3',
                                label: '需返工',
                            },
                            {
                                value: '4',
                                label: '差',
                            }
                        ]}
                    />
                </div>
                <div className="acceptAround">
                    <span className='acceptAroundTip'>验收轮次</span>
                    <Select
                        value={taskCheckTimes5}
                        defaultValue="0"
                        style={{
                            width: 120,
                        }}
                        allowClear
                        onChange={(value) => handleChange(value, 'taskCheckTimesValue')}
                        options={[
                            {
                                value: '1',
                                label: '1次',
                            },
                            {
                                value: '2',
                                label: '2次',
                            },
                            {
                                value: '3',
                                label: '3次',
                            },
                            {
                                value: '4',
                                label: '>3次',
                            }
                        ]}
                    />
                </div>
                <div className="reset">
                    <Button className='btn' onClick={reset}>
                        <span>重置</span>
                    </Button>
                </div>
            </div>
            <div className="page5-wrap">
                <Table
                    borderCell
                    data={pageFiveData}
                    columns={columns5} // 添加你的表格列配置
                    pagination={tableFiveParams.pagination}
                    scroll={{
                        x: 1500,
                    }}
                    onChange={handleTableFiveChange}
                />
            </div>
        </div>
    )
}

export default MyCheck
/* eslint-enable */

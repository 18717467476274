import {httpGetCall, httpPostCall} from "../io/HttpRequestUtil";


//获取用户信息
export function getUserInfo(onSuccess, onFail) {
    httpGetCall("user/getUserInfo", null, onSuccess, onFail);
}

export function getUser() {
    let user = localStorage.getItem("userInfo")
    if (null == user) {
        return null
    }
    return JSON.parse(localStorage.getItem("userInfo"))
}

// 获取消息列表
export function getTaskMsg(onSuccess, onFail) {
    httpGetCall("msg/getMsg", null, onSuccess, onFail);
}

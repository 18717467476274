/* eslint-disable */
import React, {useState, useEffect} from "react";
import moment from "moment";
import ApplyModule from "./apply_info";
import TaskStateModule from "./task_state";
import TaskInfoModule from "./task_info";
import DeliveryModule from "./delivery_info";
import {taskWordToNum, dataChangeMap} from '../../config/dataChange'
import CopyModal from '../copyModal/index.jsx'
import Draggable from 'react-draggable';
import {
    Modal, Collapse, Button, Trigger, Form, Spin
} from '@arco-design/web-react'
import {
    checkTask, getApproveInfo
} from "../../api/getExport"
import './index.scss'


const TaskDetailModal = ({
                             token, userID, notifyRefresh,taskKey=null,
                             showCopy = true, isCard = false, copyRecord = null
                         }) => {
    const [visible, setVisible] = useState(false)
    const [copyVisible, setCopyVisible] = useState(false)
    const [record, setRecord] = useState({"taskKey": null})
    const [disabled, setDisabled] = useState(true)
    const [collapseAcKey, setCollapseAcKey] = useState(['1', '2', '3'])
    //任务流转板块-变量
    const [taskFlowInfo, setTaskFLowInfo] = useState({
        "cancelReason": "",
        "lastTaskState": taskWordToNum.taskState["submitting"],
        "createDes": "进行中",
        "executeDes": "未开始",
        "checkDes": "未开始",
        "completeDes": "未开始",
        "notcompleteDes": "",
        "cancelDes": "",
    })
    const [taskStateTimeline, setTaskStateTimeline] = useState([])
    //申请信息板块-变量
    //const [taskKey,setTaskKey] = useState(record.taskKey)
    const [taskApplyData, setTaskApplyData] = useState({"taskKey":null, "hidden": true})
    const [applyDeadline, setApplyDeadline] = useState('')
    const [applyResource, setApplyResource] = useState('')
    const [applyExpectedTime, setApplyExpectedTime] = useState('')
    //任务信息板块-变量
    const [taskReviseLog, setTaskReviseLog] = useState([])
    const [taskInfoJson, setTaskInfoJson] = useState({"isRestart":false})
    const [form_info] = Form.useForm();

    //交付信息板块
    const [deliveryInfo, setDeliveryInfo] = useState([])
    const [deliveryVisible, setDelieveryVisible] = useState(false)
    //状态控制
    useEffect(() => {
        if (localStorage.getItem("popProjectKey")!=null && isCard) {
            console.log("popKey", localStorage.getItem("popProjectKey"))
            handleViewClick(); // 等待异步操作完成
            console.log("record", visible, record)
            localStorage.removeItem('popProjectKey');
        }
    }, [localStorage.getItem("popProjectKey")]); // 添加依赖项以确保正确的触发时机

    const handleViewClick = () => {
        setVisible(true)
        initData()
    }

    const handleViewCopyClick = () => {
        setCopyVisible(true)
        setVisible(false)
    }

    const closeModal = () => {
        setCopyVisible(false)
        notifyRefresh()
    }

    //更新task数据
    const initData = () => {
        checkTask(token, taskKey)
            .then(data => {
                setRecord(data.data)
                setDelieveryVisible(((data.data.taskState == taskWordToNum.taskState["checking"]) ||
                        (data.data.taskState == taskWordToNum.taskState["done"]) ||
                        (data.data.taskState == taskWordToNum.taskState["fail"])) ||
                    ((data.data.taskState == taskWordToNum.taskState["submitting"]) &&
                        (data.data.taskExecuterId == userID)))
                console.log("record", record);
                getTaskApplyData(data.data)

            })
            .catch(error => {
                console.log("请求失败:", error);
            });
    }

    //申请内容
    const getTaskApplyData = (cur_record) => {
        taskInfoJson["isRestart"] = false
        taskStateTimeline.length = 0
        const taskReviseLogFunc = (item) => {
            let beginValue = item.beginValue
            let endValue = item.endValue
            let modify_time = moment(parseInt(item.modifyTime)).format('YYYY-MM-DD HH:mm:ss')
            if (item.taskModifyName == "截止时间") {
                beginValue = moment(parseInt(beginValue)).format('YYYY-MM-DD HH:mm:ss')
                endValue = moment(parseInt(endValue)).format('YYYY-MM-DD HH:mm:ss')
            } else if (item.taskModifyName == "时间要求") {
                let begin_time = beginValue.split('#')
                let end_time = endValue.split('#')
                let begin_date = (begin_time.length > 2) ?
                    `: [${moment(parseInt(begin_time[1])).format('YYYY-MM-DD HH:mm:ss')}` + '-' +
                    `${moment(parseInt(begin_time[2])).format('YYYY-MM-DD HH:mm:ss')}]` : ''
                let end_date = (end_time.length > 2) ?
                    `: [${moment(parseInt(end_time[1])).format('YYYY-MM-DD HH:mm:ss')}` + '-' +
                    `${moment(parseInt(end_time[2])).format('YYYY-MM-DD HH:mm:ss')}]` : ''
                beginValue = dataChangeMap.taskTimeRequireType[begin_time[0]] + begin_date
                endValue = dataChangeMap.taskTimeRequireType[end_time[0]] + end_date
            } else if (item.taskModifyName == "地点要求") {
                let begin_place_req = beginValue.split('#')
                let end_place_req = endValue.split('#')
                let begin_place = (begin_place_req.length > 1) ? ": " + begin_place_req[1] : ""
                let end_place = (end_place_req.length > 1) ? ": " + end_place_req[1] : ""
                beginValue = dataChangeMap.taskPlaceRequireType[begin_place_req[0]] + begin_place
                endValue = dataChangeMap.taskPlaceRequireType[end_place_req[0]] + end_place
            } else if (item.taskModifyName == "任务价值分类") {
                beginValue = dataChangeMap.taskValueType[beginValue]
                endValue = dataChangeMap.taskValueType[endValue]
            } else if (item.taskModifyName == "任务开展方式") {
                beginValue = dataChangeMap.taskWorkType[beginValue]
                endValue = dataChangeMap.taskWorkType[endValue]
            }
            taskReviseLog.push([modify_time + ' ' + cur_record.taskApproverName + ' 修改【' + item.taskModifyName + '】\"' + beginValue + '\"-->\"' + endValue + '\"' + '\n'])
        }
        getApproveInfo(token, taskKey)
            .then(data => {
                let task_flow = Object.values(data['data'].taskAppFlowMap)
                let task_approve = data['data'].taskApprove
                const flow_len = task_flow.length
                task_flow.sort((a, b) => a[0].modifyTime - b[0].modifyTime)
                console.log("task_flow", task_flow)
                taskApplyData["taskApplyType"] = dataChangeMap.taskApproveType[task_approve.taskApproveType]

                //交付信息板块 && 任务流转图
                deliveryInfo.length = 0
                taskReviseLog.length = 0
                for (let i = 0; i < task_flow.length; i++) {
                    let items = task_flow[i]
                    let item = items[0]
                    let modify_time = moment(parseInt(item.modifyTime)).format('YYYY-MM-DD HH:mm:ss')
                    //交付信息板块
                    if (item.taskModifyName == '任务验收' &&
                        item.taskApproveResult == taskWordToNum.taskApproveResult["partly_adjust"]) {
                        deliveryInfo.push([modify_time + '    【' + cur_record.taskChecker + '】驳回返工，反馈意见：\"' + item.taskApproveAdvice + '\"。' + '\n'])
                    }

                    //任务流转图
                    if (item.taskModifyName == "任务创建") {
                        if ((item.taskApproveResult == taskWordToNum.taskApproveResult["pass"] ||
                            item.taskApproveResult == taskWordToNum.taskApproveResult["partly_adjust"])) {
                            taskFlowInfo["createDes"] = modify_time
                            taskFlowInfo["executeDes"] = "进行中"
                            taskFlowInfo["lastTaskState"] = taskWordToNum.taskState["submitting"]
                            if (i > 1) {
                                if (task_approve.taskApproveType == taskWordToNum.taskApproveType["task_create"]) {
                                    taskApplyData["taskApplyType"] = "任务重启"
                                }
                                taskStateTimeline.push([modify_time, cur_record.taskApproverName + ' 审批通过任务重启', ''])
                            } else {
                                taskStateTimeline.push([modify_time, cur_record.taskApproverName + ' 审批通过创建任务', ''])
                            }
                            if (item.taskApproveResult == taskWordToNum.taskApproveResult["partly_adjust"]) {
                                for (let i = 1; i < items.length; i++) {
                                    taskReviseLogFunc(items[i])
                                }
                            }
                        } else if (item.taskApproveResult == taskWordToNum.taskApproveResult["rejected"]) {
                            taskFlowInfo["createDes"] = "终止"
                            taskFlowInfo["cancelDes"] = modify_time
                            taskFlowInfo["lastTaskState"] = taskWordToNum.taskState["creating"]
                            if (i > 3) {
                                if (task_approve.taskApproveType == taskWordToNum.taskApproveType["task_create"]) {
                                    taskApplyData["taskApplyType"] = "任务重启"
                                }
                                taskStateTimeline.push([modify_time, cur_record.taskApproverName + ' 驳回重启任务', '原因：' + item.taskApproveAdvice])
                            } else {
                                taskStateTimeline.push([modify_time, cur_record.taskApproverName + ' 驳回创建任务', '原因：' + item.taskApproveAdvice])
                            }
                            taskFlowInfo["cancelReason"] = item.taskApproveAdvice
                        } else {
                            if (i > 0) {
                                if (task_approve.taskApproveType == taskWordToNum.taskApproveType["task_create"]) {
                                    taskApplyData["taskApplyType"] = "任务重启"
                                }
                                taskStateTimeline.push([modify_time, item["beginValue"] + ' 发起任务重启', '原因：' + item.taskApproveAdvice])
                            } else {
                                taskStateTimeline.push([modify_time, item["beginValue"] + ' 发起创建任务', ''])
                            }
                        }
                    } else if (item.taskModifyName == "任务验收") {
                        if (item.taskApproveResult == taskWordToNum.taskApproveResult["applying"]) {
                            taskFlowInfo["executeDes"] = modify_time
                            taskFlowInfo["checkDes"] = "进行中"
                            taskStateTimeline.push([modify_time, cur_record.taskExecuter + ' 交付任务', ''])
                        } else if (item.taskApproveResult == taskWordToNum.taskApproveResult["pass"]) {
                            taskFlowInfo["checkDes"] = modify_time
                            taskFlowInfo["completeDes"] = modify_time
                            taskStateTimeline.push([modify_time, cur_record.taskChecker + ' 验收反馈【' +
                                dataChangeMap.taskResult[cur_record.taskCheckResult] + '】', ''])
                        } else if (item.taskApproveResult == taskWordToNum.taskApproveResult["partly_adjust"]) {
                            taskFlowInfo["checkDes"] = "未开始"
                            taskFlowInfo["executeDes"] = "进行中"
                            taskStateTimeline.push([modify_time, cur_record.taskChecker + ' 验收反馈【需调整】', ''])
                        } else if (item.taskApproveResult == taskWordToNum.taskApproveResult["rejected"]) {
                            taskFlowInfo["checkDes"] = modify_time
                            taskFlowInfo["notcompleteDes"] = modify_time
                            taskStateTimeline.push([modify_time, cur_record.taskChecker + ' 验收反馈【' +
                                dataChangeMap.taskResult[cur_record.taskCheckResult] + '】', ''])
                        }
                    } else if (item.taskModifyName == '任务取消') {
                        taskStateTimeline.push([modify_time, cur_record.taskApproverName + ' 取消任务', '原因：' + item.taskApproveAdvice])
                        taskFlowInfo["cancelReason"] = item.taskApproveAdvice
                        taskFlowInfo["cancelDes"] = modify_time
                    } else if (item.taskModifyName == '任务转换' &&
                        item.taskApproveResult == taskWordToNum.taskApproveResult["pass"]) {
                        if(i==0){
                            taskStateTimeline.push([modify_time, cur_record.taskCreator + ' 申请转派创建', ''])
                        }else{
                            taskStateTimeline.push([modify_time, cur_record.taskApproverName + ' 取消任务', '原因：任务转派'])
                        }
                        taskFlowInfo["cancelReason"] = '任务转派'
                        taskFlowInfo["cancelDes"] = modify_time
                    }//任务修改信息
                    else if (item.taskModifyName != '任务验收' && item.taskModifyName != "任务创建" &&
                        item.taskModifyName != "任务取消" &&
                        (item.taskApproveResult == taskWordToNum.taskApproveResult["pass"] ||
                            item.taskApproveResult == taskWordToNum.taskApproveResult["partly_adjust"])) {
                        for (let i = 0; i < items.length; i++) {
                            taskReviseLogFunc(items[i])
                        }
                    } else if (item.taskModifyActionType == "审批人编辑") {
                        for (let i = 0; i < items.length; i++) {
                            taskReviseLogFunc(items[i])
                        }
                    }
                }
                setTaskFLowInfo(taskFlowInfo)
                taskReviseLog.reverse()
                setTaskReviseLog(taskReviseLog)
                setTaskStateTimeline(taskStateTimeline)
                // 交付信息
                deliveryInfo.reverse()
                setDeliveryInfo(deliveryInfo)

                //申请信息板块
                taskApplyData["hidden"] =
                    (task_approve.id == null || (task_approve.taskApproveType == taskWordToNum.taskApproveType["task_create"] &&
                            (task_approve.taskApproveResult == taskWordToNum.taskApproveResult["pass"])) ||
                        ((task_approve.taskApproveType == taskWordToNum.taskApproveType["task_check"]) &&
                           ((task_approve.taskApproveResult != taskWordToNum.taskApproveResult["applying"])||
                           (task_approve.taskSubmitId == task_approve.taskApproveId))))

                taskApplyData["taskApproveId"] = task_approve.taskApproveId
                taskApplyData["taskApproveName"] = task_approve.taskApproveName
                taskApplyData["taskApproveType"] = task_approve.taskApproveType
                taskApplyData["taskAttach"] = task_approve.taskApplyAttach
                taskApplyData["taskTransferId"] = task_approve.taskTransferId
                let task_apply_time = ""
                let task_dispose_time = ""
                let revise_item_list = new Set(["任务转换", "截止时间", "所需资源", "预计耗时"])
                for (let item_name of revise_item_list) {
                    taskApplyData[item_name] = {}
                    taskApplyData[item_name]["visible"] = false
                    for (let item_value of ["beginValue", "applyValue", "endValue"]) {
                        taskApplyData[item_name][item_value] = ''
                    }
                    taskApplyData[item_name]["editable"] = false
                }
                if (flow_len > 0) {
                    //applying
                    if (task_approve.taskApproveResult == taskWordToNum.taskApproveResult["applying"]) {
                        let applying_task = task_flow[flow_len - 1][0]
                        task_apply_time = moment(applying_task.modifyTime).format('YYYY-MM-DD HH:mm:ss')
                    } else {//pass/rejected/adjust
                        let applying_task = task_flow[flow_len - 2][0]
                        let result_task = task_flow[flow_len - 1][0]
                        task_dispose_time = moment(result_task.modifyTime).format('YYYY-MM-DD HH:mm:ss')
                        task_apply_time = moment(applying_task.modifyTime).format('YYYY-MM-DD HH:mm:ss')
                    }
                    //申请信息
                    let apply_idx = flow_len - 1
                    let latest_task = task_flow[apply_idx]
                    if (latest_task[0]["taskModifyName"] == '任务创建'){
                        if (latest_task[0]["taskApproveResult"]==taskWordToNum.taskApproveResult["rejected"]){
                            setDelieveryVisible(false)
                        }
                        if (apply_idx > 0){
                            taskInfoJson["isRestart"] = true
                        }
                    }
                    setTaskInfoJson(taskInfoJson)
                    while (apply_idx >= 0 && (latest_task[0].taskModifyActionType == "审批人编辑" ||
                            latest_task[0].taskModifyName == "任务取消")) {
                        apply_idx -= 1
                        latest_task = task_flow[apply_idx]
                    }
                    let apply_task = []
                    // if (task_approve.taskApproveResult == taskWordToNum.taskApproveResult["rejected"] ||
                    //     task_approve.taskApproveResult == taskWordToNum.taskApproveResult["pass"]) {
                    //     apply_task = task_flow[apply_idx - 1]
                    // }
                    for (let task_apply of [apply_task, latest_task]) {
                        for (let v of task_apply) {
                            if (revise_item_list.has(v.taskModifyName)) {
                                taskApplyData[v.taskModifyName]["visible"] = true
                                taskApplyData[v.taskModifyName]["beginValue"] = v.beginValue
                                taskApplyData[v.taskModifyName]["applyValue"] = v.applyValue
                                taskApplyData[v.taskModifyName]["endValue"] = v.endValue
                                taskApplyData[v.taskModifyName]["editable"] = 
                                    ((v.taskApproveResult == taskWordToNum.taskApproveResult["applying"])&&
                                        (cur_record.taskState == taskWordToNum.taskState["submitting"]))
                                if ((task_approve.taskApproveResult == taskWordToNum.taskApproveResult["applying"]) &&
                                    (v.taskApproveResult != taskWordToNum.taskApproveResult["applying"])){
                                        taskApplyData["additionInfo"] = "【通过异常，仅部分修改通过，请处理人尽快操作。】"
                                    }
                                switch (v.taskModifyName) {
                                    case "任务转换":
                                        taskApplyData["taskTransferId"] = task_approve.taskTransferId
                                        break
                                    case "截止时间":
                                        taskApplyData[v.taskModifyName]["beginValue"] = parseInt(v.beginValue)
                                        taskApplyData[v.taskModifyName]["applyValue"] = parseInt(v.applyValue)
                                        taskApplyData[v.taskModifyName]["endValue"] = parseInt(v.endValue)
                                        setApplyDeadline(parseInt(v.applyValue))
                                        break
                                    case "所需资源":
                                        setApplyResource(v.applyValue)
                                        break
                                    case "预计耗时":
                                        setApplyExpectedTime(v.applyValue)
                                        break
                                    default:
                                        break
                                }
                            }
                        }
                    }
                }

                taskApplyData["taskApplyTime"] = task_apply_time
                taskApplyData["taskDisposeTime"] = task_dispose_time

                taskApplyData["isApprover"] = (userID == task_approve.taskApproveId)
                taskApplyData["isApplyer"] = (userID == task_approve.taskSubmitId)
                taskApplyData["isApplying"] = (task_approve.taskApproveResult ==
                    taskWordToNum.taskApproveResult["applying"])
                taskApplyData["isRejected"] = (task_approve.taskApproveResult ==
                    taskWordToNum.taskApproveResult["rejected"])
                taskApplyData["isPass"] = (task_approve.taskApproveResult ==
                    taskWordToNum.taskApproveResult["pass"])
                taskApplyData["isAdjust"] = (task_approve.taskApproveResult ==
                    taskWordToNum.taskApproveResult["partly_adjust"])

                taskApplyData["taskApplyerName"] = task_approve.taskSubmitName
                taskApplyData["taskApproveName"] = task_approve.taskApproveName
                taskApplyData["taskFeedback"] = task_approve.taskApproveAdvice

                taskApplyData["isReviseTask"] = (task_approve.taskApproveType ==
                    taskWordToNum.taskApproveType["task_revise"])
                
                taskApplyData["taskKey"] = cur_record.taskKey
                setTaskApplyData(taskApplyData => ({
                    ...taskApplyData
                }))
                // console.log("taskApplyData", taskApplyData)
            })
            .catch(error => {
                console.log("approveInfo 请求失败:", error);
            })
    }
    const detailContent = () => {
        return (visible && taskKey!=null && record.taskKey != null) ? <>
            <TaskStateModule record={record} taskFlowInfo={taskFlowInfo}
                             taskStateTimeline={taskStateTimeline}/>
            <div className="detail-body">
                <Collapse
                    defaultActiveKey={collapseAcKey}
                    style={{maxWidth: 1180}}
                >
                    {!taskApplyData["hidden"] &&
                        <ApplyModule token={token} record={record} initData={initData} taskApplyData={taskApplyData}
                                     deadline={applyDeadline} setDeadline={setApplyDeadline}
                                     resource={applyResource} setResource={setApplyResource}
                                     expectedTime={applyExpectedTime} setExpectedTime={setApplyExpectedTime}
                                     form_info={form_info}/>}

                    <TaskInfoModule token={token} userID={userID} record={record} taskInfoJson={taskInfoJson}
                                    initData={initData} taskReviseLog={taskReviseLog} form_info={form_info}/>
                    {deliveryVisible &&
                        <DeliveryModule token={token} record={record}
                                        deliveryInfo={deliveryInfo} userID={userID} initData={initData}/>}
                </Collapse>
            </div>
        </> : <Spin size={40}/>
    }

    return (
        <Trigger
            trigger='click'
            popupVisible={visible}
            clickToClose={false}
            clickOutsideToClose={false}
            popup={() =>
                <Modal
                    id='task_detail_modal' className='executeViewModal'
                    title='任务详情' visible={visible}
                    onCancel={() => {
                        setVisible(false);
                        notifyRefresh();
                    }}
                    maskClosable={false}
                    autoFocus={false}
                    focusLock={true}
                    onMouseOver={() => {
                        disabled && setDisabled(false);
                    }}
                    onMouseOut={() => {
                        !disabled && setDisabled(true);
                    }}
                    modalRender={(modal) => <Draggable
                        droppableId="task_detail_modal" disabled={disabled}>{modal}</Draggable>}
                >
                    {detailContent()}
                </Modal>
            }>
            <div className="operate" style={{display: (taskKey!=null && !isCard)? "" : "none"}}>
                <Button type='primary' className='detailOperate' onClick={handleViewClick}>查看</Button>
                <Button type='primary' className='detailOperate'
                        onClick={handleViewCopyClick} style={{display: showCopy ? "" : "none"}}>复制</Button>
            </div>
            {copyVisible && <CopyModal token={token} visible={copyVisible} onClose={closeModal} record={copyRecord}/>}
        </Trigger>
    )
}

export default TaskDetailModal

import React from 'react';

const Liuliang = () => {
  return (
    <div>
      <h2>暂未开放，敬请期待</h2>
    </div>
  );
};

export default Liuliang;
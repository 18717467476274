import axios from 'axios';
import {TASKSERVERURL_PREFIX} from './constants';
import {Notification} from '@arco-design/web-react';

export const httpGetCall = async (api, data, onSuccess, onFail) => {
    try {
        const response = await axios({
            url: TASKSERVERURL_PREFIX + api + "?" + createGetUrl(data),
            method: "GET",
            headers: {
                'user_access_token': localStorage.getItem('token')
            }
        });
        resolveResponse(response, api, onSuccess, onFail);
    } catch (e) {
        console.log("error" + e.message)
        Notification.error({
            title: api + "网络错误",
            content: e.message,
        })
        if (typeof onFail === 'function') {
            onFail(e.message);
        }
    }
}

export const httpPostCall = async (api, data, onSuccess, onFail) => {
    try {
        const response = await axios({
            url: TASKSERVERURL_PREFIX + api,
            method: "POST",
            headers: {
                'user_access_token': localStorage.getItem('token')
            },
            data: data
        });
        resolveResponse(response, api, onSuccess, onFail);
    } catch (e) {
        console.log("error" + e.message)
        Notification.error({
            title: api + "网络错误",
            content: e.message,
        })
        if (typeof onFail === 'function') {
            onFail(e.message);
        }
    }
}

function createGetUrl(data) {
    let url = '';
    for (let key in data) {
        url += key + '=' + data[key] + '&';
    }
    return url;
}

function resolveResponse(response, api, onSuccess, onFail) {
    const responseData = response.data;
    console.log(responseData.data)
    if (responseData.retCode == '000000') {
        console.log("success" + responseData.data)
        if(typeof onSuccess === 'function'){
            onSuccess(responseData.data);
        }
    } else {
        console.log("error" + responseData.retMsg)
        let errorMsg = responseData.retMsg;
        console.log(responseData.retCode)
        if (responseData.retCode == 'AIT502') {
            //token过期
            localStorage.removeItem('token');
            errorMsg = 'token过期'
        }
        Notification.error({
            title: api + "请求失败",
            content: errorMsg,
        })
        console.log("onfail come in")
        if (typeof onFail === 'function') {
            onFail(responseData.retMsg);
        }
    }
}

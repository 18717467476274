/* eslint-disable */
import React, {useEffect, useState} from 'react'
import './index.scss'
import MyExecuter from './myExecuter'
import MyApprove from './myApprove'
import MyCreate from './myCreate'
import MyModify from './myModify'
import MyCheck from './myCheck'
import CreateModal from '../../../createModal'
import {getUser, getUserInfo} from "../../../../api/TaskApi";
import MyDrafts from "./myDrafts";
import {Icon, Message} from "@arco-design/web-react";
import TaskDetailModal from '../../../task_detail_model'

const IconFont = Icon.addFromIconFontCn({
    src: '//at.alicdn.com/t/font_180975_26f1p759rvn.js',
});

let userInfo = getUser()

const TaskList = ({data, notifyRefresh}) => {
    const [visible, setVisible] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userID, setUserID] = useState(null)

    const pages = [
        {id: 'myExecuter', text: '我执行的'},
        {id: 'myCheck', text: '我验收的'},
        {id: 'myApprove', text: '我审批的'},
        {id: 'myCreate', text: '我创建的'},
        {id: 'myModify', text: '申请中的'},
        {id: 'MyDrafts', text: '草稿箱'},
    ];
    const [copy, setCopy] = useState({})
    const [currentPage, setCurrentPage] = useState(pages[0].id);
    const [popKey, setPopKey] = useState(null)

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const onNotify = (task) => {
        console.log('onNotify', task)
        setCopy(task)
        Message.success({
            icon: <IconFont type='icon-success'/>,
            content: `任务复制到剪贴板!`,
        });
    }

    const getPopUrl =() =>{
        let url = window.location.search
        console.log(url,'url')
        let searchParams = new URLSearchParams(url);
        console.log(searchParams,'searchParams')
        let taskFlowWork = searchParams.get('taskFlowWork');
        let taskNodeWork = searchParams.get('taskNodeWork');
        let taskName = searchParams.get('taskName');
        let taskExecuterId = searchParams.get('taskExecuterId');
        let taskPage = searchParams.get('taskPage');
        console.log(taskFlowWork); // Outputs: B组工作
        console.log(taskNodeWork); // Outputs: 后端组
        console.log(taskName); // Outputs: poptest#01#A
        console.log(taskExecuterId); // Outputs: on_6f1fffbac74783628e7ba4cbf1a29c4e
        console.log(taskPage); // Outputs: 1


        if (taskFlowWork && taskNodeWork && taskName && taskExecuterId && taskPage) {
            let formattedString = `${taskFlowWork}#${taskNodeWork}#${taskName}#${taskExecuterId}`;
            console.log(formattedString)
            localStorage.setItem("popProjectKey", formattedString);
            handlePageChange(pages[taskPage].id);
            setPopKey(formattedString)

        }
        else if(taskPage){
            handlePageChange(pages[taskPage].id);
        }
        else {
            console.log("No taskKey found in URL");
        }
    }


    useEffect(() => {
        console.log("---------------taskList-----------")
        let user = getUser()
        if (null == user) {
            if (null == localStorage.getItem("token")) {
                return
            }
            getUserInfo(function (userInfo) {
                console.log("taskList", userInfo)
                localStorage.setItem("userInfo", JSON.stringify(userInfo))
                user = getUser()
                console.log("taskList_getUser", user)
            })
        } else {
            setUserID(user.union_Id)
        }
        getPopUrl()



    }, []);

    return (
        <div className='taskList'>
            <div className='taskList-header'>
                {pages.map((page) => (
                    <div
                        key={page.id}
                        onClick={() => handlePageChange(page.id)}
                        className={`tab ${currentPage === page.id ? 'active' : ''}`}
                    >
                        {page.text}
                    </div>
                ))}
            </div>
            <div className="taskList-wrap">
                {currentPage === 'myExecuter' &&
                    <MyExecuter token={token} userID={userID} change={data} notifyRefresh={notifyRefresh}/>}
                {currentPage === 'myCheck' &&
                    <MyCheck token={token} userID={userID} change={data} notifyRefresh={notifyRefresh}/>}
                {currentPage === 'myApprove' &&
                    <MyApprove token={token} userID={userID} change={data} notifyRefresh={notifyRefresh}/>}
                {currentPage === 'myCreate' &&
                    <MyCreate token={token} userID={userID} change={data} notifyRefresh={notifyRefresh}/>}
                {currentPage === 'myModify' &&
                    <MyModify token={token} userID={userID} change={data} notifyRefresh={notifyRefresh}/>}
                {currentPage === 'MyDrafts' &&
                    <MyDrafts token={token} userID={userID} change={data} onNotify={onNotify}/>}
            </div>
            <TaskDetailModal taskKey={popKey} token={token} userID={userID} showCopy={false}
                    notifyRefresh={notifyRefresh} isCard={popKey!=null?true:false}/>
        </div>
    );
}

export default TaskList
/* eslint-enable */

/* eslint-disable */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import {
    Table,
    Button,
    Input,
    DatePicker,
    Select,
    Cascader
} from '@arco-design/web-react'
import { fetchMyCreate,fetchSecondTask, fetchThirdTask,  } from '../../../../../api/getExport'
import { dataChangeMap } from '../../../../../config/dataChange'
import TaskDetailModal from '../../../../task_detail_model'
import './index.scss'
const optionsList = [
    {
        value: 0,
        label: '项目',
        isLeaf: false,
    },
    {
        value: 1,
        label: '事务',
        isLeaf: false,
    },
    {
        value: 2,
        label: '业务',
        isLeaf: false,
    },
    {
        value: 3,
        label: '事项',
        isLeaf: false,
    },
    {
        value: 4,
        label: '其他',
        isLeaf: false,
    }
]

const MyCreate = ({ token, userID, change,notifyRefresh}) => {
    const [pageThreeData, setPageThreeData] = useState('')
    const [taskTypePage3, setTaskTypePage3] = useState('')
    const [taskExecuterPage3, setTaskExecuterPage3] = useState('')
    const [taskNamePage3, setTaskNamePage3] = useState('')
    // const [taskApproverNamePage3, setTaskApproverNamePage3] = useState('')
    const [taskClassify, setTaskClassify] = useState('')
    const [options, setOptions] = useState(optionsList)
    const [projectKey, setProjectKey] = useState(null)
    const [dateRange, setDataRange] = useState(['', ''])
    const handleChange = (value, name) => {
        if (typeof (value) == "undefined") {
            if (name == 'taskCreatedTimePage3') {
                value = ['', '']
            } else {
                value = ''
            }
        }
        switch (name) {
            case 'taskTypePage3':
                setTaskTypePage3(value);
                break;
            case 'taskExecuterPage3':
                setTaskExecuterPage3(value);
                break;
            case 'taskNamePage3':
                setTaskNamePage3(value);
                break;
            case 'taskCreatedTimePage3':
                setDataRange([value[0], value[1]]);
                break;
            default:
                break;
        }
    }
    const loadMore = async (pathValue, level) => {
        try {
            if (level === 1) {
                const data = await fetchSecondTask(token, pathValue);
                pathValue = data.data
            } else if (level === 2) {
                const values = pathValue.toString();
                const value = values.split(',')[1];
                const data = await fetchThirdTask(token, value);
                const ProjectKey = data.data[2].taskWorkProject;
                setProjectKey(ProjectKey);
                pathValue = data.data;
            }

            const nodes = pathValue.map((x, i) => ({
                label: level === 1 ? `${pathValue[i].projectName}` : `${pathValue[i].taskWorkName}`,
                value: `${pathValue[i].id}`,
                isLeaf: level >= 2,
            }));

            return nodes;
        } catch (error) {
            console.log("请求失败:", error);
            return [];
        }
    };
    const handleTaskWorkOptionChange = (value, selectedOptions) => {
        if (typeof selectedOptions === 'undefined' || typeof value === 'null') {
            setSelectedTaskWorkOption(null)
            return
        }
        const labels = selectedOptions.map(option => option.label);
        const encodedTaskClassify = encodeURIComponent(labels[1] + '#' + labels[2])
        setTaskClassify(encodedTaskClassify)
    }

    const clearLocalStorage = () => {
        setTaskTypePage3('');
        setTaskExecuterPage3('');
        setTaskNamePage3('');
        setDataRange(['', ''])
        setTaskClassify('')
        setTaskName('')
    }

    const taskTypeValuePage3 = taskTypePage3
    const taskExecuterValuePage3 = taskExecuterPage3
    const taskNameValuePage3 = taskNamePage3
    // const taskApproverNameValuePage3 = taskApproverNamePage3
    const taskCreatedBeginTimeValuePage3 = ''
    const taskCreatedEndTimeValuePage3 = ''
    if ('' != dateRange[0]) {
        const taskCreatedBeginTimeValuePage3 = moment(dateRange[0]).format('x')
    }
    if ('' != dateRange[1]) {
        const taskCreatedEndTimeValuePage3 = moment(dateRange[1]).format('x')
    }


    const columns = [
        {
            title: '更新时间',
            width: 110,
            dataIndex: 'taskUpdateddate',
            key: '1',
            sorter: (a, b) => a.taskUpdateddate - b.taskUpdateddate,
            render: (value) => {
                if (value === null) {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '任务分类',
            width: 100,
            dataIndex: 'taskKey',
            key: '01',
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return ''
                }
                const parts = value.split('#');
                if (parts.length >= 2) {
                    return `${parts[0]}#${parts[1]}`;
                } else {
                    return value;
                }
            }
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: '2',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return value
                }
            }
        },
        {
            title: '价值类型',
            dataIndex: 'taskValueType',
            key: '3',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return dataChangeMap.taskValueType[value]
                }
            }
        },
        {
            title: '开展方式',
            dataIndex: 'taskWorkType',
            key: '4',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return dataChangeMap.taskWorkType[value]
                }
            }
        },
        {
            title: '时间要求',
            dataIndex: 'taskExecuteTimeStandard',
            key: '5',
            width: 165,
            render: (value) => {
                if (value === '0') {
                    return '不限';
                } else if (typeof value === 'string' && value.startsWith("1#")) {
                    const timeRange = value.split("#").slice(1); // 获取毫秒数时间范围
                    const startDate = new Date(parseInt(timeRange[0]));
                    const endDate = new Date(parseInt(timeRange[1]));
                    const startTime = startDate.toLocaleString(); // 格式化开始时间（包括时:分:秒）
                    const endTime = endDate.toLocaleString(); // 格式化结束时间（包括时:分:秒）
                    return (
                        <div>
                            {startTime}
                            <br />
                            至
                            <br />
                            {endTime}
                        </div>
                    );
                } else {
                    return '—'
                }
            },
        },
        {
            title: '地点要求',
            dataIndex: 'taskExecuteAddressStandard',
            key: '6',
            width: 100,
            render: (value) => {
                if (value === '0') {
                    return '不限';
                } else if (typeof value === 'string' && value.startsWith("1#")) {
                    const place = value.split("#").slice(1)
                    return `${place}`;
                } else {
                    return '—';
                }
            },
        },
        {
            title: '预计耗时',
            dataIndex: 'taskExpectedTime',
            key: '7',
            width: 120,
            sorter: (a, b) => a.taskExpectedTime - b.taskExpectedTime,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return `${value}小时`;
                }
            }
        },
        {
            title: '截止时间',
            dataIndex: 'taskDeliverLasttime',
            key: '8',
            width: 110,
            sorter: (a, b) => a.taskDeliverLasttime - b.taskDeliverLasttime,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '执行人',
            dataIndex: 'taskExecuter',
            key: '9',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '审批人',
            dataIndex: 'taskApproverName',
            key: '10',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '任务状态',
            dataIndex: 'taskState',
            key: '11',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return dataChangeMap.taskState[value]
                }
            }
        },
        {
            title: '操作',
            key: 'op',
            fixed: 'right',
            width: 150,
            render: (col, record, index) => <TaskDetailModal taskKey={record.taskKey} 
                    token={token} userID={userID} showCopy={true} notifyRefresh={notifyRefresh} 
                    copyRecord={record}/>
        }
    ]
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10
        }
    })
    const pageValuePage3 = tableParams.pagination.current

    const fetchData = () => {
        fetchMyCreate(token, taskExecuterValuePage3, taskNameValuePage3,
            taskTypeValuePage3, taskCreatedBeginTimeValuePage3,
            taskCreatedEndTimeValuePage3,taskClassify, 10, pageValuePage3)
            .then(data => {
                if (data && data['data'] && data['data'].list) {
                    setPageThreeData(data['data'].list)
                    setTableParams(tableParams => ({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: data['data'].totalCount
                        }
                    }))
                } else {
                    setPageThreeData([])
                    setTableParams(tableParams => ({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: 0
                        }
                    }))
                }
            })
            .catch(error => {
                console.log("请求失败:", error)
            })
    }
    useEffect(() => {
        fetchData()
    }, [taskExecuterValuePage3, taskNameValuePage3, taskTypeValuePage3,
        taskCreatedBeginTimeValuePage3, taskCreatedEndTimeValuePage3,taskClassify, pageValuePage3, change])
    const handleTableChange = (pagination) => {
        setTableParams({
            pagination
        })
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setPageThreeData([])
        }
    }
    return (
        <div className="page3">
            <div className='page3-header'>
                <div className="filterType">
                    <span className='selectType'>截止时间</span>
                    <DatePicker.RangePicker
                        status='warning'
                        placeholder='warning status'
                        style={{ width: 400, backgroundColor: 'rgba(242,243,245,1)' }}
                        value={dateRange}
                        showTime={{
                            defaultValue: ['00:00:00', '23:59:59'],
                            format: 'HH:mm:ss',
                        }}
                        format='YYYY/MM/DD HH:mm:ss'
                        onChange={(dataString, value) => handleChange(dataString, 'taskCreatedTimePage3')}
                    />
                </div>
                <div className="filterType">
                    <span className='selectType'>价值类型</span>
                    <Select
                        placeholder='请选择'
                        style={{
                            width: 120,
                        }}
                        value={taskTypePage3}
                        allowClear
                        onChange={(value) => handleChange(value, 'taskTypePage3')}
                        options={[
                            {
                                value: '0',
                                label: '基础型',
                            },
                            {
                                value: '1',
                                label: '灵活型',
                            },
                            {
                                value: '2',
                                label: '创新型',
                            },
                            {
                                value: '3',
                                label: '复合型',
                            },
                            {
                                value: '4',
                                label: '学习型',
                            },
                            {
                                value: '5',
                                label: '其他类型',
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='page3-header'>
                <div className="filterType">
                    <span className='selectType'>执行人 </span>
                    <Input
                        placeholder='请输入'
                        style={{ width: 120 }}
                        value={taskExecuterPage3}
                        onChange={(value) => handleChange(value, 'taskExecuterPage3')}
                    />
                </div>
                <div className="taskClassify">
                    <span className='classify'>任务分类</span>
                    <Cascader
                        style={{
                            width: 200,
                        }}
                        showSearch
                        placeholder='请输入'
                        allowClear
                        onChange={handleTaskWorkOptionChange}
                        options={options}
                        loadMore={loadMore}
                    />
                </div>
                <div className="filterType">
                    <span className='selectType'>任务名称</span>
                    <Input
                        placeholder='请输入'
                        style={{ width: 200 }}
                        value={taskNamePage3}
                        onChange={(value) => handleChange(value, 'taskNamePage3')}
                    />
                </div>
                <div className="reset">
                    <Button className='btn'
                        onClick={clearLocalStorage}>
                        <span>重置</span>
                    </Button>

                </div>
            </div>
            <div className="page3-wrap">
                <Table
                    borderCell
                    columns={columns}
                    data={pageThreeData}
                    pagination={tableParams.pagination}
                    scroll={{
                        x: 1500
                    }}
                    onChange={handleTableChange}
                />
            </div>
        </div>
    )
}

export default MyCreate
/* eslint-enable */

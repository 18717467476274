/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './index.scss'
import { Cascader, Button, Select, Input, Table, Modal, Message, Icon } from '@arco-design/web-react'
import { fetchMyApply, fetchSecondTask, fetchThirdTask, remindMsg } from '../../../../../api/getExport'
import { dataChangeMap } from '../../../../../config/dataChange'
import TaskDetailModal from '../../../../task_detail_model'
import moment from "moment"
const IconFont = Icon.addFromIconFontCn({
    src: '//at.alicdn.com/t/font_180975_26f1p759rvn.js',
});
const optionsList = [
    {
        value: 0,
        label: '项目',
        isLeaf: false,
    },
    {
        value: 1,
        label: '事务',
        isLeaf: false,
    },
    {
        value: 2,
        label: '业务',
        isLeaf: false,
    },
    {
        value: 3,
        label: '事项',
        isLeaf: false,
    },
    {
        value: 4,
        label: '其他',
        isLeaf: false,
    }
]
const MyModify = ({ token, userID, change, notifyRefresh }) => {
    const [visible, setVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [taskKeyModify, setTaskKeyModify] = useState([])
    const [type, setType] = useState('checkbox');
    const [taskNameModify, setTaskNameModify] = useState('')
    const [SelectedTaskApplyTypeValue, setSelectedTaskApplyTypeValue] = useState('');
    const [inputHandler, setInputHandler] = useState('');
    const [taskClassify, setTaskClassify] = useState('')
    const [options, setOptions] = useState(optionsList)
    const [projectKey, setProjectKey] = useState(null)
    const [pageFourData, setPageFourData] = useState([]);
    const [tableFourParams, setTableFourParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        }
    });
    const loadMore = async (pathValue, level) => {
        try {
            if (level === 1) {
                const data = await fetchSecondTask(token, pathValue);
                pathValue = data.data;
            } else if (level === 2) {
                const values = pathValue.toString();
                const value = values.split(',')[1];
                const data = await fetchThirdTask(token, value);
                const ProjectKey = data.data[2].taskWorkProject;
                setProjectKey(ProjectKey);
                pathValue = data.data;
            }

            const nodes = pathValue.map((x, i) => ({
                label: level === 1 ? `${pathValue[i].projectName}` : `${pathValue[i].taskWorkName}`,
                value: `${pathValue[i].id}`,
                isLeaf: level >= 2,
            }));

            return nodes;
        } catch (error) {
            console.log("请求失败:", error);
            return [];
        }
    };
    const handleTaskWorkOptionChange = (value, selectedOptions) => {
        if (typeof selectedOptions === 'undefined' || typeof value === 'null') {
            setSelectedTaskWorkOption(null)
            return
        }
        const labels = selectedOptions.map(option => option.label);
        const encodedTaskClassify = encodeURIComponent(labels[1] + '#' + labels[2])
        setTaskClassify(encodedTaskClassify)
    }
    const handleSelectedTaskApplyTypeChange = (value) => {
        setSelectedTaskApplyTypeValue(value)
    };
    const handleTaskNameChange = (event) => {
        setTaskNameModify(event)
        localStorage.setItem('taskName', event)
    }
    const handleHandlerChange = (event) => {
        setInputHandler(event);
    };

    const reset = () => {
        setSelectedTaskApplyTypeValue('')
        setInputHandler('')
        setTaskNameModify('')
        setTaskClassify('')
    }


    const pageFour = tableFourParams.pagination.current;


    useEffect(() => {
        const limit = 10;
        const selectedTaskApplyTypeValue = SelectedTaskApplyTypeValue
        const taskName = taskNameModify

        fetchMyApply(token, limit, pageFour, selectedTaskApplyTypeValue, taskName, taskClassify, inputHandler)
            .then(data => {
                if (data && data['data'] && data['data'].list) {
                    setPageFourData(data['data'].list);
                    setTableFourParams(tableFourParams => ({
                        ...tableFourParams,
                        pagination: {
                            ...tableFourParams.pagination,
                            total: data['data'].totalCount
                        }
                    }));
                } else {
                    setPageFourData([]);
                    setTableFourParams(tableFourParams => ({
                        ...tableFourParams,
                        pagination: {

                            ...tableFourParams.pagination,
                            total: 0
                        }
                    }));
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [SelectedTaskApplyTypeValue, taskNameModify, taskClassify, inputHandler, pageFour, token, change]);
    const handleTableFourChange = (pagination) => {
        // 先检查页面大小是否改变
        if (pagination.pageSize !== tableFourParams.pagination?.pageSize) {
            // 如果页面大小改变了，应该重置页码为1，因为数据已经重新加载了
            setTableFourParams({
                pagination: {
                    current: 1, // 重置为第一页
                    pageSize: pagination.pageSize, // 更新页面大小
                    total: tableFourParams.pagination.total // 保持总数不变
                }
            });
        } else {
            // 如果页面大小没改变，只更新页码
            setTableFourParams({
                pagination
            });
        }
    }

    const columns = [
        {
            title: '申请时间',
            width: 100,
            dataIndex: 'taskApproveTime',
            key: '0',
            sorter: (a, b) => a.taskApproveTime - b.taskApproveTime,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '申请类型',
            width: 100,
            dataIndex: 'taskApproveType',
            key: '1',
            render: (value) => {
                if (value === null|| value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskApproveType[value]
                }
            }
        },
        {
            title: '任务分类',
            width: 100,
            dataIndex: 'taskKey',
            key: '01',
            render: (value) => {
                if (value === null|| value.trim() === '') {
                    return '—'
                }
                const parts = value.split('#');
                if (parts.length >= 2) {
                    return `${parts[0]}#${parts[1]}`;
                } else {
                    return value;
                }
            }
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: '2',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return value
                }
            }
        },
        {
            title: '价值类型',
            dataIndex: 'taskValueType',
            key: '3',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskValueType[value]
                }
            }
        },
        {
            title: '开展方式',
            dataIndex: 'taskWorkType',
            key: '4',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskWorkType[value]
                }
            }
        },
        {
            title: '预计耗时',
            dataIndex: 'taskExpectedTime',
            key: '5',
            width: 120,
            sorter: (a, b) => a.taskExpectedTime - b.taskExpectedTime,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return `${value}小时`;
                }
            }
        },
        {
            title: '截止时间',
            dataIndex: 'taskDeliverLasttime',
            key: '6',
            width: 100,
            sorter: (a, b) => a.taskDeliverLasttime - b.taskDeliverLasttime,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '执行人',
            dataIndex: 'taskExecuter',
            key: '7',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '处理人',
            dataIndex: 'taskHandler',
            key: '8',
            width: 100,
            render: (value, record) => {
                if (record.taskState == 0 || record.taskState == 1) {
                    return value ? value : record.taskApproverName || '—'
                } else if (record.taskState == 2) {
                    return value ? value : record.taskChecker || '—'
                } else {
                    return '—'
                }
            }
        },
        {
            title: '任务状态',
            dataIndex: 'taskState',
            key: '9',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskState[value]
                }
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (col, record, index) => <TaskDetailModal taskKey={record.taskKey} 
                    token={token} userID={userID} showCopy={true} notifyRefresh={notifyRefresh} 
                    copyRecord={record}/>
        }
    ]

    //批量通知
    const batchNotifications = () => {
        remindMsg(taskKeyModify, function () {
            setVisible(false)
            setSelectedRowKeys([])
            Message.success({
                icon: <IconFont type='icon-success' />,
                content: `通知已发送!`,
            });
        })
    }
    return (
        <div className='page4'>
            <div className='page4-header'>
                <div className="taskApplyType">
                    <span className='type'>申请类型</span>
                    <Select
                        defaultValue=""
                        value={SelectedTaskApplyTypeValue}
                        style={{
                            width: 120,
                        }}
                        allowClear
                        onChange={handleSelectedTaskApplyTypeChange}
                        options={[
                            {
                                value: '0',
                                label: '任务创建'
                            },
                            {
                                value: '1',
                                label: '任务修改'
                            }
                        ]}
                    >
                    </Select>
                </div>
                <div className="taskClassify">
                    <span className='classify'>任务分类</span>
                    <Cascader
                        style={{
                            width: 200,
                        }}
                        showSearch
                        placeholder='请输入'
                        allowClear
                        onChange={handleTaskWorkOptionChange}
                        options={options}
                        loadMore={loadMore}
                    />
                </div>
                <div className="taskName">
                    <span className='name'>任务名称</span>
                    <Input
                        value={taskNameModify}
                        style={{ width: 150 }}
                        allowClear
                        placeholder='请输入'
                        onChange={handleTaskNameChange} // 绑定处理器到输入框
                    />
                </div>
                <div className="taskHandler">
                    <span className='handler'>处理人</span>
                    <Input style={{ width: 150 }}
                        allowClear
                        placeholder='请输入'
                        onChange={handleHandlerChange}
                        value={inputHandler}
                    />
                </div>
                <Button className='btn-reset' onClick={reset}><span>重置</span></Button>
                <div className="urge">
                    <Button className='allUrge' disabled={selectedRowKeys.length === 0}
                        onClick={() => setVisible(true)} type='primary'>
                        <span>一键催办</span>
                    </Button>
                    <Modal
                        title={
                            <div style={{ textAlign: 'left' }}>
                                注意
                            </div>
                        }
                        visible={visible}
                        onOk={() => batchNotifications()}
                        onCancel={() => setVisible(false)}
                        autoFocus={false}
                        focusLock={true}
                        cancelButtonProps={{
                            className: 'allUrge',
                            style: {
                                position: 'relative',
                                left: 70,
                                backgroundColor: 'white',
                                color: '#1496db',
                                border: '1px solid #1496db'
                            }
                        }}
                        okButtonProps={{
                            className: "allUrge",
                            style: {
                                position: 'relative',
                                left: -420,
                                backgroundColor: 'white',
                                color: '#1496db',
                                border: '1px solid #1496db'
                            }
                        }}
                    >
                        <p>
                            将通知待办人
                            <br></br><br></br>
                            请确认是否通知？
                        </p>
                    </Modal>
                </div>
            </div>
            <div className="page4-wrap">
                <Table
                    rowKey="tid"
                    borderCell
                    columns={columns}
                    data={pageFourData}
                    pagination={tableFourParams.pagination}
                    scroll={{
                        x: 1500,
                    }}
                    rowSelection={{
                        type,
                        selectedRowKeys,
                        onSelect: (selected, record, selectedRows) => {
                            if (selected) {
                                setSelectedRowKeys(selectedRowKeys.concat(record.tid));
                                setTaskKeyModify([...taskKeyModify, record.taskKey])
                            } else {
                                setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.tid))
                                setTaskKeyModify(taskKeyModify.filter(key => key !== record.taskKey))
                            }
                        },
                        onSelectAll: (selected, selectedRows) => {
                            if (selected) {
                                const newSelectedRowKeys = selectedRows.map(row => row.tid);
                                setSelectedRowKeys(newSelectedRowKeys);
                                const newTaskKeys = selectedRows.map(row => row.taskKey);
                                setTaskKeyModify(newTaskKeys);
                            } else {
                                setSelectedRowKeys([]);
                                setTaskKeyModify([]);
                            }
                        },
                    }}
                    onChange={handleTableFourChange}
                />
            </div>
        </div>
    )
}

export default MyModify
/* eslint-enable */

export const dataChangeMap = {
    taskValueType: {
        "0": "基础型",
        "1": "灵活型",
        "2": "创新型",
        "3": "复合型",
        "4": "学习型",
        "5": "其他类型"
    },
    taskTimeType: {
        "0": "非标准",
        "1": "标准"
    },
    taskWorkType: {
        "0": "会议",
        "1": "独立执行"
    },
    taskState: {
        "0": "待创建",
        "1": "待交付",
        "2": "待验收",
        "3": "已完成",
        "4": "未完成",
        "5": "已取消"
    },
    approveResult: {
        "-1": "暂无",
        "0": "暂无",
        "1": "不达标",
        "2": "达标",
        "3": "需返工",
        "4": "差"
    },
    checkResult: {
        // "-1":"暂无",
        "0": "暂无",
        "1": "不达标",
        "2": "达标",
        "3": "需返工",
        "4": "差"
    },
    taskResult: {
        "0": "达标",
        "1": "不达标",
        "2": "差"
    },
    taskApproveResult: {
        "-1": "未处理",
        "0": "未通过",
        "1": "通过",
        "2": "部分调整"
    },
    taskTimeRequireType: {
        "0": "不限",
        "1": "固定时间"
    },
    taskPlaceRequireType: {
        "0": "不限",
        "1": "固定地点"
    },
    taskApproveType: {
        "0": "任务创建",
        "1": "任务修改",
        "2": "任务验收",
        "3": "任务重启"
    }
}

export const taskWordToNum = {
    taskApproveType: {
        "task_create": "0",
        "task_revise": "1",
        "task_check": "2"
    },
    taskApproveResult: {
        "applying": "-1",
        "rejected": "0",
        "pass": "1",
        "partly_adjust": "2"
    },
    taskState: {
        "creating": "0",
        "submitting": "1",
        "checking": "2",
        "done": "3",
        "fail": "4",
        "cancel": "5"
    },
    checkResult: {
        '达标': 0,
        '不达标': 1,
        '差': 2
    }
}

export const taskResultOptions = ["达标", "不达标", "差"]


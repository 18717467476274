import { useState } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { Layout, Menu } from '@arco-design/web-react';
import TaskList from './myTask/taskList';
import ProgressDashboard from './myTask/progressDashboard';
import TeamTaskList from './teamManage/teamTaskList'
import MemberProgress from './teamManage/memberProgress'
import Liuliang from './myManage/liuliang'
import Rencaiku from './myManage/rencaiku';
import React from 'react'
import './index.scss'

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Sider = Layout.Sider;
const Content = Layout.Content;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('我的任务', '1', null, [
    getItem('任务列表', 'taskList'),
    getItem('任务仪表盘', 'progressDashboard'),
  ]),
  getItem('团队管理', '2', null, [
    getItem('团队任务列表', 'teamTaskList'),
    getItem('成员进度概览', 'memberProgress'),
  ]),
  getItem('我的项目', '3', null, [
    getItem('流量项目', 'liuliang'),
    getItem('人才库项目', 'rencaiku'),
  ]),
  // getItem('任务监察', '4', null, [
  //   getItem('申诉处理', '11'),
  //   getItem('任务抽查', '12'),
  // ]),
];

const LayoutDesign = ({refresh,notifyRefresh}) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [siderWidth, setSiderWidth] = useState(208);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
    setSiderWidth(collapsed ? 60 : 208);
  };

  return (
    <Layout className="byte-layout-collapse-demo">
      <Sider
        collapsible
        theme="light"
        onCollapse={onCollapse}
        collapsed={collapsed}
        width={siderWidth}
      >
        <div className="logo" />
        <Menu theme="dark" autoOpen style={{ width: '100%' }}>
          {items.map((item) =>
            item.children ? (
              <SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.children.map((childItem) => (
                  <MenuItem key={childItem.key} onClick={() => navigate(childItem.key)}>
                    {childItem.label}
                  </MenuItem>
                ))}
              </SubMenu>
            ) : (
              <MenuItem key={item.key} icon={item.icon} onClick={() => navigate(item.key)}>
                {item.label}
              </MenuItem>
            )
          )}
        </Menu>
      </Sider>
      <Content style={{ background:'#ffffff', textAlign: 'center', padding: '30px' }}>
        <Routes>
          <Route path="/taskList" element={<TaskList  data={refresh} notifyRefresh={notifyRefresh}/>} />
          <Route path="/progressDashboard" element={<ProgressDashboard />} />
          <Route path="/teamTaskList" element={<TeamTaskList />} />
          <Route path="/memberProgress" element={<MemberProgress />} />
          <Route path="/liuliang" element={<Liuliang />} />
          <Route path="/rencaiku" element={<Rencaiku />} />
        </Routes>
      </Content>
    </Layout>
  );
};

export default LayoutDesign;

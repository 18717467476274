/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './index.scss'
import moment from 'moment'
import { Select, DatePicker, Table, Cascader, Input } from '@arco-design/web-react'
import { fetchMyExecute, fetchSecondTask, fetchThirdTask, } from '../../../../../api/getExport'
import { dataChangeMap } from '../../../../../config/dataChange'
import read from '../../../.././../assets/image/read.svg'
import unread from '../../../.././../assets/image/unread.svg'
import TaskDetailModal from '../../../../task_detail_model'

const optionsList = [
    {
        value: 0,
        label: '项目',
        isLeaf: false,
    },
    {
        value: 1,
        label: '事务',
        isLeaf: false,
    },
    {
        value: 2,
        label: '业务',
        isLeaf: false,
    },
    {
        value: 3,
        label: '事项',
        isLeaf: false,
    },
    {
        value: 4,
        label: '其他',
        isLeaf: false,
    }
]

const MyExecuter = ({ token, userID, change, notifyRefresh }) => {
    const [pageOneData, setPageOneData] = useState([])
    const [taskState, setTaskState] = useState('1')
    const [taskType, setTaskType] = useState('')
    const [taskName, setTaskName] = useState('')
    const [approveResultOne, setApproveResultOne] = useState('')
    const [dateRangeOne, setDateRangeOne] = useState(null)
    const [taskClassify, setTaskClassify] = useState('')
    const [options, setOptions] = useState(optionsList)
    const [projectKey, setProjectKey] = useState(null)
    // const [visible, setVisible] = useState(false);

    const handleTaskNameChange = (event) => {
        setTaskName(event)
        localStorage.setItem('taskName', event)
    }
    const handleChange = (value, name) => {
        if (typeof value === 'undefined') {
            value = ''
        }
        switch (name) {
            case 'taskState':
                setTaskState(value);
                break;
            case 'taskType':
                setTaskType(value);
                break;
            case 'approveResult':
                setApproveResultOne(value);
                break;
            default:
                break;
        }
    }
    const loadMore = async (pathValue, level) => {
        try {
            if (level === 1) {
                const data = await fetchSecondTask(token, pathValue);
                pathValue = data.data;
            } else if (level === 2) {
                const values = pathValue.toString();
                const value = values.split(',')[1];
                const data = await fetchThirdTask(token, value);
                const ProjectKey = data.data[2].taskWorkProject;
                setProjectKey(ProjectKey);
                pathValue = data.data;
            }

            const nodes = pathValue.map((x, i) => ({
                label: level === 1 ? `${pathValue[i].projectName}` : `${pathValue[i].taskWorkName}`,
                value: `${pathValue[i].id}`,
                isLeaf: level >= 2,
            }));

            return nodes;
        } catch (error) {
            console.log("请求失败:", error);
            return [];
        }
    };
    const handleTaskWorkOptionChange = (value, selectedOptions) => {
        if (typeof selectedOptions === 'undefined' || typeof value === 'null') {
            setSelectedTaskWorkOption(null)
            return
        }
        const labels = selectedOptions.map(option => option.label);
        const encodedTaskClassify = encodeURIComponent(labels[1] + '#' + labels[2])
        setTaskClassify(encodedTaskClassify)
    }
    const handleDateRangeChange = (dates) => {

        if (typeof (dates) == "undefined") {
            setDateRangeOne(null)
        } else {
            const StartDate = new Date(dates[0]);
            dates[0] = StartDate.getTime();
            const endDate = new Date(dates[1]);
            endDate.setHours(23, 59, 59, 999); // 设置小时、分钟、秒和毫秒
            dates[1] = endDate.getTime();
            setDateRangeOne(dates)
        }
    }

    const taskBeginTime = dateRangeOne ? dateRangeOne[0] : ''
    const taskEndTime = dateRangeOne ? dateRangeOne[1] : ''
    const approveResult = approveResultOne
    const taskStateValue = taskState
    const taskTypeValue = taskType

    const reset = () => {
        setDateRangeOne(null)
        setTaskState('')
        setTaskType('')
        setApproveResultOne('')
        setTaskClassify('')
        setTaskName('')
    }

    const columns = [
        {
            title: '读取状态',
            width: 100,
            dataIndex: 'isTaskOpenIdView',
            key: '1',
            fixed: 'left',
            render: (value) => (Number(value) === 0 ?
                <img src={unread} className='unread' alt="" style={{ width: 25 }} /> :
                <img src={read} className='read' alt="" style={{ width: 25 }} />)
        },
        {
            title: '更新时间',
            width: 110,
            dataIndex: 'taskUpdateddate',
            key: '2',
            sorter: (a, b) => a.taskUpdateddate - b.taskUpdateddate,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '任务分类',
            width: 100,
            dataIndex: 'taskKey',
            key: '01',
            render: (value) => {
                if (value === null) {
                    return '—'
                }
                const parts = value.split('#');
                if (parts.length >= 2) {
                    return `${parts[0]}#${parts[1]}`;
                } else {
                    return value;
                }
            }
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: '3',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return value
                }
            }
        },
        {
            title: '价值类型',
            dataIndex: 'taskValueType',
            key: '4',
            width: 100,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return dataChangeMap.taskValueType[value]
                }
            }
        },
        {
            title: '开展方式',
            dataIndex: 'taskWorkType',
            key: '5',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskWorkType[value]
                }
            }
        },
        {
            title: '时间要求',
            dataIndex: 'taskExecuteTimeStandard',
            key: '6',
            width: 165,
            render: (value) => {
                if (value === '0') {
                    return '不限';
                } else if (typeof value === 'string' && value.startsWith("1#")) {
                    const timeRange = value.split("#").slice(1); // 获取毫秒数时间范围
                    const startDate = new Date(parseInt(timeRange[0]));
                    const endDate = new Date(parseInt(timeRange[1]));
                    const startTime = startDate.toLocaleString(); // 格式化开始时间（包括时:分:秒）
                    const endTime = endDate.toLocaleString(); // 格式化结束时间（包括时:分:秒）
                    return (
                        <div>
                            {startTime}
                            <br />
                            至
                            <br />
                            {endTime}
                        </div>
                    );
                } else {
                    return '—'
                }
            },
        },
        {
            title: '地点要求',
            dataIndex: 'taskExecuteAddressStandard',
            key: '7',
            width: 100,
            render: (value) => {
                if (value === '0') {
                    return '不限';
                } else if (typeof value === 'string' && value.startsWith("1#")) {
                    const place = value.split("#").slice(1)
                    return `${place}`;
                } else {
                    return '—';
                }
            },
        },
        {
            title: '预计耗时',
            dataIndex: 'taskExpectedTime',
            key: '8',
            width: 110,
            sorter: (a, b) => a.taskExpectedTime - b.taskExpectedTime,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'
                } else {
                    return `${value}小时`
                }
            }
        },
        {
            title: '截止时间',
            dataIndex: 'taskDeliverLasttime',
            key: '9',
            sorter: (a, b) => a.taskDeliverLasttime - b.taskDeliverLasttime,
            width: 110,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '任务状态',
            dataIndex: 'taskState',
            key: '10',
            width: 100,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return dataChangeMap.taskState[value]
                }
            }
        },
        {
            title: '认定工时',
            dataIndex: 'taskRealTime',
            key: '11',
            width: 100,
            render: (value) => {
                if (value === null) {
                    return '—';
                } else {
                    return `${value}小时`;
                }
            },
        },
        {
            title: '验收结果',
            dataIndex: 'taskApproveResult',
            key: '12',
            width: 100,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return dataChangeMap.approveResult[value]
                }
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (col, record, index) => <TaskDetailModal taskKey={record.taskKey}
                token={token} userID={userID} showCopy={true} notifyRefresh={notifyRefresh}
                copyRecord={record} />
        }
    ]
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10
        }
    })
    const pageValue = tableParams.pagination.current
    const fetchData = () => {
        getPopExeUrl()
        fetchMyExecute(token, taskStateValue, taskTypeValue, taskBeginTime, taskEndTime, approveResult, taskClassify, taskName, 10, pageValue)
            .then(data => {
                if (data && data['data'] && data['data'].list) {
                    setPageOneData(data['data'].list)
                    setTableParams(tableParams => ({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: data['data'].totalCount
                        }
                    }))
                } else {
                    setPageOneData([])
                    setTableParams(tableParams => ({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: 0
                        }
                    }))
                }
            })
            .catch(error => {
                console.log("请求失败:", error)
            })
    }
    const getPopExeUrl =() => {
        let url = window.location.search
        console.log(url, 'url')
        let searchParams = new URLSearchParams(url);
        let taskPage = searchParams.get('taskPage');
        if (taskPage == 0) {
            let taskBeginTime = Number(searchParams.get('taskBeginTime'));
            let taskEndTime = Number(searchParams.get('taskEndTime'));
            let approveResult = searchParams.get('approveResult');
            if (taskBeginTime  && taskEndTime  && approveResult ) {
                console.log(`taskBeginTime: ${taskBeginTime}, taskEndTime: ${taskEndTime}, approveResult: ${approveResult}`);
                let taskTime = []
                taskTime.push(taskBeginTime, taskEndTime)
                setDateRangeOne(taskTime)
                setApproveResultOne(approveResult)
                setTaskState('')
            } else {
                console.log("我执行的页面参数获取不完全");
            }
        }
    }
    /* eslint-disable */
    useEffect(() => {
        if (null == localStorage.getItem("token")) {
            return
        }
        fetchData()
    }, [taskStateValue, taskTypeValue, taskBeginTime, taskEndTime, approveResult, taskClassify, taskName, pageValue, change])
    /* eslint-enable */
    const handleTableChange = (pagination) => {
        setTableParams({
            pagination
        })
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setPageOneData([])
        }
    }
    return (
        <div className="page1">
            <div className='page1-header'>
                <div className="deadline">
                    <span className='deadlineTip'>截止时间</span>
                    <DatePicker.RangePicker
                        value={dateRangeOne}
                        style={{ width: 400, backgroundColor: 'rgba(242,243,245,1)' }}
                        showTime={{
                            defaultValue: ['00:00:00', '23:59:59'],
                            format: 'HH:mm:ss',
                        }}
                        format='YYYY/MM/DD HH:mm:ss'
                        onChange={handleDateRangeChange}
                    />
                </div>
                <div className="taskName">
                    <span className='name'>任务名称</span>
                    <Input
                        placeholder='请输入'
                        style={{ width: 200 }}
                        value={taskName}
                        onChange={(value) => handleTaskNameChange(value, 'taskName')}
                    />
                </div>
                <div className="taskState">
                    <span className='state'>任务状态</span>
                    <Select
                        defaultValue="1"
                        style={{
                            width: 120,
                        }}
                        allowClear
                        value={taskState}
                        onChange={(value) => handleChange(value, 'taskState')}
                        options={[
                            {
                                value: '0',
                                label: '待创建'
                            },
                            {
                                value: '1',
                                label: '待交付',
                            },
                            {
                                value: '2',
                                label: '待验收',
                            },
                            {
                                value: '3',
                                label: '已完成',
                            },
                            {
                                value: '4',
                                label: '未完成',
                            },
                            {
                                value: '5',
                                label: '已取消',
                            }
                        ]}
                    />
                </div>
                <div className="taskClassify">
                    <span className='classify'>任务分类</span>
                    <Cascader
                        style={{
                            width: 200,
                        }}
                        showSearch
                        placeholder='请输入'
                        allowClear
                        onChange={handleTaskWorkOptionChange}
                        options={options}
                        loadMore={loadMore}
                    />
                </div>
                <div className="taskType">
                    <span className='type'>价值类型</span>
                    <Select
                        defaultValue="0"
                        style={{
                            width: 120,
                        }}
                        allowClear
                        value={taskType}
                        onChange={(value) => handleChange(value, 'taskType')}
                        options={[
                            {
                                value: '0',
                                label: '基础型',
                            },
                            {
                                value: '1',
                                label: '灵活型',
                            },
                            {
                                value: '2',
                                label: '创新型',
                            },
                            {
                                value: '3',
                                label: '复合型',
                            },
                            {
                                value: '4',
                                label: '学习型',
                            },
                            {
                                value: '5',
                                label: '其他类型',
                            }
                        ]}
                    />
                </div>
                <div className="approveResult">
                    <span className='approveResultTip'>验收结果</span>
                    <Select
                        defaultValue="0"
                        style={{
                            width: 120,
                        }}
                        allowClear
                        value={approveResultOne}
                        onChange={(value) => handleChange(value, 'approveResult')}
                        options={[
                            {
                                value: '0',
                                label: '暂无',
                            },
                            {
                                value: '1',
                                label: '不达标',
                            },
                            {
                                value: '2',
                                label: '达标',
                            },
                            {
                                value: '3',
                                label: '需返工',
                            },
                            {
                                value: '4',
                                label: '差',
                            }
                        ]}
                    />
                </div>
                <div className="reset">
                    <button className='btn' onClick={reset}><span>重置</span></button>
                </div>
            </div>
            <div className="page1-wrap">
                <Table
                    borderCell
                    columns={columns}
                    data={pageOneData}
                    pagination={tableParams.pagination}
                    scroll={{
                        x: 1500
                    }}
                    onChange={handleTableChange}
                />
            </div>
        </div>
    )
}

export default MyExecuter
/* eslint-enable */

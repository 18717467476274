/* eslint-disable */
import React, { useEffect, useState } from "react"
import { Avatar, Button, Input, Space } from '@arco-design/web-react'
import { IconUser } from '@arco-design/web-react/icon';
import { getTaskMsg, getUser, getUserInfo } from "../../api/TaskApi"
import "@arco-design/web-react/dist/css/arco.css"
import { handleJSAPIAccess, handleUserAuth } from '../../utils/auth_access_util'
import LayoutDesign from '../../components/layout'
import CreateModal from "../../components/createModal"
import './index.scss'


export const Home = () => {
    //const messages = [];
    const [userInfo, setUserInfo] = useState({})
    const [refreshData, setRefreshData] = useState(0);
    const [userImage, setUserImage] = useState('');
    const [count, setCount] = useState(0)
    const [showUserInfo, setShowUserInfo] = useState(false); // added state for showing user info
    const token = localStorage.getItem('token');
    const [userNames, setUserNames] = useState('');
    const [unionId, setUnionId] = useState('');
    const [visible, setVisible] = useState(false);
    // const getPopUrl =() =>{
    //     let url = decodeURIComponent(window.location.href)
    //     let parsedUrl = new URL(url);
    //     let searchParams = new URLSearchParams(parsedUrl.search);
    //     let taskFlowWork = searchParams.get('taskFlowWork');
    //     let taskNodeWork = searchParams.get('taskNodeWork');
    //     let taskName = searchParams.get('taskName');
    //     let taskExecuterId = searchParams.get('taskExecuterId');

    //     if (taskFlowWork && taskNodeWork && taskName && taskExecuterId) {
    //         let formattedString = `${taskFlowWork}#${taskNodeWork}#${taskName}#${taskExecuterId}`;
    //         console.log(formattedString)
    //         localStorage.setItem("popProjectKey", formattedString);

    //     }
    //     else {
    //         console.log("No taskKey found in URL");
    //     }
    // }

    const authenticateAndUpdate = () => {
        handleJSAPIAccess((isSuccess) => {
            // console.log('handleJSAPIAccess OK: ', isSuccess);
            // 免登处理
            handleUserAuth((userInfo) => {
                if (typeof (userInfo) === "undefined") {
                    console.log('handleUserAuth failed: ', userInfo);
                    return;
                }
                // console.log('handleUserAuth OK: ', userInfo);
                localStorage.setItem('token', userInfo.access_token);
                notifyRefresh()
                setUserInfo(userInfo);
                getUserInfo(function (userInfo) {
                    localStorage.setItem("userInfo", JSON.stringify(userInfo));
                    setUserNames(userInfo.name)
                    setUnionId(userInfo.union_id); // 注意这里的属性名应该是小写的，除非你的API确实是返回union_Id
                    if (getUser()) {
                        setUserImage(getUser().avatar_url.avatar_240);
                    }
                });
            });
        });
    };
    useEffect(() => {
        // 定义一个执行鉴权和更新用户信息的函数


        // 首次加载时执行一次
        authenticateAndUpdate();// 设置定时器，每两个小时执行一次
        const intervalId = setInterval(authenticateAndUpdate, 7150000); // 7200000毫秒等于2小时

        // 组件卸载时清除定时器
        return () => clearInterval(intervalId);
    }, []);


    //TODO 临时处理
    const handleCheckerChange = (event) => {
        // console.log('tokenChange:', event)
        localStorage.setItem('token', event);
        getUserInfo(function (data) {
            localStorage.setItem("userInfo", JSON.stringify(data))
            setUserNames(data.name)
            setUnionId(data.union_Id)
            if (getUser()) {
                setUserImage(getUser().avatar_url.avatar_240)
            }
        })
    };

    const notifyRefresh = () => {
        // console.log('notifyRefresh')
        setRefreshData(refreshData + 1)
    }
    const handleShowUserInfo = () => {
        setShowUserInfo(!showUserInfo);
    }
    const openModal = () => {
        setVisible(true);
    }

    const closeModal = (action) => {
        // console.log('closeModal', action)
        setVisible(false);
        setRefreshData(refreshData + 1)
    };
    return (
        <div className="home">
            <div className="system-header">
                <div className="header-left">
                    <span className="task-system">任务管理系统</span>
                </div>
                <div className="header-right">
                    {/*{<div className="task-search">*/}
                    {/*    <InputSearch*/}
                    {/*        searchButton*/}
                    {/*        placeholder='搜索任务'*/}
                    {/*        style={{width: 200}}*/}
                    {/*    />*/}
                    {/*</div>}*/}
                    <Space size='large'>
                        <Avatar>
                            {userImage ? (
                                <img
                                    alt='avatar'
                                    src={userImage}
                                />
                            ) : (
                                <IconUser />
                            )}
                        </Avatar>
                    </Space>
                    {/* <Button type='primary' onClick={handleShowUserInfo} className='show'>Show</Button> */}
                    <Button type='primary' onClick={openModal} className='createTask'>
                        创建任务
                    </Button>
                    <div className="userName"><span>欢迎{userNames}, 希望您在今天能有一个美丽的心情！！！</span></div>
                    {visible && <CreateModal token={token} visible={visible} onClose={closeModal} className="createModal" />}
                    {showUserInfo && (
                        <div className="showToken">
                            <div className="checker">
                                <span className='checkerToken'>token :</span>
                                <Input
                                    style={{ width: 410 }}
                                    allowClear
                                    defaultValue={token}
                                    onChange={handleCheckerChange}
                                />
                            </div>
                            {/* <div className="checkerName">
                                <span className='checkerUserName'>name: {userNames}</span>
                            </div> */}
                        </div>
                    )}
                    {/* {<div className="task-notice">
                        <div className="notice-logo" onClick={handleLogoClick}>
                            <img className="notice-svg" src={notice} alt=""/>
                            <div className="notice-number">
                                <span>{count}</span>
                            </div>
                            {showMessages && (
                                <div className="message-list">
                                    <ul className="no-bullet">
                                        {messages.map(message => (
                                            <li key={message.id}>
                                                新收到{message.content}任务,<a href={message.detailLink}
                                                                               className="no-underline">查看详情</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>} */}
                </div>
            </div>
            <div className="system-warp">
                <LayoutDesign refresh={refreshData} notifyRefresh={notifyRefresh} />
            </div>
        </div>
    );
}
/* eslint-enable */

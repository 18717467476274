import React from 'react';

//任务服务器url的前缀
export const TASKSERVERURL_PREFIX = 'http://116.62.65.222:8080/aicms/';
//export const TASKSERVERURL_PREFIX = 'http://localhost:8080/aicms/';

//用户access_token存储
export const AccessTokenContext = React.createContext("fly");

export default AccessTokenContext;

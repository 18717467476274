import { approveOtherResourceTask,approveTransferTask, 
    createTask, remindMsg, cancelTask } from '../../api/getExport';
import {dataChangeMap, taskWordToNum} from "../../config/dataChange";
import moment from 'moment'
import { 
    Form, Input, DatePicker, Modal, Collapse, Button, 
    Message, Space,InputNumber, Popconfirm
} from '@arco-design/web-react'
import React, { useState, useEffect } from 'react'
import './index.scss'
const CollapseItem = Collapse.Item;

const ApplyModule = ({token, record, initData, taskApplyData, 
                    deadline, setDeadline, resource, setResource,
                    expectedTime, setExpectedTime, form_info}) => {
    const [rejectModalVisible, setRejectModalVisible] = useState(false)
    const [passModalVisible, setPassModalVisible] = useState(false)
    const [revised, setRevised] = useState(false)
    const [feedBack, setFeedBack] = useState("")

    const init_apply_info=()=>{
        setRevised(false)
        setFeedBack("")
        initData()
    }

    const notify = () => {
        remindMsg([taskApplyData.taskKey], function () {
            Message.success({
                content: `通知已发送!`,
            });
        })
    }

    function generateUUID() {
        const hexDigits = '0123456789abcdef';
        let uuid = '';

        for (let i = 0; i < 36; i++) {
            if (i === 8 || i === 13 || i === 18 || i === 23) {
                uuid += '-';
            } else if (i === 14) {
                uuid += '4';
            } else if (i === 19) {
                uuid += hexDigits[(Math.random() * 4 | 8)];
            } else {
                uuid += hexDigits[(Math.random() * 16 | 0)];
            }
        }

        return uuid;
    }

    const handleTransfer=(params)=>{
        approveTransferTask(params)
        .then(transfer_data => {
            if (transfer_data != null){
                Message.success({id: 'handleApply', content: "审批成功"})
                init_apply_info()
            }
        }).catch(error2 => {
            console.log("approveTransferTask0 请求失败:", error2);
        })
    }
    
    const handleApplyOld=(params)=>{
        let isOtherChanged = false
        if (taskApplyData["截止时间"]["visible"] || taskApplyData["预计耗时"]["visible"]||
            taskApplyData["所需资源"]["visible"] || taskApplyData.taskAttach!=""){
            isOtherChanged = true
        }
        if (isOtherChanged && params.taskTransferId!=null){
            let approveOpKey = generateUUID();
            params["approveOpKey"] = approveOpKey
        }
        if (isOtherChanged){
            approveOtherResourceTask(params)
            .then(data => {
                if (data != null){
                    form_info.setFieldsValue({
                        "所需资源":taskApplyData["所需资源"]["applyValue"],
                        "预计耗时": taskApplyData["预计耗时"]["applyValue"]})
                    if (params.taskTransferId != null){
                        handleTransfer(params)
                    }
                    else{
                        Message.success({id: 'handleApply', content: "审批成功"})
                        init_apply_info()
                    }
                }
            }).catch(error => {
                console.log("approveResourceTask 请求失败:", error);
            })
        }else if (params.taskTransferId != null){
            handleTransfer(params)
        }else{
            Message.error({id: 'handleApply', content: "审批失败"})
        }
    }
    const handleApply=(params)=>{
        approveOtherResourceTask(params)
        .then(data => {
            if (data != null){
                form_info.setFieldsValue({
                    "所需资源":taskApplyData["所需资源"]["applyValue"],
                    "预计耗时": taskApplyData["预计耗时"]["applyValue"]})
                Message.success({id: 'handleApply', content: "任务修改审批成功"})
                init_apply_info()
            }
        }).catch(error => {
            console.log("approveResourceTask 请求失败:", error);
        })
    }
    const handleReject = () =>{
        let context = {}
        let params = {}
        params.token = token
        params.taskApproveId = taskApplyData.taskApproveId
        params.taskApproveName = taskApplyData.taskApproveName
        params.taskKey = taskApplyData.taskKey
        params.taskApproveType = taskApplyData.taskApproveType
        params.taskApproveAdvice = feedBack
        params.taskApproveResult = taskWordToNum.taskApproveResult["rejected"]
        params.taskApproveContent = JSON.stringify(context)
        params.taskTransferId = taskApplyData.taskTransferId
        params.taskTransferName = taskApplyData.executerApplyValue
        
        handleApply(params)
        setRejectModalVisible(false)
    }
    const handlePass = () => {
        let pass_type = revised ? taskWordToNum.taskApproveResult["partly_adjust"] : 
                                    taskWordToNum.taskApproveResult["pass"]
        let context = {}
        let deliver_lasttime = record.taskDeliverLasttime
        let expected_time = record.taskExpectedTime
        let need_recourse = record.taskResAttribute
        if (deadline!=0){
            context["taskDeliverLasttime"] = deadline
            deliver_lasttime = deadline
        }
        if (resource!=""){
            context["taskResAttribute"] = resource
            need_recourse = resource
        }
        if (expectedTime!=""){
            context["taskExpectedTime"] = expectedTime
            expected_time = expectedTime
        }
        let params = {}
        params.token = token
        params.taskApproveId = taskApplyData.taskApproveId
        params.taskApproveName = taskApplyData.taskApproveName
        params.taskKey = taskApplyData.taskKey
        params.taskApproveType = taskApplyData.taskApproveType
        params.taskApproveAdvice = feedBack
        params.taskApproveResult = pass_type
        params.taskApproveContent = JSON.stringify(context)
        params.taskTransferId = taskApplyData.taskTransferId
        params.taskTransferName = taskApplyData["任务转换"]["endValue"]

        handleApply(params)
        setPassModalVisible(false)
        if(taskApplyData.taskTransferId!=null){
            cancelTask(record.taskKey, "任务转派给 "+taskApplyData["任务转换"]["endValue"])
                .then(data => {
                    if (data!=null){
                        initData()
                    }
                })
                .catch(error => {
                    console.log("取消失败:", error);
                });
            createTask(token, record.taskName, record.taskDes, record.taskValueType, record.taskWorkType, 
                record.taskExecuteTimeStandard, record.taskExecuteAddressStandard, record.taskExecuteOtherStandard, 
                record.taskCheckStandard, expected_time, parseInt(deliver_lasttime), 
                record.taskSkillAttribute, need_recourse, record.taskChecker, record.taskCheckerId, 
                record.taskFlowWork, record.taskNodeWork, params.taskTransferName, params.taskTransferId, 
                record.taskApproverId, record.taskApproverName, record.taskWork, record.taskStandardPay, 
                record.taskWorkhoursType)
                .then(data => {
                  if (data != null) {
                    Message.success({
                      id: "handlePass",
                      content: `${record.taskName} 任务转派成功!`,
                    });
                  } else {
                    Message.error({
                      id: "handlePass",
                      content: `${data.retMsg}任务转派失败，请重试。`,
                    });
                  }
                })
        }
    }

    return (<CollapseItem className={'custom_style'} header='申请信息' name='1'>
        <div className='collapse-block'>
            <div className={"collapse-item"}>
                <span>申请类型：{taskApplyData["taskApplyType"]}</span>
                <span style={{display:taskApplyData.isApplying ? "": "none"}}>
                    申请时间：{taskApplyData.taskApplyTime}
                </span>
                <span style={{display:taskApplyData.isApplying ? "none": ""}}>
                    处理时间：{taskApplyData.taskDisposeTime}
                </span>
                <span style={{display:taskApplyData.isApprover ? "none": ""}}>
                    处理人：{taskApplyData.taskApproveName}
                </span>
                <span style={{display:taskApplyData.isApprover ? "": "none"}}>
                    申请人：{taskApplyData.taskApplyerName}
                </span>
                <Popconfirm 
                    focusLock
                    title='催办确认'
                    content='确认催办，处理人将收到催办消息。'
                    onOk={() => {
                        notify()
                    }}
                    onCancel={() => {
                        Message.error({
                            content: '取消催办',
                        });
                    }}
                >
                <Button className='allUrge' style={{display:(!taskApplyData.isApprover && 
                        taskApplyData.isApplyer && taskApplyData.isApplying)?"":"none"}}>催办</Button>
                </Popconfirm>
            </div>
            
            <div>
                <div className={"collapse-item"}
                    style={{display:taskApplyData.isApplyer && 
                            !taskApplyData.isApprover && taskApplyData.isApplying ? "": "none"}}>
                    申请状态：
                    <span style={{color:'red'}}>申请中</span> 
                    <span style={{color:'red'}}>{taskApplyData["additionInfo"]}</span>
                </div>
                <div className={"collapse-item"}
                    style={{display:taskApplyData.isApprover && taskApplyData.isApplying ? "": "none"}}>
                    处理状态：
                    <span style={{color:'red'}}>未处理</span> 
                    <span style={{color:'red'}}>{taskApplyData["additionInfo"]}</span>
                </div>
                <div className={"collapse-item"}
                    style={{display:taskApplyData.isRejected ? "": "none"}}>
                    <span>处理结果：已驳回</span> 
                    <span>驳回原因：{taskApplyData.taskFeedback}</span>
                </div>
                <div className={"collapse-item"}
                    style={{display:taskApplyData.isAdjust ? "": "none"}}>
                    <span>处理结果：部分调整</span> 
                    <span>调整原因：{taskApplyData.taskFeedback}</span>
                </div>
                <div className={"collapse-item"}
                    style={{display:taskApplyData.isPass ? "": "none"}}>
                    <span>处理结果：已通过</span> 
                </div>
            </div>
        </div>
        <Form layout='vertical' className={'collapse-form'} autoComplete='off'
                style={{display:taskApplyData.isReviseTask &&
                    (taskApplyData.taskApproveType==taskWordToNum.taskApproveType["task_revise"]) ? "": "none"}}>
            <Space id="executer" style={{display:taskApplyData["任务转换"]["visible"] ? "": "none"}}>
                <Form.Item disabled label='执行人' >
                    <Input value={taskApplyData["任务转换"]['beginValue']}/>
                </Form.Item>
                <Form.Item disabled label='申请修改为'>
                    <Input value={taskApplyData["任务转换"]['endValue']}/>
                </Form.Item>
            </Space>
            <Space id="deadline"
                style={{display:taskApplyData["截止时间"].visible ? "": "none"}}>
                <Form.Item disabled label='截止时间' >
                    <Input value={moment(taskApplyData["截止时间"]['beginValue']).format('YYYY-MM-DD HH:mm:ss')}/>
                </Form.Item>
                <Form.Item label='申请修改为'>
                    <DatePicker
                        disabled={taskApplyData.isApprover && taskApplyData.isApplying && 
                                taskApplyData["截止时间"]["editable"] ? false: true}
                        value={deadline}
                        showTime={{
                            defaultValue: '23:59:59',
                        }}
                        format='YYYY-MM-DD HH:mm:ss'
                        onChange={v=>{setDeadline(moment(v).format('x'));setRevised(true)}}
                    />
                </Form.Item>
                <Form.Item label='实际修改为' disabled 
                    style={{display:(taskApplyData.isAdjust &&
                            (taskApplyData["截止时间"]['applyValue']!=taskApplyData["截止时间"]['endValue']))? "": "none"}}>
                    <Input value={moment(taskApplyData["截止时间"]['endValue']).format('YYYY-MM-DD HH:mm:ss')} />
                </Form.Item>
            </Space>
            <Space id="resource" style={{display:taskApplyData["所需资源"]['visible'] ? "": "none"}}>
                <Form.Item disabled label='所需资源' >
                    <Input value={taskApplyData["所需资源"]['beginValue']}/>
                </Form.Item>
                <Form.Item disabled={taskApplyData.isApprover && taskApplyData.isApplying &&
                                    taskApplyData["所需资源"]["editable"] ? false: true}
                    label='申请修改为'>
                    <Input value={resource} onChange={v=>{setResource(v);setRevised(true)}}/>
                </Form.Item>
                <Form.Item label='实际修改为' disabled 
                    style={{display:taskApplyData.isAdjust &&
                        (taskApplyData["所需资源"]['applyValue']!=taskApplyData["所需资源"]['endValue'])? "": "none"}}>
                    <Input value={taskApplyData["所需资源"]['endValue']} />
                </Form.Item>
            </Space>
            <Space id="expected_time" style={{display:taskApplyData["预计耗时"]['visible'] ? "": "none"}}>
                <Form.Item disabled label='预计耗时' >
                    <InputNumber min={0} suffix="小时"
                        value={taskApplyData["预计耗时"]['beginValue']}/>
                </Form.Item>
                <Form.Item disabled={taskApplyData.isApprover && taskApplyData.isApplying &&
                                    taskApplyData["预计耗时"]["editable"] ? false: true}
                    label='申请修改为'>
                    <InputNumber min={0} suffix="小时"
                        value={expectedTime} onChange={v=>{setExpectedTime(v);setRevised(true)}}/>
                </Form.Item>
                <Form.Item label='实际修改为' disabled 
                    style={{display:taskApplyData.isAdjust &&
                        (taskApplyData["预计耗时"]['applyValue']!=taskApplyData["预计耗时"]['endValue'])? "": "none"}}>
                    <InputNumber min={0} suffix="小时"
                        value={taskApplyData["预计耗时"]['endValue']}/>
                </Form.Item>
            </Space>

            <Form.Item disabled label='补充说明'
                style={{display:(taskApplyData.taskAttach=="") ? "none":""}}>
                <Input value={taskApplyData.taskAttach}/>
            </Form.Item>
            
            <Form.Item wrapperCol={{ offset: 5 }} 
                style={{display:taskApplyData.isApprover && taskApplyData.isApplying? "": "none"}}>
                <Button type='primary' style={{marginRight: '10%'}} onClick={e=>setRejectModalVisible(true)}>驳回</Button>
                <Button type='primary' onClick={e=>setPassModalVisible(true)}>通过</Button>
            </Form.Item>
        </Form>

        <Modal
            title='驳回申请'
            visible={rejectModalVisible}
            footer={null}
            autoFocus={false}
            focusLock={true}
            onCancel={() => setRejectModalVisible(false)}
        >
            <Form onSubmit={handleReject}>
                <Form.Item label='驳回原因' field="驳回原因" rules={[{ required: true }]}>
                    <Input.TextArea onChange={v=>setFeedBack(v)}
                    />
                </Form.Item>
                <Form.Item label=' '>
                    <Button type='primary' htmlType='submit'>
                        确认驳回
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        <Modal
            title='通过申请'
            visible={passModalVisible}
            onOk={() => setPassModalVisible(false)}
            onCancel={() => setPassModalVisible(false)}
            autoFocus={false}
            focusLock={true}
            footer={null}
        >
            <Form onSubmit={handlePass}>
                <Form.Item 
                    style={{display: revised ? "":"none"}}
                    label='调整原因' field="调整原因" rules={[{ required: revised }]}>
                    <Input.TextArea onChange={v=>setFeedBack(v)}
                    />
                </Form.Item>
                <p style={{marginLeft: '9%',marginBottom:'5%', 
                    display: taskApplyData.taskTransferId!=null ? '':'none'}}>若修改执行人，审批通过后将自动转派任务，原任务将自动取消。</p>
                <Form.Item label=' '>
                    <Button type='primary' htmlType='submit' style={{marginLeft: '25%'}}>
                        确认通过
                    </Button>
                </Form.Item>
            </Form>
            
        </Modal>
        </CollapseItem>
    )
}

export default ApplyModule
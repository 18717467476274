import axios from 'axios'
import {Message, Notification} from '@arco-design/web-react';
import {TASKSERVERURL_PREFIX} from '../io/constants'
import {httpPostCall} from "../io/HttpRequestUtil";
import {handleJSAPIAccess, handleUserAuth} from "../utils/auth_access_util";
import {getUserInfo} from "./TaskApi";

const ipAddress = TASKSERVERURL_PREFIX.slice(7)

const authenticateAndUpdate = () => {
    handleJSAPIAccess((isSuccess) => {
        // 免登处理
        handleUserAuth((userInfo) => {
            if (typeof (userInfo) === "undefined") {
                console.log('handleUserAuth failed: ', userInfo);
                return;
            }
            console.log('handleUserAuth OK: ', userInfo);
            localStorage.setItem('token', userInfo.access_token);
            getUserInfo(function (userInfo) {
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
            });
        });
    });
};

//我执行的任务  1
export function fetchMyExecute(token, taskState, taskValueType, taskBeginTime, taskEndTime, approveResult, taskClassify, taskName, limit, page) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `task/queryTasks?openActionType=1&taskState=${taskState}
    &taskValueType=${taskValueType}&taskBeginTime=${taskBeginTime}&taskEndTime=${taskEndTime}
    &approveResult=${approveResult}&taskClassify=${taskClassify}&taskName=${taskName}&limit=${limit}&page=${page}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            if (response.data && response.data.data && response.data.data.list) {
                response.data.data.list = response.data.data.list.map(item => {
                    if (!item.taskInitDate) {
                        item.taskInitDate = '暂无'
                    }
                    if (!item.taskDeliverLasttime) {
                        item.taskDeliverLasttime = '暂无'
                    }
                    return item
                })
            }
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//我审批的任务 openActionType 2
export function fetchMyApprove(token, taskName, approveResult, taskApplyType, taskApproveBeginTime,
                               taskApproveEndTime, limit, page, taskState, taskValueType, taskApplyName, taskClassify) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `task/queryTasks?openActionType=2&taskName=${taskName}&approveResult=${approveResult}&taskApplyType=${taskApplyType}&taskApproveBeginTime=${taskApproveBeginTime}&taskApproveEndTime=${taskApproveEndTime}&limit=${limit}&page=${page}&taskState=${taskState}&taskValueType=${taskValueType}&taskApplyName=${taskApplyName}&taskClassify=${taskClassify}`,
        headers: {
            //user_access_token
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            if (response.data && response.data.data && response.data.data.list) {
                response.data.data.list = response.data.data.list.map(item => {
                    if (!item.taskApproveTime) {
                        item.taskApproveTime = '暂无'
                    }
                    if (!item.taskDeliverLasttime) {
                        item.taskDeliverLasttime = '暂无'
                    }
                    return item
                })
            }
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//我创建的任务  openActionType 3
export function fetchMyCreate(token, taskExecuter, taskName, taskValueType,
                              taskCreatedBeginTime, taskCreatedEndTime, taskClassify, limit, page) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `task/queryTasks?openActionType=3&taskExecuter=${taskExecuter}&taskName=${taskName}&taskValueType=${taskValueType}&taskCreatedBeginTime=${taskCreatedBeginTime}&taskCreatedEndTime=${taskCreatedEndTime}&taskClassify=${taskClassify}&limit=${limit}&page=${page}`,
        headers: {
            //user_access_token
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//我申请的任务 openActionType 4
export function fetchMyApply(token, limit, page, taskApplyType, taskName, taskClassify, taskHandler) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `task/queryTasks?openActionType=4&limit=${limit}&page=${page}&taskApplyType=${taskApplyType}&taskName=${taskName}&taskClassify=${taskClassify}&taskHandler=${taskHandler}&approveResult=-1`,
        headers: {
            //user_access_token
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            if (response.data && response.data.data && response.data.data.list) {
                response.data.data.list = response.data.data.list.map(item => {
                    if (!item.taskApproveTime) {
                        item.taskApproveTime = '暂无'
                    }
                    if (!item.taskDeliverLasttime) {
                        item.taskDeliverLasttime = '暂无'
                    }
                    return item
                })
            }
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//我验收的任务 openActionType 5
export function fetchMyChecked(token, limit, page, taskCheckBeginTime, taskCheckTimes, taskCheckEndTime, taskState, approveResult, taskClassify, taskName) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `task/queryTasks?openActionType=5&limit=${limit}&page=${page}&taskCheckBeginTime=${taskCheckBeginTime}
        &taskCheckTimes=${taskCheckTimes}&taskCheckEndTime=${taskCheckEndTime}&taskState=${taskState}&approveResult=${approveResult}&taskClassify=${taskClassify}&taskName=${taskName}`,
        headers: {
            //user_access_token
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//查看任务具体信息
export function checkTask(token, taskKey) {
    const encodedTaskKey = encodeURIComponent(taskKey)

    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `task/viewTask?taskKey=${encodedTaskKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//申请任务验收
export function ApplyCheck(token, taskKey) {
    const encodedTaskKey = encodeURIComponent(taskKey)

    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `apply/applyAcceptTask?taskKey=${encodedTaskKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//获取用户的上级信息
export function fetchUpperInfo(token, userId, projectKey) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `user/queryApproveUser?userId=${userId}&projectKey=${projectKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502' && '999999') {
                    errorMsg = '请先选择任务分类及执行人'
                    authenticateAndUpdate()
                }
                if (response.data.retCode == '999999') {
                    errorMsg = '请先选择任务分类及执行人'
                }
                Message.error({
                    title: "请求失败",
                    content: errorMsg,
                })
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Message.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//获取用户的可选执行人
export function fetchCandidate(token) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + 'user/queryUserDepartmentInfo',
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//获取用户下级
export function fetchSubStaff(token) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + 'user/querySubStaff',
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//创建任务二级目录
export function fetchSecondTask(token, type) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `project/queryPojectList?type=${type}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//创建任务三级目录
export function fetchThirdTask(token, projectKey) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `project/queryWorks?projectKey=${projectKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//添加任务申请接口
export function createTask(token, taskName, taskDes, taskValueType, taskWorkType, taskExecuteTimeStandard,
                           taskExecuteAddressStandard, taskExecuteOtherStandard, taskCheckStandard, taskExpectedTime,
                           taskDeliverLasttime, taskSkillAttribute, taskResAttribute, taskChecker,
                           taskCheckerId, taskFlowWork, taskNodeWork, taskExecuter, taskExecuterId, taskApproverId, taskApproverName,
                           taskWork, taskStandardPay, taskWorkhoursType, taskApplyAttach = "") {
    return axios({
        method: 'POST', // 修改请求类型为 POST
        url: 'http://' + ipAddress + 'apply/addNewTask',
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskName": taskName, // "0"任务名称
            "taskDes": taskDes, //"0"任务描述
            "taskValueType": taskValueType, //"0"任务价值分类0.基础型 1.灵活型2.创新型3.复合型4.学习型5.其他类型
            "taskWorkhoursType": taskWorkhoursType, //"0"任务工时认定分类0.标准1.非标准
            "taskWorkType": taskWorkType,//"0"任务开展方式0 会议 1.独立执行
            "taskExecuteTimeStandard": taskExecuteTimeStandard,//"0"任务执行时间规范0不限1固定
            "taskExecuteAddressStandard": taskExecuteAddressStandard,//"1"任务执行时间规范0不限1固定
            "taskExecuteOtherStandard": taskExecuteOtherStandard,//"0"任务执行其他规范
            "taskCheckStandard": taskCheckStandard,//"0"任务验收标准
            "taskExpectedTime": taskExpectedTime,//"0"任务预计耗时
            "taskDeliverLasttime": taskDeliverLasttime,//1706164975830任务最晚交付时间
            "taskSkillAttribute": taskSkillAttribute,//"0"任务技能要求
            "taskResAttribute": taskResAttribute,//"0"任务资源配置要求
            // "taskDeliverWay": taskDeliverWay,//"0"任务交付方式
            // "taskCheckWay": taskCheckWay,//"0"任务验收方式
            "taskChecker": taskChecker,//"fly"任务验收人
            "taskCheckerId": taskCheckerId,//"11212121"任务验收id
            "taskFlowWork": taskFlowWork,// null任务所属具体项目
            "taskNodeWork": taskNodeWork,// "0"任务所属项目分组
            "taskExecuter": taskExecuter,//"杨开鹏"任务执行人
            "taskExecuterId": taskExecuterId,//"443434"任务执行人id
            "taskApproverId": taskApproverId,//"1212121"任务审批人id
            "taskApproverName": taskApproverName,//"周天洋"任务审批人姓名
            "taskWork": taskWork,//"0"所属事务和项目分类 0项目1事务2业务3事项4其他
            "taskStandardPay": taskStandardPay,//null是否按照标准计
            "taskApplyAttach": taskApplyAttach
        }
    })
        .then(response => {
            if (response.data.retCode == '000000') {
                return response.data;
            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    errorMsg = 'token过期'
                    authenticateAndUpdate()
                } else if (response.data.retCode == 'AIT604') {
                    errorMsg = '重复任务已存在，请修改任务名称后重新提交'
                }
                Message.error({
                    title: "请求失败",
                    content: errorMsg,
                })
            }
        })
        .catch(error => {
            console.error(error)
            Message.error({
                title: "网络错误",
                content: error.message,
            })
        })
}

//任务验收申请接口
export function applyAcceptTask(token, taskAttach, taskKey) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `apply/applyAcceptTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            'taskAttach': taskAttach,
            'taskKey': taskKey
        },
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务转让申请接口
export function applyTransferTask(params) {
    const encodedTaskKey = encodeURIComponent(params.taskKey);
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `apply/applyTransferTask?taskKey=${encodedTaskKey}&userId=${params.unionID}&opKey=${params.opKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期

                    errorMsg = 'token过期'
                    authenticateAndUpdate()
                }
                if (response.data.retCode == 'AIT604') {
                    errorMsg = '该执行人已被转派过，请重新选择！'
                }
                Message.error({
                    title: "请求失败",
                    content: errorMsg,
                })
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务稽查申请接口
export function applyAuditTask(token, taskKey) {
    const encodedTaskKey = encodeURIComponent(taskKey)
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `apply/applyAuditTask?taskKey=${encodedTaskKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务其他资源申请
export function applyOtherResourceTask(params) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `apply/applyOtherResourceTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskKey": params.taskKey,
            "taskExpectedTime": params.taskExpectedTime,
            "taskDeliverLasttime": params.taskDeliverLasttime,
            "taskResAttribute": params.taskResAttribute,
            "taskApplyAttach": params.taskApplyAttach,
            "applyOpKey": params.opKey,
            "taskExecuterId":params.taskExecuterId,

        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务验收审批接口
export function approveAcceptTask(params) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `approve/approveAcceptTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskApproveId": params.taskApproveId,
            "taskApproveName": params.taskApproveName,
            "taskKey": params.taskKey,
            "taskApproveType": params.taskApproveType,
            "taskApproveResult": params.taskApproveResult,
            "taskCheckResult": params.taskCheckResult,
            "taskApproveAdvice": params.taskApproveAdvice,
            "taskApproveContent": params.taskApproveContent

        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//添加任务审批接口
export function approveNewTask(params) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `approve/approveNewTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskApproveId": params.taskApproveId,
            "taskApproveName": params.taskApproveName,
            "taskKey": params.taskKey,
            "taskApproveType": params.taskApproveType,//审批类型 0.任务创建审批 1.任务转让审批 2.任务验收审批 3.任务取消审批 4.任务稽查审批
            "taskApproveAdvice": params.taskApproveAdvice,//审批建议
            "taskApproveResult": params.taskApproveResult,//0拒绝 1通过
            "taskApproveContent": params.taskApproveContent
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务其他资源审批接口
export function approveOtherResourceTask(params) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `approve/approveOtherResourceTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskApproveId": params.taskApproveId,
            "taskApproveName": params.taskApproveName,
            "taskKey": params.taskKey,
            "taskApproveType": params.taskApproveType,//审批类型 0.任务创建审批 1.任务转让审批 2.任务验收审批 3.任务取消审批 4.任务稽查审批
            "taskApproveAdvice": params.taskApproveAdvice,//审批建议
            "taskApproveResult": params.taskApproveResult,//0拒绝 1通过
            "taskApproveContent": params.taskApproveContent,
            "approveOpKey": params.approveOpKey
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务转让审批接口
export function approveTransferTask(params) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `approve/approveTransferTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskApproveId": params.taskApproveId,
            "taskApproveName": params.taskApproveName,
            "taskKey": params.taskKey,
            "taskApproveType": params.taskApproveType,
            "taskApproveResult": params.taskApproveResult,
            "taskTransferId": params.taskTransferId,
            "taskTransferName": params.taskTransferName,
            "approveOpKey": params.approveOpKey

        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务稽查审批接口
export function approveAudioTask(token, taskApproveId, taskApproveName, taskKey, taskApproveType, taskApproveAdvice, taskApproveResult) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `approve/approveAudioTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskApproveId": taskApproveId,
            "taskApproveName": taskApproveName,
            "taskKey": taskKey,
            "taskApproveType": taskApproveType,//审批类型 0.任务创建审批 1.任务转让审批 2.任务验收审批 3.任务取消审批 4.任务稽查审批
            "taskApproveAdvice": taskApproveAdvice,//审批建议
            "taskApproveResult": taskApproveResult//0拒绝 1通过
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务取消接口
export function cancelTask(taskKey, taskCancelReason) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `task/cancelTask`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskKey": taskKey,
            "taskCancelReason": taskCancelReason
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//任务转让验收人接口
export function transferCheckTask(token, taskName, taskDes, taskValueType, taskWorkhoursType, taskWorkType, taskExecuteTimeStandard, taskExecuteAddressStandard, taskExecuteOtherStandard, taskCheckStandard, taskExpectedTime, taskDeliverLasttime, taskSkillAttribute, taskResAttribute, taskDeliverWay, taskCheckWay, taskChecker, taskCheckerId, taskFlowWork, taskNodeWork, taskExecuter, taskExecuterId, taskApproverId, taskApproverName, taskWork, taskStandardPay) {
    return axios({
        method: 'POST', // 修改请求类型为 POST
        url: 'http://' + ipAddress + 'task/transferCheckTask',
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskName": taskName, //任务名称
            "taskDes": taskDes, //任务描述
            "taskValueType": taskValueType, //任务价值分类0.基础型 1.灵活型2.创新型3.复合型4.学习型5.其他类型
            "taskWorkhoursType": taskWorkhoursType, //任务工时认定分类0.标准1.非标准
            "taskWorkType": taskWorkType, //任务开展方式0 会议 1.独立执行
            "taskExecuteTimeStandard": taskExecuteTimeStandard, //任务执行时间规范0不限1固定
            "taskExecuteAddressStandard": taskExecuteAddressStandard, //任务执行地点规范0不限1固定
            "taskExecuteOtherStandard": taskExecuteOtherStandard, //任务执行其他规范
            "taskCheckStandard": taskCheckStandard, //任务验收标准
            "taskExpectedTime": taskExpectedTime, //任务预计耗时
            "taskDeliverLasttime": taskDeliverLasttime, //任务最晚交付时间
            "taskSkillAttribute": taskSkillAttribute, //任务技能要求
            "taskResAttribute": taskResAttribute, //任务资源配置要求
            "taskDeliverWay": taskDeliverWay, //任务交付方式
            "taskCheckWay": taskCheckWay, //任务验收方式
            "taskChecker": taskChecker, //任务验收人
            "taskCheckerId": taskCheckerId, //任务验收id
            "taskFlowWork": taskFlowWork, //任务所属具体项目
            "taskNodeWork": taskNodeWork, //任务所属项目分组
            "taskExecuter": taskExecuter, //任务执行人
            "taskExecuterId": taskExecuterId, //任务执行人id
            "taskApproverId": taskApproverId, //任务审批人id
            "taskApproverName": taskApproverName, //任务审批人姓名
            "taskWork": taskWork, //所属事务和项目分类 0项目1事务2业务3事项4其他
            "taskStandardPay": taskStandardPay, //是否按照标准计算
        }
    })
        .then(response => {
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            })
        })
}

//添加任务草稿接口
export function addDraft(token, taskDraftContent, taskDraftType, taskDraftName) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + 'drafts/addDraft',
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            'taskDraftContent': taskDraftContent,
            'taskDraftType': taskDraftType,
            'taskDraftName': taskDraftName

        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//删除任务草稿接口
export function deleteDraft(token, draftKey) {
    const encodedDraftKey = encodeURIComponent(draftKey)
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `drafts/deleteDraft?draftKey=${encodedDraftKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//查询任务草稿接口
export function queryDraft(token, draftKey) {
    const encodedDraftKey = encodeURIComponent(draftKey)
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `drafts/queryDraft?draftKey=${encodedDraftKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//上传文件接口
export function uploadFile(token, taskName, taskAttachDes, taskAttachFiles) {
    // Create a FormData object
    const formData = new FormData()

    // Append taskAttachDes as a field in the form data
    formData.append('taskAttachDes', taskAttachDes)

    // Append each file to the form data
    taskAttachFiles.forEach((file) => {
        formData.append('taskAttachFile', file.originFile);
    });

    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + `file/upload/${taskName}`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'user_access_token': localStorage.getItem('token'),
        },
        data: formData,
    })
        .then((response) => {
            // Directly return the response data
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch((error) => {
            // Handle errors
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // or return error information
        })
}

//获取用户未读消息接口
export function getMsg(token) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `msg/getMsg`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//读取消息接口
export function readMsg(token, msgId) {
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `msg/readMsg?id=${msgId}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//获取审批信息接口
export function getApproveInfo(token, taskKey) {
    const encodedTaskKey = encodeURIComponent(taskKey);
    return axios({
        method: 'GET',
        url: 'http://' + ipAddress + `approve/getApproveInfoFlow?taskKey=${encodedTaskKey}`,
        headers: {
            'user_access_token': localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
            ;
        })
        .catch(error => {
            // 处理错误
            console.error(error);
            Notification.error({
                title: "网络错误",
                content: error.message,
            }); // 或者返回错误信息
        });
};

//审批人的编辑接口
export function approveEditTask(params) {
    return axios({
        method: 'POST',
        url: 'http://' + ipAddress + 'task/approveEditTask',
        headers: {
            'user_access_token': localStorage.getItem('token')
        },
        data: {
            "taskName": params.taskName, //任务名称1
            "taskDes": params.taskDes, //任务描述
            "taskKey": params.taskKey, // 任务key
            "taskState": params.taskState, // 任务状态
            "taskValueType": params.taskValueType, //任务价值分类0.基础型 1.灵活型2.创新型3.复合型4.学习型5.其他类型
            "taskWorkhoursType": params.taskWorkhoursType, //任务工时认定分类0.标准1.非标准
            "taskWorkType": params.taskWorkType, //任务开展方式0 会议 1.独立执行
            "taskExecuteTimeStandard": params.taskExecuteTimeStandard, //任务执行时间规范0不限1固定
            "taskExecuteAddressStandard": params.taskExecuteAddressStandard, //任务执行地点规范0不限1固定
            "taskExecuteOtherStandard": params.taskExecuteOtherStandard, //任务执行其他规范
            "taskCheckStandard": params.taskCheckStandard, //任务验收标准
            "taskExpectedTime": params.taskExpectedTime, //任务预计耗时
            "taskDeliverLasttime": params.taskDeliverLasttime, //任务最晚交付时间
            "taskSkillAttribute": params.taskSkillAttribute, //任务技能要求
            "taskResAttribute": params.taskResAttribute, //任务资源配置要求
            "taskDeliverWay": params.taskDeliverWay, //任务交付方式
            "taskCheckWay": params.taskCheckWay, //任务验收方式
            "taskChecker": params.taskChecker, //任务验收人
            "taskCheckerId": params.taskCheckerId, //任务验收人id
            "taskFlowWork": params.taskFlowWork, //任务所属具体项目1
            "taskNodeWork": params.taskNodeWork, //任务所属项目分组1
            "taskExecuter": params.taskExecuter, //任务执行人1
            "taskExecuterId": params.taskExecuterId, //任务执行人id1
            "taskApproverId": params.taskApproverId, //任务审批人id1
            "taskApproverName": params.taskApproverName, //任务审批人姓名1
            "taskWork": params.taskWork, //所属事务和项目分类 0项目1事务2业务3事项4其他
            "taskStandardPay": params.taskStandardPay, //是否按照标准计算
            "taskAttach": params.taskAttach
        }
    })
        .then(response => {
            // 直接返回响应数据
            if (response.data.retCode == '000000') {
                return response.data;

            } else {
                let errorMsg = response.data.retMsg;
                if (response.data.retCode == 'AIT502') {
                    //token过期
                    authenticateAndUpdate()
                } else {
                    Notification.error({
                        title: "请求失败",
                        content: errorMsg,
                    })
                }
            }
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

//
export function fetchUserImage() {
    return axios({
        method: 'GET',
        url: 'https://open.feishu.cn/open-apis/authen/v1/user_info',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
            // 直接返回响应数据
            return response.data.data.avatar_middle;
        })
        .catch(error => {
            // 处理错误
            console.error(error)
            Notification.error({
                title: "网络错误",
                content: error.message,
            }) // 或者返回错误信息
        })
}

// 提醒消息接口
export function remindMsg(taskKeyJson, onSuccess) {
    httpPostCall("msg/remindMsg", taskKeyJson, onSuccess)
}
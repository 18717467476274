import React from 'react';


export default function NotFound() {
    return (
        <div>
            <h1>404 NotFound</h1>
        </div>
    );
}

/* eslint-disable */
import React, { useState } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Radio,
  Cascader,
  InputNumber,
  DatePicker,
  Modal, Avatar,
} from '@arco-design/web-react';
import Draggable from 'react-draggable';
import { fetchSecondTask, fetchThirdTask, fetchCandidate, fetchUpperInfo, createTask } from '../../api/getExport'
import { Message, Icon } from '@arco-design/web-react';
import { addDraft, TaskDrafts } from '../../api/DraftsApi'
import './index.scss';

const IconFont = Icon.addFromIconFontCn({
  src: '//at.alicdn.com/t/font_180975_26f1p759rvn.js',
});
const { Option } = Select;
const TextArea = Input.TextArea;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const optionsList = [
  {
    value: 0,
    label: '项目',
    isLeaf: false,
  },
  {
    value: 1,
    label: '事务',
    isLeaf: false,
  },
  {
    value: 2,
    label: '业务',
    isLeaf: false,
  },
  {
    value: 3,
    label: '事项',
    isLeaf: false,
  },
  {
    value: 4,
    label: '其他',
    isLeaf: false,
  }
]

const CreateModal = ({ visible, token, onClose }) => {
  const [form] = Form.useForm()
  const [timeRequirement, setTimeRequirement] = useState('0')
  const [selectedDates, setSelectedDates] = useState(['', ''])
  const [selectedPlace, setSelectedPlace] = useState('')
  const [formatTime, setFormatTime] = useState('0')
  const [placeRequirement, setPlaceRequirement] = useState('0')
  const [formatPlace, setFormatPlace] = useState('0')
  const [disabled, setDisabled] = useState(true)
  const [options, setOptions] = useState(optionsList)
  const [queryAllStaffOptions, setQueryAllStaffOptions] = useState([])
  const [approvalName, setApprovalName] = useState('')
  const [approvalId, setApprovalId] = useState('')
  const [checkerName, setCheckerName] = useState('')
  const [checkerId, setCheckerId] = useState('')
  const [unionId, setUnionId] = useState(null);
  const [executer, setExecuter] = useState(null)
  const [projectKey, setProjectKey] = useState(null)
  const [workHoursType, setWorkHoursType] = useState('1')
  const [createTaskName, setCreateTaskName] = useState('')
  const [taskDescribe, setTaskDescribe] = useState('')
  const [checkStandard, setCheckStandard] = useState('')
  const [skillAttribute, setSkillAttribute] = useState('')
  const [resAttribute, setResAttribute] = useState('')
  const [expectedTime, setExpectedTime] = useState('')
  const [deliverLasttime, setDeliverLasttime] = useState('')
  const [selectedTaskWorkOption, setSelectedTaskWorkOption] = useState(null);
  const [workType, setWorkType] = useState('')
  const [valueType, setValueType] = useState('')
  const [approvalOptions, setApprovalOptions] = useState([]);
  const handleTimeRequirementChange = (value) => {
    setTimeRequirement(value)
    setFormatTime(value === '0' ? '0' : `1#${selectedDates[0]}#${selectedDates[1]}`)
  }
  const handlePlaceRequirementChange = (value) => {
    setPlaceRequirement(value)
    setFormatPlace(value === '0' ? '0' : `1#${selectedPlace}`)
  }
  const loadMore = async (pathValue, level) => {
    try {
      if (level === 1) {
        const data = await fetchSecondTask(token, pathValue);
        pathValue = data.data;
      } else if (level === 2) {
        const values = pathValue.toString();
        const value = values.split(',')[1];
        const data = await fetchThirdTask(token, value);
        const ProjectKey = data.data[2].taskWorkProject;
        setProjectKey(ProjectKey);
        pathValue = data.data;
      }

      const nodes = pathValue.map((x, i) => ({
        label: level === 1 ? `${pathValue[i].projectName}` : `${pathValue[i].taskWorkName}`,
        value: `${pathValue[i].id}`,
        isLeaf: level >= 2,
      }));

      return nodes;
    } catch (error) {
      console.log("请求失败:", error);
      return [];
    }
  };

  const handleExecuteClick = () => {
    fetchCandidate(token)
      .then(data => {
        const filteredOptions = data.data.map(item => ({
          avatar_url: item.avatar_url.avatar_240,
          label: item.name,//"计涛",//item.name,
          value: item.name,//"计涛",//item.name,
          id: item.union_Id//"on_6f1fffbac74783628e7ba4cbf1a29c4e"//item.union_Id
        }));
        setQueryAllStaffOptions(filteredOptions)
      })
      .catch(error => {
        console.log("请求失败:", error);
        setQueryAllStaffOptions([])
      });
  }
  const handleExecuteChange = (value) => {
    const selectedOption = queryAllStaffOptions.find(option => option.value === value);
    if (selectedOption) {
      const UnionId = selectedOption.id;
      const Executer = selectedOption.label

      setExecuter(Executer)
      setUnionId(UnionId);
    }
  }

  const handleApprovalClick = () => {
    fetchUpperInfo(token, unionId, projectKey)
      .then(data => {
        const options = data.data.map(item => ({
          name: item.name, // 审批人名称
          union_Id: item.union_Id, // 对应的union_Id
          avatar_url: item.avatar_url.avatar_240

        }));
        setApprovalOptions(options); // 设置状态以在Select中展示所有审批人
      })
      .catch(error => {
        console.log("请求失败:", error);
      });
  };

  const handleApprovalChange = (value, option) => {
    // 更新选中的审批人的union_id
    setApprovalId(option._key);
    setApprovalName(value)
  };

  const [checkerOptions, setCheckerOptions] = useState([]);
  const handleCheckerClick = () => {
    fetchUpperInfo(token, unionId, projectKey)
      .then(data => {
        const options = data.data.map(item => ({
          name: item.name, // 验收人名称
          union_Id: item.union_Id, // 对应的union_Id
          avatar_url: item.avatar_url.avatar_240,
        }));
        setCheckerOptions(options); // 设置状态以在Select中展示所有验收人
      })
      .catch(error => {
        console.log("请求失败:", error);
      });
  };

  const handleCheckerChange = (value, option) => {
    // 更新选中的审批人的union_id
    setCheckerId(option._key);
    setCheckerName(value)
  };

  const handleDatePickerChange = (dates) => {
    if (typeof dates === 'undefined') {
      dates = ['', ''];
    }
    if (dates[0] === '') {
      dates[0] = 0
    } else {
      const StartDate = new Date(dates[0])
      dates[0] = StartDate.getTime()
    }
    if (dates[1] === '') {
      dates[1] = 0
    } else {
      const endDate = new Date(dates[1])
      endDate.setHours(23, 59, 59, 999)
      dates[1] = endDate.getTime()
    }
    let formattedDates = `1#${dates[0]}#${dates[1]}`
    setFormatTime(formattedDates)
    setSelectedDates(dates)
  }
  const handleTextAreaChange = (event) => {
    let formatPlace = `1#${event}`
    setSelectedPlace(event)
    setFormatPlace(formatPlace)
  }

  const handleTaskWorkOptionChange = (value, selectedOptions) => {
    if (typeof selectedOptions === 'undefined' || typeof value === 'null') {
      setSelectedTaskWorkOption(null)
      return
    }
    const labels = selectedOptions.map(option => option.label);
    setSelectedTaskWorkOption(labels)
  }
  const handleInputChange = (event) => {
    const value = event
    setCreateTaskName(value);

  }
  const handleDesClick = (event) => {
    const value = event
    setTaskDescribe(value)
  }
  const checkStandardClick = (event) => {
    const value = event
    setCheckStandard(value)
  }
  const skillAttributeClick = (event) => {
    const value = event
    setSkillAttribute(value)
  }
  const resAttributeClick = (event) => {
    const value = event
    setResAttribute(value)
  }
  const expectedTimeClick = (event) => {
    const value = event
    setExpectedTime(value)
  }
  const deliverLasttimeClick = (dateString, date) => {
    const timestamp = new Date(date).getTime()
    setDeliverLasttime(timestamp)
  }
  const workTypeClick = (value) => {
    const WorkType = value
    setWorkType(WorkType)
  }
  const valueTypeClick = (value) => {
    const ValueType = value
    setValueType(ValueType)
  }
  const handleCreateClick = () => {
    const taskName = createTaskName;//任务名称
    const taskDes = taskDescribe;//任务描述
    const taskValueType = valueType;//任务价值分类
    const taskWorkType = workType;//任务开展方式
    const taskExecuteTimeStandard = formatTime;//任务执行时间规范   1#
    const taskExecuteAddressStandard = formatPlace;//任务执行地点规范
    const taskExecuteOtherStandard = null;//任务其他执行规范
    const taskCheckStandard = checkStandard;//任务验收标准
    const taskExpectedTime = expectedTime;//任务预计耗时
    const taskDeliverLasttime = deliverLasttime;//任务最晚交付时间
    const taskSkillAttribute = skillAttribute;//任务技能要求
    const taskResAttribute = resAttribute;//任务资源要求配置
    const taskChecker = checkerName;//任务验收人
    const taskCheckerId = checkerId//任务验收id
    let taskWork = null;
    if (selectedTaskWorkOption != null) {
      taskWork = selectedTaskWorkOption[0]; //任务所属事务和项目分类
    }
    let taskFlowWork = null;
    let taskNodeWork = null;
    if (selectedTaskWorkOption != null) {
      taskFlowWork = selectedTaskWorkOption[1];//任务所属具体项目
      taskNodeWork = selectedTaskWorkOption[2];//任务所属项目分组
    }
    const taskExecuter = executer//执行人
    const taskExecuterId = unionId//执行人id
    const taskApproverId = approvalId;//审批人id
    const taskApproverName = approvalName;//任务审批人
    const taskWorkHoursType = workHoursType;
    const taskStandardPay = null; // 是否按照标准计价
    form.validate().then((values) => {
      createTask(token, taskName, taskDes, taskValueType, taskWorkType, taskExecuteTimeStandard,
        taskExecuteAddressStandard, taskExecuteOtherStandard, taskCheckStandard, taskExpectedTime,
        taskDeliverLasttime, taskSkillAttribute, taskResAttribute, taskChecker,
        taskCheckerId, taskFlowWork, taskNodeWork, taskExecuter, taskExecuterId, taskApproverId, taskApproverName,
        taskWork, taskStandardPay, taskWorkHoursType)
        .then(response => {
          if (response.retCode === '000000') {
            Message.success({
              id: "handleCreateClick",
              icon: <IconFont type='icon-success' />,
              content: `${taskName} 任务创建成功!`,
            });
            onClose('task')
          } else {
            Message.error({
              icon: <IconFont type='icon-error' />,
              content: `${response.retMsg}任务创建失败，请重试。`,
            })
          }
        })
    }).catch((error) => {
      console.log(error.name,'8')
      console.log(error.message)
      console.log(error.errors)
    })
  }
  const handleSaveClick = () => {
    let taskDrafts = new TaskDrafts()
    let taskInfo = {}
    taskInfo.taskName = createTaskName;
    taskInfo.taskDes = taskDescribe;
    taskInfo.taskValueType = valueType;
    taskInfo.taskWorkType = workType;
    taskInfo.taskExecuteTimeStandard = formatTime;
    taskInfo.taskExecuteAddressStandard = formatPlace;
    taskInfo.taskExecuteOtherStandard = null;
    taskInfo.taskCheckStandard = checkStandard;
    taskInfo.taskExpectedTime = expectedTime;
    taskInfo.taskDeliverLasttime = deliverLasttime;
    taskInfo.taskSkillAttribute = skillAttribute;
    taskInfo.taskResAttribute = resAttribute;
    taskInfo.taskChecker = checkerName;
    taskInfo.taskCheckerId = checkerId;
    taskInfo.taskFlowWork = null
    taskInfo.taskNodeWork = null
    taskInfo.taskWork = null
    if (selectedTaskWorkOption != null) {
      taskInfo.taskWork = selectedTaskWorkOption[0];
      taskInfo.taskFlowWork = selectedTaskWorkOption[1];
      taskInfo.taskNodeWork = selectedTaskWorkOption[2];
      taskInfo.taskProjectId = projectKey;

    }
    taskInfo.taskExecuter = executer;
    taskInfo.taskExecuterId = unionId;
    taskInfo.taskApproverId = approvalId;
    taskInfo.taskApproverName = approvalName;
    taskInfo.taskWorkhoursType = workHoursType;
    taskInfo.taskStandardPay = null;

    taskDrafts.taskDraftsContent = JSON.stringify(taskInfo);
    addDraft(taskDrafts, function (data) {
      Message.success({
        icon: <IconFont type='icon-success' />,
        content: `草稿创建成功!`,
      });
      onClose('drafts')
    })
  }

  return (
    <div>
      <Modal
        className='createTaskModal'
        style={{ cursor: 'move' }}
        title='创建任务'
        onCancel={() => onClose()}
        visible={visible}
        autoFocus={false}
        focusLock={true}
        onMouseOver={() => {
          disabled && setDisabled(false);
        }}
        onMouseOut={() => {
          !disabled && setDisabled(true);
        }}
        modalRender={(modal) => <Draggable disabled={disabled}>{modal}</Draggable>}
      >
        <Form style={{ width: 1000 }} autoComplete='off' form={form}>
          <FormItem
            label='任务分类'
            field='任务分类'
            rules={[
              {
                type: 'array',
                required: true,
              }
            ]}
          >
            <Cascader
              showSearch
              placeholder='请输入'
              allowClear
              onChange={handleTaskWorkOptionChange}
              options={options}
              loadMore={loadMore}
            />
          </FormItem>
          <FormItem
            label='任务名称'
            field="任务名称"
            rules={[
              {
                required: true
              },
              {
                validator: (value, cb) => {
                  if (typeof value !== 'string') {
                    value = String(value)
                  }
                  if (value.includes('#')) {
                    return cb('输入内容不能包含特殊字符 #')
                  }
                  return cb()
                },
              },
            ]}
          >
            <Input
              placeholder='请输入'
              onChange={handleInputChange}
            />
          </FormItem>
          <FormItem label='任务描述' field="任务描述" rules={[{ required: true }]} 
            className="fullWidthFormItem">
            <TextArea placeholder='请输入' className="taskDescribeInput" onChange={handleDesClick}
              style={{ minHeight: 150, maxWidth: 600, borderRadius: 8 }} />
          </FormItem>
          <FormItem label='执行人' field='执行人' rules={[{ required: true }]} >
            <Select
              placeholder='请输入'
              onClick={handleExecuteClick}
              onChange={handleExecuteChange}
              showSearch={{
                retainInputValue: true,
              }}
              allowClear
            >
              {queryAllStaffOptions.map(option => (
                <Option key={option.id} value={option.value}>
                  <Avatar size={24}>
                    <img
                      alt='avatar'
                      src={option.avatar_url} style={{ marginRight: 8 }}
                    />
                  </Avatar>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label='审批人' field='审批人' rules={[{ required: true }]} >
            <Select
              placeholder='请输入'
              onClick={handleApprovalClick}
              onChange={handleApprovalChange}
              allowClear
            >
              {approvalOptions.map((option) => (
                <Option key={option.union_Id} value={option.name}>
                  <Avatar size={24}>
                    <img
                      alt='avatar'
                      src={option.avatar_url} style={{ marginRight: 8 }}
                    />
                  </Avatar>
                  {option.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label='验收人' field='验收人' rules={[{ required: true }]} >
            <Select
              placeholder='请输入'
              onClick={handleCheckerClick}
              onChange={handleCheckerChange}
              allowClear
            >
              {checkerOptions.map((option) => (
                <Option key={option.union_Id} value={option.name}>
                  <Avatar size={24}>
                    <img
                      alt='avatar'
                      src={option.avatar_url} style={{ marginRight: 8 }}
                    />
                  </Avatar>
                  {option.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label='验收标准' field='验收标准'>
            <TextArea placeholder='请输入' style={{ minHeight: 64, width: 400 }}
              onChange={checkStandardClick} />
          </FormItem>
          <FormItem label='价值类型' field='价值类型' rules={[{ required: true }]} >
            <Select
              placeholder='请输入'
              options={[
                {
                  label: '基础型',
                  value: 0,
                },
                {
                  label: '灵活型',
                  value: 1,
                },
                {
                  label: '创新型',
                  value: 2,
                },
                {
                  label: '复合型',
                  value: 3,
                },
                {
                  label: '学习型',
                  value: 4,
                },
                {
                  label: '其他',
                  value: 5,
                },
              ]}
              allowClear
              onChange={valueTypeClick}
            />
          </FormItem>
          <FormItem label='开展方式' field='开展方式' rules={[{ required: true }]} >
            <Select
              placeholder='请输入'
              options={[
                {
                  label: '会议',
                  value: 0,
                },
                {
                  label: '独立执行',
                  value: 1,
                }
              ]}
              onChange={workTypeClick}
              allowClear
            />
          </FormItem>
          <FormItem label='所需技能' field='所需技能'>
            <TextArea placeholder='请输入' style={{ minHeight: 64, width: 400 }}
              onChange={skillAttributeClick} />
          </FormItem>
          <FormItem label='所需资源' field='所需资源'>
            <TextArea placeholder='请输入' style={{ minHeight: 64, width: 400 }}
              onChange={resAttributeClick} />
          </FormItem>
          <FormItem label='预计耗时' field="预计耗时" rules={[{ required: true }]} >
            <InputNumber
              style={{ width: 400, height: 40 }}
              min={0} placeholder='请输入' suffix="小时"
              onChange={expectedTimeClick}
            />
          </FormItem>
          <FormItem label='截止时间' field='截止时间' rules={[{ required: true }]} >
            <DatePicker
              style={{ width: 400, height: 40, margin: '0 24px 24px 0' }}
              showTime={{
                defaultValue: '23:59:59',
              }}
              format='YYYY/MM/DD HH:mm:ss'
              position="bottom"
              onChange={deliverLasttimeClick}
            />
          </FormItem>
          <FormItem label="时间要求" field="时间要求" rules={[{ required: true }]} >
            <RadioGroup value={timeRequirement}
              className='taskExecuteTimeStandard'
              onChange={(value) => handleTimeRequirementChange(value)}
              defaultValue='0'
            >
              <Radio value="0">不限</Radio>
              <Radio value="1">固定时间</Radio>
            </RadioGroup>
            {timeRequirement === '1' && (
              <FormItem label="时间要求" field="时间要求" rules={[{ required: true }]}>
                <DatePicker.RangePicker
                  value={selectedDates}
                  showTime={{
                    defaultValue: ['00:00:00', '23:59:59'],
                    format: 'HH:mm:ss',
                  }}
                  style={{ width: 400, height: 40, margin: '0 24px 24px 0' }}
                  onChange={handleDatePickerChange}
                />
              </FormItem>
            )}
          </FormItem>
          <FormItem label="地点要求" field="地点要求" rules={[{ required: true }]} >
            <RadioGroup
              className='taskExecuteAddressStandard'
              value={placeRequirement}
              onChange={(value) => handlePlaceRequirementChange(value)}
              defaultValue="0"
            >
              <Radio value="0">不限</Radio>
              <Radio value="1">固定地点</Radio>
            </RadioGroup>
            {placeRequirement === '1' && (
              <FormItem label="地点要求" field="地点要求" rules={[{ required: true }]}>
                <TextArea
                  placeholder="请输入"
                  style={{ minHeight: 64, width: 350 }}
                  onChange={handleTextAreaChange}
                  value={selectedPlace}
                />
              </FormItem>
            )}
          </FormItem>
          <FormItem className="submit">
            <Button onClick={handleCreateClick} type='primary' htmlType='submit'>
              提交创建
            </Button>
            <Button onClick={handleSaveClick}>
              存草稿箱
            </Button>
          </FormItem>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateModal;
/* eslint-enable */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './index.scss'
import { DatePicker, Button, Select, Input, Table, Cascader } from '@arco-design/web-react'
import read from '../../../.././../assets/image/read.svg'
import unread from '../../../.././../assets/image/unread.svg'
import { fetchMyApprove, fetchSecondTask, fetchThirdTask } from '../../../../../api/getExport'
import TaskDetailModal from '../../../../task_detail_model'
import { dataChangeMap } from '../../../../../config/dataChange'
import moment from "moment"
const optionsList = [
    {
        value: 0,
        label: '项目',
        isLeaf: false,
    },
    {
        value: 1,
        label: '事务',
        isLeaf: false,
    },
    {
        value: 2,
        label: '业务',
        isLeaf: false,
    },
    {
        value: 3,
        label: '事项',
        isLeaf: false,
    },
    {
        value: 4,
        label: '其他',
        isLeaf: false,
    }
]

const MyApprove = ({ token, userID, change, notifyRefresh }) => {
    const [activeButton, setActiveButton] = useState('pending')
    const [timeRangeTwo, setTimeRangeTwo] = useState('')
    const [taskApproveResultValueApprove, setTaskApproveResultValueApprove] = useState(-1)
    const [taskApplyTypeValue, setTaskApplyTypeValue] = useState('')
    const [taskValueTypeApprove, setTaskValueTypeApprove] = useState('')
    const [taskNameTwo, setTaskNameTwo] = useState('')
    const [taskStateValue, setTaskStateValue] = useState('')
    const [applyName, setApplyName] = useState('')
    const [taskClassify, setTaskClassify] = useState('')
    const [options, setOptions] = useState(optionsList)
    const [projectKey, setProjectKey] = useState(null)
    const [pageTwoData, setPageTwoData] = useState([])
    const [tableTwoParams, setTableTwoParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        }
    })
    const [waitApproveCount, setWaitApproveCount] = useState(0)

    const reset = () => {
        setTimeRangeTwo(null)
        setTaskStateValue('')
        setTaskApproveResultValueApprove('')
        setTaskValueTypeApprove('')
        setTaskApplyTypeValue('')
        setTaskNameTwo('')
        setApplyName('')
        setTaskClassify('')
    }
    const handleButtonClick = (Button) => {
        setTaskApproveResultValueApprove(-1)
        setTimeRangeTwo(null)
        setTaskStateValue('')
        setTaskValueTypeApprove('')
        setTaskApplyTypeValue('')
        setTaskNameTwo('')
        setApplyName('')
        setTaskClassify('')
        setActiveButton(Button)
    }
    const handleDateRangeChange = (dates) => {
        if (typeof (dates) == "undefined") {
            setTimeRangeTwo(null)
        } else {
            const StartDate = new Date(dates[0])
            dates[0] = StartDate.getTime()
            const endDate = new Date(dates[1])
            endDate.setHours(23, 59, 59, 999) // 设置小时、分钟、秒和毫秒
            dates[1] = endDate.getTime()
            setTimeRangeTwo(dates)
            localStorage.setItem('timeRange', dates)
        }
    }
    const handleTaskNameChange = (event) => {
        setTaskNameTwo(event)
        localStorage.setItem('taskName', event)
    }
    const handleApplyNameChange = (event) => {
        setApplyName(event)
        localStorage.setItem('applyName', event)
    }
    const handleChange = (value, name) => {
        if (typeof value === 'undefined') {
            value = ''
        }
        switch (name) {
            case 'taskApproveResult':
                setTaskApproveResultValueApprove(value)
                localStorage.setItem('taskApproveResult', value)
                break
            case 'taskApplyType':
                setTaskApplyTypeValue(value)
                localStorage.setItem('taskApplyType', value)
                break
            case 'taskValueType':
                setTaskValueTypeApprove(value)
                localStorage.setItem('taskValueType', value)
                break
            case 'taskState':
                setTaskStateValue(value)
                localStorage.setItem('taskState', value)
                break
            default:
                break
        }
    }
    const loadMore = async (pathValue, level) => {
        try {
            if (level === 1) {
                const data = await fetchSecondTask(token, pathValue);
                pathValue = data.data;
            } else if (level === 2) {
                const values = pathValue.toString();
                const value = values.split(',')[1];
                const data = await fetchThirdTask(token, value);
                const ProjectKey = data.data[2].taskWorkProject;
                setProjectKey(ProjectKey);
                pathValue = data.data;
            }

            const nodes = pathValue.map((x, i) => ({
                label: level === 1 ? `${pathValue[i].projectName}` : `${pathValue[i].taskWorkName}`,
                value: `${pathValue[i].id}`,
                isLeaf: level >= 2,
            }));

            return nodes;
        } catch (error) {
            console.log("请求失败:", error);
            return [];
        }
    };
    const handleTaskWorkOptionChange = (value, selectedOptions) => {
        if (typeof selectedOptions === 'undefined' || typeof value === 'null') {
            setSelectedTaskWorkOption(null)
            return
        }
        const labels = selectedOptions.map(option => option.label);
        const encodedTaskClassify = encodeURIComponent(labels[1] + '#' + labels[2])
        setTaskClassify(encodedTaskClassify)
    }
    const pageApprove = tableTwoParams.pagination.current
    const taskName = taskNameTwo;
    const taskApproveBeginTime = timeRangeTwo ? timeRangeTwo[0] : ''
    const taskApproveEndTime = timeRangeTwo ? timeRangeTwo[1] : ''
    const approveResult = taskApproveResultValueApprove
    const taskApplyType = taskApplyTypeValue
    const taskState = taskStateValue
    const taskValueType = taskValueTypeApprove
    const taskApplyName = applyName
    const taskApproveType = taskApplyTypeValue
    // 第二个表格的数据获取
    const fetchMyApproveData = (num) => {
        fetchMyApprove(token, taskName, approveResult, taskApplyType, taskApproveBeginTime, taskApproveEndTime, 10, pageApprove, taskState, taskValueType, taskApplyName, taskClassify)
            .then(data => {
                if (data && data['data'] && data['data'].list) {
                    // if (num != null && num == 0) {
                    //     setWaitApproveCount(data['data'].totalCount)
                    // }
                    setPageTwoData(data['data'].list)
                    setTableTwoParams(prevParams => ({
                        ...prevParams,
                        pagination: {
                            ...prevParams.pagination,
                            total: data['data'].totalCount
                        }
                    }))
                } else {
                    // if (num != null && num == 0) {
                    //     setWaitApproveCount(0)
                    // }
                    setPageTwoData([])
                    setTableTwoParams(prevParams => ({
                        ...prevParams,
                        pagination: {
                            ...prevParams.pagination,
                            total: 0
                        }
                    }))
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error)
            })
        if (num != null && num == 0) {
            return
        }
        fetchMyApprove(token, taskName, -1, taskApplyType, taskApproveBeginTime, taskApproveEndTime, 10, pageApprove, taskState, taskValueType, taskApplyName, taskClassify)
            .then(data => {
                if (data && data['data'] && data['data'].list) {
                    setWaitApproveCount(data['data'].totalCount)
                } else {
                    setWaitApproveCount(0)
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error)
            })
    }
    useEffect(() => {
        fetchMyApproveData()
    }, [token, taskName, approveResult, taskApplyType, taskApproveBeginTime, taskApproveEndTime, pageApprove, taskState, taskValueType, taskApplyName, taskClassify, change])

    const handleTableTwoChange = (pagination) => {
        if (pagination.pageSize !== tableTwoParams.pagination?.pageSize) {
            setTableTwoParams({
                pagination: {
                    current: 1,
                    pageSize: pagination.pageSize,
                    total: tableTwoParams.pagination.total
                }
            })
        } else {
            setTableTwoParams({
                pagination
            })
        }
    }

    const columns = [
        {
            title: '读取状态',
            width: 100,
            dataIndex: 'isTaskOpenIdView',
            key: 'name',
            fixed: 'left',
            render: (value) => (Number(value) === 0 ?
                <img src={unread} className='unread' alt="" style={{ width: 25 }} /> :
                <img src={read} className='read' alt="" style={{ width: 25 }} />)
        },
        {
            title: '更新时间',
            width: 110,
            dataIndex: 'taskApproveTime',
            key: '00',
            sorter: (a, b) => a.taskApproveTime - b.taskApproveTime,
            render: (value) => {
                if (value === null) {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '任务分类',
            width: 100,
            dataIndex: 'taskKey',
            key: '01',
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                }
                const parts = value.split('#');
                if (parts.length >= 2) {
                    return `${parts[0]}#${parts[1]}`;
                } else {
                    return value;
                }
            }
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: '02',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '价值类型',
            dataIndex: 'taskValueType',
            key: '03',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return dataChangeMap.taskValueType[value]
                }
            }
        },
        {
            title: '开展方式',
            dataIndex: 'taskWorkType',
            key: '04',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return dataChangeMap.taskWorkType[value]
                }
            }
        },
        {
            title: '预计耗时',
            dataIndex: 'taskExpectedTime',
            key: '05',
            width: 120,
            sorter: (a, b) => a.taskExpectedTime - b.taskExpectedTime,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return `${value}小时`;
                }
            }
        },
        {
            title: '截止时间',
            dataIndex: 'taskDeliverLasttime',
            key: '06',
            width: 110,
            sorter: (a, b) => a.taskDeliverLasttime - b.taskDeliverLasttime,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '执行人',
            dataIndex: 'taskExecuter',
            key: '07',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '验收人',
            dataIndex: 'taskChecker',
            key: '08',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '任务状态',
            dataIndex: 'taskState',
            key: '09',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—'
                } else {
                    return dataChangeMap.taskState[value]
                }
            }
        },
        {
            title: '认定工时',
            dataIndex: 'taskRealTime',
            key: '10',
            width: 100,
            render: (value) => {
                if (value === null || value.trim()==='') {
                    return '—';
                } else {
                    return `${value}小时`;
                }
            },
        },
        {
            title: '验收结果',
            dataIndex: 'taskApproveResult',
            key: '11',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.approveResult[value]
                }
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (col, record, index) => <TaskDetailModal taskKey={record.taskKey} 
                    token={token} userID={userID} showCopy={true} notifyRefresh={notifyRefresh} 
                    copyRecord={record}/>
        }
    ]
    const columnsPending = [
        {
            title: '申请时间',
            width: 95,
            dataIndex: 'taskApproveTime',
            key: '000',
            sorter: (a, b) => a.taskApproveTime - b.taskApproveTime,
            render: (value) => moment(value).format('YYYY/MM/DD HH:mm:ss')
        },
        {
            title: '申请类型',
            width: 100,
            dataIndex: 'taskApproveType',
            key: '001',
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return dataChangeMap.taskApproveType[value]
                }
            }
        },
        {
            title: '申请人',
            dataIndex: 'taskCreator',
            key: '002',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return value
                }
            }
        },
        {
            title: '任务分类',
            width: 100,
            dataIndex: 'taskKey',
            key: '003',
            render: (value) => {
                if (value === null) {
                    return '—'
                }
                const parts = value.split('#');
                if (parts.length >= 2) {
                    return `${parts[0]}#${parts[1]}`;
                } else {
                    return value;
                }
            }
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: '004',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return value
                }
            }
        },
        {
            title: '价值类型',
            dataIndex: 'taskValueType',
            key: '005',
            width: 100,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return dataChangeMap.taskValueType[value]
                }
            }
        },
        {
            title: '开展方式',
            dataIndex: 'taskWorkType',
            key: '006',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskWorkType[value]
                }
            }
        },
        {
            title: '预计耗时',
            dataIndex: 'taskExpectedTime',
            key: '007',
            width: 120,
            sorter: (a, b) => a.taskExpectedTime - b.taskExpectedTime,
            render: (value) => {
                if (value === null || value.trim() === "") {
                    return '—'; // 如果值为null或空字符串，则显示破折号
                } else {
                    return `${value}小时`;
                }
            }
        },
        {
            title: '截止时间',
            dataIndex: 'taskDeliverLasttime',
            key: '008',
            width: 100,
            sorter: (a, b) => a.taskDeliverLasttime - b.taskDeliverLasttime,
            render: (value) => {
                if (value === null) {
                    return '—'
                } else {
                    return moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
            }
        },
        {
            title: '执行人',
            dataIndex: 'taskExecuter',
            key: '009',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return value
                }
            }
        },
        {
            title: '任务状态',
            dataIndex: 'taskState',
            key: '0010',
            width: 100,
            render: (value) => {
                if (value === null || value.trim() === '') {
                    return '—'
                } else {
                    return dataChangeMap.taskState[value]
                }
            }
        },
        {
            title: '操作',
            key: 'operation2',
            fixed: 'right',
            width: 150,
            render: (col, record, index) => <TaskDetailModal taskKey={record.taskKey} 
                token={token} userID={userID} showCopy={false} notifyRefresh={notifyRefresh} />
        }
    ]

    return (
        <div className='page2'>
            <div className="switchAngelView">
                <div className="switch">
                    <Button
                        className={`switch-button ${activeButton === 'all' ? 'active' : ''}`}
                        onClick={() => (handleButtonClick('all'), setTaskApproveResultValueApprove(''))}
                    >
                        全部任务
                    </Button>
                    <Button
                        className={`switch-button ${activeButton === 'pending' ? 'active' : ''}`}
                        onClick={() => (handleButtonClick('pending'), setTaskApproveResultValueApprove(-1))}
                    >
                        待审批({waitApproveCount})
                    </Button>
                </div>
            </div>
            <div className='page2-header'>
                {/* 截止时间 */}
                {activeButton === 'all' && (
                    <div className="handleTime">
                        <span className='handleTimeTip'>截止时间</span>
                        <DatePicker.RangePicker
                            value={timeRangeTwo ? [new Date(timeRangeTwo[0]), new Date(timeRangeTwo[1])] : null}
                            status='warning'
                            placeholder='warning status'
                            style={{ width: 300, height: 30, backgroundColor: 'rgba(242,243,245,1)' }}
                            onChange={handleDateRangeChange}
                        />
                    </div>
                )}

                {/* 任务状态 */}
                {activeButton === 'all' && (
                    <div className="taskState">
                        <span className='state'>任务状态</span>
                        <Select
                            value={taskState}
                            defaultValue="2"
                            style={{
                                width: 120,
                            }}
                            allowClear
                            onChange={(value) => handleChange(value, 'taskState')}
                            options={[
                                {
                                    value: '0',
                                    label: '待创建',
                                },
                                {
                                    value: '1',
                                    label: '待交付',
                                },
                                {
                                    value: '2',
                                    label: '待验收',
                                },
                                {
                                    value: '3',
                                    label: '已完成',
                                },
                                {
                                    value: '4',
                                    label: '未完成',
                                },
                                {
                                    value: '5',
                                    label: '已取消',
                                }
                            ]}
                        />
                    </div>
                )}
                {/* 任务分类*/}
                {(activeButton === 'all' || activeButton === 'pending') && (
                    <div className="taskClassify">
                        <span className='classify'>任务分类</span>
                        <Cascader
                            style={{
                                width: 200,
                            }}
                            showSearch
                            placeholder='请输入'
                            allowClear
                            onChange={handleTaskWorkOptionChange}
                            options={options}
                            loadMore={loadMore}
                        />
                    </div>
                )}

                {/* 验收结果 */}
                {activeButton === 'all' && (
                    <div className="taskApproveResult">
                        <span className='type'>验收结果</span>
                        <Select
                            defaultValue=""
                            allowClear
                            value={taskApproveResultValueApprove}
                            style={{
                                width: 120,
                            }}
                            onChange={(value) => handleChange(value, 'taskApproveResult')}
                            options={[
                                {
                                    value: '0',
                                    label: '暂无',
                                },
                                {
                                    value: '1',
                                    label: '不达标',
                                },
                                {
                                    value: '2',
                                    label: '达标',
                                },
                                {
                                    value: '3',
                                    label: '需返工',
                                },
                                {
                                    value: '4',
                                    label: '差',
                                }
                            ]}
                        >
                        </Select>
                    </div>
                )}

                {/* 任务价值类型 */}
                {(activeButton === 'all' || activeButton === 'pending') && (
                    <div className="taskValueType">
                        <span className='valueType'>价值类型</span>
                        <Select
                            value={taskValueTypeApprove}
                            defaultValue=""
                            style={{
                                width: 120,
                            }}
                            allowClear
                            onChange={(value) => handleChange(value, 'taskValueType')}
                            options={[
                                {
                                    value: '0',
                                    label: '基础型',
                                },
                                {
                                    value: '1',
                                    label: '灵活型',
                                },
                                {
                                    value: '2',
                                    label: '创新型',
                                },
                                {
                                    value: '3',
                                    label: '复合型',
                                },
                                {
                                    value: '4',
                                    label: '学习型',
                                },
                                {
                                    value: '5',
                                    label: '其他类型',
                                }
                            ]}
                        />
                    </div>
                )}

                {/* 任务申请类型 */}
                {activeButton === 'pending' && (
                    <div className="taskApplyType">
                        <span className='type'>申请类型</span>
                        <Select
                            value={taskApplyTypeValue}
                            defaultValue="0"
                            allowClear
                            style={{
                                width: 120,
                            }}
                            options={[
                                {
                                    value: '0',
                                    label: '任务创建'
                                },
                                {
                                    value: '1',
                                    label: '任务修改'
                                },
                                {
                                    value: '3',
                                    label: '任务重启'
                                }
                            ]}
                            onChange={(value) => handleChange(value, 'taskApplyType')}
                        >
                        </Select>
                    </div>
                )}

                {/* 申请人姓名*/}
                {activeButton === 'pending' && (
                    <div className="taskApplyName">
                        <span className='name'>申请人</span>
                        <Input
                            value={applyName}
                            style={{ width: 150 }}
                            allowClear
                            placeholder='请输入'
                            onChange={handleApplyNameChange}
                        />
                    </div>
                )}

                {/* 任务名称 */}
                {(activeButton === 'all' || activeButton === 'pending') && (
                    <div className="taskName">
                        <span className='name'>任务名称</span>
                        <Input
                            value={taskNameTwo}
                            style={{ width: 150 }}
                            allowClear
                            placeholder='请输入'
                            onChange={handleTaskNameChange} // 绑定处理器到输入框
                        />
                    </div>
                )}

                <div className="reset">
                    <button className='btn-reset' onClick={reset}><span>重置</span></button>
                </div>
            </div>
            <div className="page2-wrap">
                {activeButton === 'all' && (
                    <Table
                        borderCell
                        columns={columns}
                        data={pageTwoData}
                        pagination={tableTwoParams.pagination}
                        scroll={{
                            x: 1500,
                        }}
                        onChange={handleTableTwoChange}
                    />
                )}
                {activeButton === 'pending' && (
                    <Table
                        borderCell
                        columns={columnsPending}
                        data={pageTwoData}
                        pagination={tableTwoParams.pagination}
                        scroll={{
                            x: 1500,
                        }}
                        onChange={handleTableTwoChange}
                    />
                )}
            </div>
        </div>
    )
}

export default MyApprove
/* eslint-enable */

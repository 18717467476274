import {httpGetCall, httpPostCall} from "../io/HttpRequestUtil";

//添加草稿
export const TaskDrafts = class TaskDrafts {
    constructor() {
        this.taskDraftsKey = null;
        this.id = null;
        this.taskDraftsContent = null;
        this.taskDraftsName = null;
        this.taskDraftsType = '0'//草稿类型 0 任务 1.其他
    }
}

//添加草稿例子
//let taskDrafts = new TaskDrafts();
//taskDrafts.taskDraftsContent = "test";
//taskDrafts.taskDraftsName = "test";
//addDraft(taskDrafts, function (data) {
//    console.log(data);
//});
export function addDraft(taskDrafts, onSuccess) {
    httpPostCall("drafts/addDraft", taskDrafts, onSuccess);
}

//修改草稿例子
//let taskDrafts = new TaskDrafts();
//taskDrafts.id = "1";
//taskDrafts.taskDraftsContent = "test";
//taskDrafts.taskDraftsName = "test";
//updateDraft(taskDrafts, function (data) {
//    console.log(data);
//});
export function updateDraft(taskDrafts, onSuccess) {
    httpPostCall("drafts/updateDraft", taskDrafts, onSuccess);
}


//删除草稿
//deleteDraft("1", function (data) {
//    console.log(data);
//});
export function deleteDraft(draftKey, onSuccess) {
    httpGetCall("drafts/deleteDraft", draftKey, onSuccess);
}

//查看草稿
export const DraftsPara = class DraftsPara {
    constructor() {
        this.taskName = null;
        this.taskValueType = null;
        this.taskExecuter = null;
        this.limit = 10
        this.page = 1;
    }
}

//查看草稿例子
//let draftsPara = new DraftsPara();
//draftsPara.taskName = "test";
//draftsPara.taskValueType = "test";
//draftsPara.taskExecuter = "test";
//queryTaskListByDraft(draftsPara, function (data) {
//    console.log(data);
//});
export function queryTaskListByDraft(draftsPara, onSuccess) {
    httpGetCall("drafts/queryTaskListByDraft", draftsPara, onSuccess);
}

//批量删除草稿
//let draftsKeys = ["1", "2"];
//deleteDrafts(draftsKeys, function (data) {
//    console.log(data);
//});


export function deleteDrafts(draftsKeys, onSuccess) {
    httpPostCall("drafts/deleteDrafts", draftsKeys, onSuccess);
}

//全部删除草稿
//deleteAllDrafts(function (data) {
//    console.log(data);
//});
export function deleteAllDrafts(onSuccess) {
    httpGetCall("drafts/deleteAllDrafts", null, onSuccess);
}


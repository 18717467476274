/* eslint-disable */
import {dataChangeMap, taskWordToNum} from "../../config/dataChange";
import { 
    Steps, Timeline, Drawer, Button,Typography
} from '@arco-design/web-react'
import React, { useState } from 'react'
import './index.scss'

const TaskStateModule = ({record, taskFlowInfo, taskStateTimeline}) => {
    const [timelineVisible, setTimelineVisible] = useState(false)
    const leftTime = ()=>{
        var cur_time = new Date().getTime()
        var left_times_second = parseInt((record.taskDeliverLasttime - cur_time)/1000)
        left_times_second = left_times_second > 0 ? left_times_second : 0
        var days = parseInt(left_times_second/(24*60*60))
        var hours = parseInt((left_times_second-days*24*60*60)/(60*60))
        var minutes = parseInt((left_times_second-days*24*60*60-hours*60*60)/(60))
        var seconds = parseInt(left_times_second-days*24*60*60-hours*60*60-minutes*60)
        return <div className="status">
            剩余时间:&nbsp;<span>{days}&nbsp;天{hours}&nbsp;时{minutes}&nbsp;分{seconds}&nbsp;秒</span></div>
    }
    const checkResult=()=>{
        return <>
                <div className="status" >验收结果:&nbsp;
                        {dataChangeMap.taskResult[record.taskCheckResult]}</div>
                <div className="status" >认定工时:&nbsp;
                        {record.taskRealTime}小时 &nbsp; (预计{record.taskExpectedTime}小时)</div>
            </>
    }
    const cancelReason=()=>{
        return <div className="status" >
                取消原因:&nbsp; {taskFlowInfo["cancelReason"]}
            </div>
    }

    const taskSteps=()=>{
        if (record.taskState == taskWordToNum.taskState["cancel"]){
            if(taskFlowInfo.lastTaskState == taskWordToNum.taskState["creating"]){
                return <Steps current={2} status='error' labelPlacement='vertical'
                    style={{maxWidth:'50%', margin:'4% 0 5% 0' }}>
                    <Steps.Step title={`创建: ${record.taskCreator}`} description="终止" />
                    <Steps.Step title={`任务取消: ${record.taskApproverName}`} description={taskFlowInfo["cancelDes"]} />
                </Steps>
            } else{
                return <Steps current={3} status='error' labelPlacement='vertical'
                    style={{maxWidth:'70%', margin:'4% 0 5% 0' }}>
                    <Steps.Step title={`创建: ${record.taskCreator}`} description={taskFlowInfo["createDes"]} />
                    <Steps.Step title={`执行: ${record.taskExecuter}`} description="终止" />
                    <Steps.Step title={`任务取消: ${record.taskApproverName}`} description={taskFlowInfo["cancelDes"]} />
                </Steps>
            }
        }else if(record.taskState == taskWordToNum.taskState["fail"]){
            return <Steps current={4} labelPlacement='vertical'
                style={{maxWidth:'90%', margin:'4% 0 5% 0' }}>
                <Steps.Step title={`创建: ${record.taskCreator}`} description={taskFlowInfo["createDes"]} />
                <Steps.Step title={`执行: ${record.taskExecuter}`} description={taskFlowInfo["executeDes"]} />
                <Steps.Step title={`验收: ${record.taskChecker}`} description={taskFlowInfo["checkDes"]} />
                <Steps.Step status='error' title="任务未完成" description={taskFlowInfo["notcompleteDes"]}/>
            </Steps>
       }else{
        return <Steps current={parseInt(record.taskState)+1} labelPlacement='vertical'
            style={{maxWidth:'90%', margin:'4% 0 5% 0' }}>
            <Steps.Step title={`创建: ${record.taskCreator}`} description={taskFlowInfo["createDes"]} />
            <Steps.Step title={`执行: ${record.taskExecuter}`} description={taskFlowInfo["executeDes"]} />
            <Steps.Step title={`验收: ${record.taskChecker}`} description={taskFlowInfo["checkDes"]} />
            <Steps.Step title="任务已完成" description={taskFlowInfo["completeDes"]} />
        </Steps>
        }
    }

    return (
    <div className="detail-header">
        <div className="task-status">
            <div className='status'>任务状态:&nbsp;
                <span style={{color:'red'}}>{dataChangeMap.taskState[record.taskState]}</span>
            </div>
            { record.taskState==taskWordToNum.taskState["cancel"] && cancelReason()}
            {(record.taskState==taskWordToNum.taskState["submitting"]||
                record.taskState==taskWordToNum.taskState["creating"] ||
                record.taskState==taskWordToNum.taskState["checking"]) && leftTime()}
            {(record.taskState==taskWordToNum.taskState["done"]||
                record.taskState==taskWordToNum.taskState["fail"]) && checkResult()}
            <Button onClick={() => setTimelineVisible(true)} className="dialog">
                状态流转日志
            </Button>                 
            <Drawer
                width={350}
                title={<span>状态流转日志 </span>}
                visible={timelineVisible}
                onOk={() => {
                    setTimelineVisible(false);
                }}
                onCancel={() => {
                    setTimelineVisible(false);
                }}
                footer={null}
            >
                <Timeline reverse={true} mode={"left"}>
                {taskStateTimeline.map((item, index) => (
                    <Timeline.Item key={index} label={item[0]}>
                    {item[1]}
                    <br />
                    <Typography.Text
                        type='secondary'
                        style={{
                        fontSize: 12,
                        marginTop: 4,
                        }}
                    >
                        {item[2]}
                    </Typography.Text>
                    </Timeline.Item>
                ))}
                </Timeline>
            </Drawer>
        </div>
        {taskSteps()}
    </div>
    )
}

export default TaskStateModule
/* eslint-enable */

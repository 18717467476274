import React, {useState} from "react";
import {
    Modal,Button,
    Trigger, Message, Icon
} from '@arco-design/web-react'
import './index.scss'
import {deleteDrafts} from "../../../../../api/DraftsApi";
import DraftsEditModel from "./drafts_edit";
const IconFont = Icon.addFromIconFontCn({
    src: '//at.alicdn.com/t/font_180975_26f1p759rvn.js',
});

const Drafts_op_model = ({token,record, onClose, onNotify}) => {
    const [visible, setVisible] = useState(false)
    const [tid, setTid] = useState('')
    const [editVisible, setEditVisible] = useState(false)

    const handleViewClick = () => {
        setEditVisible(true)
    }

    const closeModal = (action) => {
        setEditVisible(false)
        if (action === 'drafts') {
            onClose('drafts')
        }
    }

    const handleViewDelClick = () => {
        let tid = record['tid']
        setTid(tid)
        setVisible(true)
    }

    const deleteSelectDraft = () => {
        let ids = []
        ids.push(tid)
        deleteDrafts(ids, function (data) {
            setVisible(false)
            Message.success({
                icon: <IconFont type='icon-success'/>,
                content: `选中草稿删除成功!`,
            });
            onClose('drafts')
        })
    }


    return (
        <Trigger
            trigger='click'
            popupVisible={visible}
            clickToClose={false}
            clickOutsideToClose={false}
            popup={() =>
                <Modal
                    title={
                        <div style={{textAlign: 'left'}}>
                            注意
                        </div>
                    }
                    visible={visible}
                    onOk={() => deleteSelectDraft()}
                    onCancel={() => setVisible(false)}
                    autoFocus={false}
                    focusLock={true}
                    cancelButtonProps={{
                        className: 'deleteDraft',
                        style: {
                            position: 'relative',
                            left: 70,
                            backgroundColor: 'white',
                            color: '#1496db',
                            border: '1px solid #1496db'
                        }
                    }}
                    okButtonProps={{
                        className: "deleteDraft",
                        style: {
                            position: 'relative',
                            left: -420,
                            backgroundColor: 'white',
                            color: '#1496db',
                            border: '1px solid #1496db'
                        }
                    }}
                >
                    <p>
                        将删除选中序号为{tid}的草稿,一旦删除将无法恢复!
                        <br></br><br></br>
                        请确认是否删除？
                    </p>
                </Modal>
            }>
            <div>
                <Button className="draftStyleEdit" onClick={handleViewClick}>编辑</Button>
            </div>
            <div>
                <Button className='draftStyleDel' onClick={handleViewDelClick}>删除</Button>
            </div>
            {editVisible && <DraftsEditModel token={token} visible={editVisible} onClose={closeModal} record={record}/>}
        </Trigger>
    )
}

export default Drafts_op_model
